import React, { useMemo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTabContext } from 'GlobalState/Context/TabContext';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Table
import {
  useTable,
  useSortBy,
  useFlexLayout,
  useExpanded,
  useRowSelect,
} from 'react-table';
import Label from 'components/Label/Label';
import TableSubRow from './TableSubRow';

// Billed field
import BilledForm from 'components/Form/SingleProject/BilledTimeForm';

// Icons
import ExpandSolid from 'components/Icons/expand-solid';
import CollapseSolid from 'components/Icons/collapse-solid';
import CheckSolid from 'components/Icons/Check-solid';

// Styling
import cx from 'classnames';
import tableStyles from '../Tables.module.scss';
import tableProjectStyles from './TableSingleProject.module.scss';

// Data
import { useQuery } from 'react-query';
import instance from 'axiosInstance';

const columnsWithHoursArray = [
  'qaTime',
  'pmTime',
  'beTime',
  'feTime',
  'qaTime',
  'totalTime',
  'totalTimeNormalized',
  'totalTimeBilled',
];

const TableSingleComponentUI = ({ data }) => {
  const {
    state: { isTableExpanded, isTableSumMode },
  } = useTabContext();

  const [selectedMembers, setSelectedMembers] = useState({});

  const {
    globalState: { isAdmin },
  } = useGlobalContext();

  /**
   * The useTable hook is what gives us the instances to render the table.
   * @see https://react-table.js.org/api/useTable
   */

  const columns = useMemo(() => {
    const columns = [
      !isTableSumMode
        ? {
            accessor: 'expand',
            maxWidth: 60,
            minWidth: 60,
            width: 60,
            disableSortBy: true,
          }
        : {
            accessor: 'toggleRowSelected',
            maxWidth: 60,
            minWidth: 60,
            width: 60,
            disableSortBy: true,
          },
      {
        Header: 'Period',
        accessor: 'period',
        maxWidth: 200,
        minWidth: 180,
        width: 180,
      },
      {
        Header: 'QA',
        accessor: 'qaTime',
        maxWidth: 100,
        minWidth: 80,
        width: 80,
        sortType: (a, b) => (a.original.qaTime < b.original.qaTime ? -1 : 1),
      },
      {
        Header: 'PM',
        accessor: 'pmTime',
        maxWidth: 100,
        minWidth: 80,
        width: 80,
        sortType: (a, b) => (a.original.pmTime < b.original.pmTime ? -1 : 1),
      },
      {
        Header: 'BE',
        accessor: 'beTime',
        maxWidth: 100,
        minWidth: 80,
        width: 80,
        sortType: (a, b) => (a.original.beTime < b.original.beTime ? -1 : 1),
      },
      {
        Header: 'FE',
        accessor: 'feTime',
        maxWidth: 100,
        minWidth: 80,
        width: 80,
        sortType: (a, b) => (a.original.feTime < b.original.feTime ? -1 : 1),
      },
      {
        Header: 'Involved',
        accessor: 'membersInvolved',
        maxWidth: 280,
        minWidth: 200,
        width: 200,
        disableSortBy: true,
      },
      {
        Header: 'Total',
        accessor: 'totalTime',
        maxWidth: 140,
        minWidth: 120,
        width: 120,
        sortType: (a, b) =>
          a.original.totalTime < b.original.totalTime ? -1 : 1,
      },
    ];

    if (isAdmin) {
      columns.push({
        Header: 'Normalized',
        accessor: 'totalTimeNormalized',
        maxWidth: 100,
        minWidth: 140,
        width: 140,
        sortType: (a, b) =>
          a.original.totalTimeNormalized < b.original.totalTimeNormalized
            ? -1
            : 1,
      });
    }

    return columns;
  }, [isTableSumMode, isAdmin]);

  const renderRowSubComponent = useCallback((data) => {
    return <TableSubRow data={data} />;
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    toggleAllRowsExpanded,
    toggleAllRowsSelected,
    isAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
    },
    useSortBy,
    useFlexLayout,
    useExpanded,
    useRowSelect
  );

  useEffect(() => {
    toggleAllRowsExpanded(isTableExpanded);
  }, [isTableExpanded]);

  /**
   * In the header, we are showing the label + can add sorting options
   */
  const tableHeader = headerGroups.map((headerGroup) => (
    <div
      key={headerGroup.id}
      className={cx(tableStyles.tr, tableStyles['sticky-row'])}
      {...headerGroup.getHeaderGroupProps()}
    >
      {headerGroup.headers.map((column) => {
        let output = (
          <Label fontStyle="bold" arrow={column.canSort ? 'arrow-down' : ''}>
            {column.render('Header')}
          </Label>
        );

        if (column.id === 'toggleRowSelected') {
          output = (
            <>
              <label
                htmlFor="toggle-all-rows-checkbox"
                className={cx(
                  tableStyles['checkbox-wrapper'],
                  tableStyles['w-40px'],
                  {
                    [tableStyles['has-color']]: isAllRowsSelected,
                  }
                )}
              >
                {isAllRowsSelected && (
                  <CheckSolid
                    color={`var(--theme-base-color)`}
                    width="18px"
                    height="14px"
                  />
                )}
              </label>
              <input
                className="is-hidden"
                type="checkbox"
                id="toggle-all-rows-checkbox"
                onChange={() => {
                  toggleAllRowsSelected(!isAllRowsSelected);
                }}
              />
            </>
          );
        }

        return (
          <div
            key={column.id}
            className={`${tableStyles.th} ${tableProjectStyles.th}`}
            {...column.getHeaderProps(column.getSortByToggleProps())}
          >
            {output}
          </div>
        );
      })}
    </div>
  ));

  /**
   * The tableBody is the date we get from the API. Currently static :)
   */
  const tableBody = rows.map((row) => {
    // (Required) Any row that you intend to render in your table needs to be passed to
    // this function before every render.
    prepareRow(row);

    return (
      <div
        key={row.id}
        className={`${tableProjectStyles.tr} ${
          row.isExpanded ? tableProjectStyles.expanded : ''
        } ${tableStyles.tr}`}
        {...row.getRowProps()}
      >
        {row.cells.map((cell) => {
          let output = <Label>{cell.value}</Label>;

          if (cell.column.id === 'membersInvolved' && cell.value) {
            output = cell.value.map((member) => (
              <span
                key={member.slug}
                className={cx(tableProjectStyles.member, {
                  [tableProjectStyles['selected']]: selectedMembers[
                    row.id
                  ]?.includes(member.slug),
                })}
                onClick={() => {
                  setSelectedMembers((prevState) => {
                    const currentRow = prevState[row.id];
                    const newState = { ...prevState };

                    if (!currentRow) {
                      newState[row.id] = [member.slug];
                    } else {
                      const index = currentRow.indexOf(member.slug);
                      let newRow = [];

                      if (index === -1) {
                        newRow = [...currentRow, member.slug];
                      } else {
                        newRow = currentRow.filter(
                          (memberSlug) => memberSlug !== member.slug
                        );
                      }
                      newState[row.id] = newRow;
                    }
                    return newState;
                  });
                }}
              >
                {member.name}
              </span>
            ));
          }

          if (cell.column.id === 'expand') {
            if (row.original.reports.length > 0) {
              output = (
                <span
                  className={cx(
                    tableStyles['expand-icon-wrapper'],
                    ['flex-row'],
                    ['centered-vertically'],
                    ['centered-horizontally'],
                    {
                      [tableStyles['expand']]: true,
                      [tableStyles['has-color']]: row.isExpanded,
                    }
                  )}
                  {...row.getToggleRowExpandedProps()}
                >
                  {row.isExpanded ? (
                    <CollapseSolid
                      width={16}
                      height={14}
                      color={`var(--theme-base-color)`}
                    />
                  ) : (
                    <ExpandSolid
                      width={16}
                      height={14}
                      color={'var(--theme-text-color)'}
                    />
                  )}
                </span>
              );
            }
          }

          if (cell.column.id === 'toggleRowSelected') {
            output = (
              <>
                <label
                  htmlFor={`checkbox-${row.id}`}
                  className={cx(
                    tableStyles['checkbox-wrapper'],
                    tableStyles['w-40px'],
                    {
                      [tableStyles['has-color']]: row.isSelected,
                    }
                  )}
                >
                  {row.isSelected && (
                    <CheckSolid
                      color={`var(--theme-base-color)`}
                      width="18px"
                      height="14px"
                    />
                  )}
                </label>
                <input
                  className="is-hidden"
                  type="checkbox"
                  id={`checkbox-${row.id}`}
                  {...row.getToggleRowSelectedProps()}
                />
              </>
            );
          }

          if (columnsWithHoursArray.includes(cell.column.id)) {
            output = <Label>{cell.value + 'h'}</Label>;
          }

          if (cell.column.id === 'totalTimeBilled') {
            output = (
              <BilledForm
                id={row.original.componentId}
                billed={row.original.totalTimeBilled}
              />
            );
          }

          return (
            <div
              key={cell.id}
              className={cx(tableStyles.td, tableProjectStyles.td, {
                [tableProjectStyles.expandTd]: cell.column.id === 'expand',
              })}
              {...cell.getCellProps()}
            >
              {output}
            </div>
          );
        })}
        {row.isExpanded &&
          renderRowSubComponent(
            selectedMembers[row.id] && selectedMembers[row.id].length > 0
              ? row.original.reports.filter((report) =>
                  selectedMembers[row.id]?.includes(report.employee.slug)
                )
              : row.original.reports
          )}
      </div>
    );
  });

  let selectedRows = rows.filter((row) => {
    prepareRow(row);

    return row.isSelected;
  });

  if (selectedRows.length === 0) {
    selectedRows = rows;
  }

  const sumSelectedRows = {
    qaTime: selectedRows.reduce(
      (prev, current) => prev + current.original.qaTime,
      0
    ),

    pmTime: selectedRows.reduce(
      (prev, current) => prev + current.original.pmTime,
      0
    ),

    beTime: selectedRows.reduce(
      (prev, current) => prev + current.original.beTime,
      0
    ),

    feTime: selectedRows.reduce(
      (prev, current) => prev + current.original.feTime,
      0
    ),

    totalTime: selectedRows.reduce(
      (prev, current) => prev + current.original.totalTime,
      0
    ),

    totalTimeNormalized: selectedRows.reduce(
      (prev, current) => prev + current.original.totalTimeNormalized,
      0
    ),

    totalTimeBilled: selectedRows.reduce(
      (prev, current) => prev + current.original.totalTimeBilled,
      0
    ),
  };

  const tableFooter = useMemo(
    () =>
      footerGroups.map((footerGroup, key) => (
        <div
          key={key}
          className={cx(tableStyles['sticky-row'])}
          {...footerGroup.getFooterGroupProps()}
        >
          {footerGroup.headers.map((column) => {
            let output = '';
            if (column.id === 'component') {
              output = <Label color={`var(--theme-base-color)`}>Sum:</Label>;
            }

            if (columnsWithHoursArray.includes(column.id)) {
              output = (
                <Label color={`var(--theme-base-color)`}>
                  {sumSelectedRows[column.id]}h
                </Label>
              );
            }
            return (
              <div
                key={column.id}
                className={tableStyles.td}
                {...column.getFooterProps()}
              >
                {output}
              </div>
            );
          })}
        </div>
      )),
    [selectedRows]
  );

  return (
    <div className={tableProjectStyles['table-wrapper']}>
      <div
        {...getTableProps}
        className={cx(tableStyles.table, 'custom-scroll')}
      >
        <div
          className={cx(tableProjectStyles.thead, tableStyles['thead-sticky'])}
        >
          {tableHeader}
        </div>
        <div
          className={`${tableStyles.tbody} ${tableProjectStyles.tbody}`}
          {...getTableBodyProps()}
        >
          {tableBody}
        </div>
        {isTableSumMode && (
          <div className={tableStyles['tfoot-sticky']}>{tableFooter}</div>
        )}
      </div>
    </div>
  );
};

TableSingleComponentUI.propTypes = {
  data: PropTypes.array,
};

const TableSingleComponent = () => {
  const { id } = useParams();

  const getSingleProject = async ({ queryKey }) => {
    const response = await instance.get(
      `/private/project-components/${queryKey[1]}`
    );
    return response.data;
  };

  const { data } = useQuery(['single-component', id], getSingleProject);

  return <TableSingleComponentUI data={data} />;
};

export default TableSingleComponent;
