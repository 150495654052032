import React from 'react';
import PropTypes from 'prop-types';

import CheckSolid from 'components/Icons/Check-solid';
import styles from './Checkbox.module.scss';
import cx from 'classnames';

const ExternalStateCheckbox = ({ isChecked, setIsChecked }) => {
  return (
    <div
      className={cx(
        styles['checkbox-wrapper'],
        'flex-row',
        'centered-horizontally',
        'centered-vertically'
      )}
    >
      <label
        className={`${styles['checkbox-item-custom']} ${
          isChecked ? styles.checked : ''
        }`}
      >
        <input
          type="checkbox"
          onChange={() => setIsChecked(!isChecked)}
          className={`${styles['checkbox-item']} ${
            isChecked ? styles.checked : ''
          }`}
        />
        <CheckSolid color="var(--theme-text-color-invert-adaptive)" />
      </label>
    </div>
  );
};

ExternalStateCheckbox.propTypes = {
  isChecked: PropTypes.bool,
  setIsChecked: PropTypes.func,
};

export default ExternalStateCheckbox;
