import React from 'react';

const CloudSolid = (props) => {
  return (
    <svg
      viewBox="0 0 48 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40.275 14.3638C40.575 13.5536 40.8 12.7433 40.8 11.7857C40.8 7.8817 37.575 4.71429 33.6 4.71429C32.1 4.71429 30.675 5.15625 29.55 5.96652C27.525 2.4308 23.625 0 19.2 0C12.525 0 7.2 5.30357 7.2 11.7857C7.2 12.0067 7.2 12.2277 7.2 12.4487C3 13.8482 0 17.8259 0 22.3929C0 28.2857 4.8 33 10.8 33H38.4C43.65 33 48 28.8013 48 23.5714C48 19.0781 44.7 15.2478 40.275 14.3638ZM16.125 16.5L22.725 10.0179C22.875 9.79688 23.325 9.42857 24 9.42857C24.6 9.42857 25.05 9.79688 25.2 10.0179L31.8 16.5C32.55 17.1629 32.55 18.2679 31.8 18.9308C31.125 19.6674 30 19.6674 29.325 18.9308L25.8 15.4688V26.5179C25.8 27.5491 24.975 28.2857 24 28.2857C22.95 28.2857 22.2 27.5491 22.2 26.5179V15.4688L18.6 18.9308C17.925 19.6674 16.8 19.6674 16.125 18.9308C15.375 18.2679 15.375 17.1629 16.125 16.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloudSolid;
