import React from 'react';
import { Routes, Route } from 'react-router-dom';

import AppTabs from 'components/AppTabs/AppTabs';

import TabReportProblem from './TabReportProblem/TabReportProblem';

// One easy way to control where the tabs should lead to
// @todo: the /adimitrov/ to be replaced with the current user name
const pageTabs = [
  {
    to: '/report-problem',
    text: 'Report a problem',
  },
];

const RequestProblem = () => {
  return (
    <>
      <AppTabs links={pageTabs} />
      <div className="app-body-content">
        <Routes>
          <Route path="/*" element={<TabReportProblem />} />
        </Routes>
      </div>
    </>
  );
};

export default RequestProblem;
