import React from 'react';
// Form
import { useForm } from 'react-hook-form';

// UX
import FlexContainer from 'components/FlexContainer/FlexContainer';
import Button from 'components/Button/Button';

// Styles
import cx from 'classnames';
import requestFormStyles from '../RequestForm.module.scss';
import formStyles from '../../Form.module.scss';
import UploadImage from 'components/UploadImage/UploadImage';

const RequestFeature = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  console.log(errors);

  const onSubmit = () => {
    console.log('submit form');
  };

  return (
    <div className={requestFormStyles['request-form']}>
      <h2 className={formStyles['form-title']}>Request a feature</h2>
      <form
        className={formStyles['form-wrapper']}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FlexContainer width="width-medium" wrap="wrap">
          <div
            className={`${formStyles['form-input-wrapper']} ${formStyles['form-input-description']} ${formStyles['width-large']}`}
          >
            <label className={formStyles['form-input-label']}>
              What are you trying to do, that you are currently unable to?
              <div className={formStyles['form-field-wrapper']}>
                <textarea
                  className={formStyles['form-textarea']}
                  rows="6"
                  cols="50"
                  placeholder="Senior Front-End Developer"
                  {...register('description', {
                    required:
                      '*Required! The descriptions helps us form the reports',
                  })}
                ></textarea>
              </div>
            </label>
          </div>

          <div
            className={`${formStyles['form-input-wrapper']} ${formStyles['form-input-description']} ${formStyles['width-large']}`}
          >
            <label className={formStyles['form-input-label']}>
              What feature do you think could solve the problem?
              <div className={formStyles['form-field-wrapper']}>
                <textarea
                  className={formStyles['form-textarea']}
                  rows="6"
                  cols="50"
                  placeholder="Senior Front-End Developer"
                  {...register('description', {
                    required:
                      '*Required! The descriptions helps us form the reports',
                  })}
                ></textarea>
              </div>
            </label>
          </div>
        </FlexContainer>
        <FlexContainer width="width-medium">
          <div
            className={`${formStyles['form-input-wrapper']} ${requestFormStyles['form-input-upload-image']} ${formStyles['width-large']}`}
          >
            <label
              className={cx('flex-column', formStyles['form-input-label'])}
            >
              If you have a screenshot describing the feature, please attach it
              here.
              <div
                className={`${formStyles['form-field-wrapper']} ${requestFormStyles['field-upload-image']}`}
              >
                <UploadImage
                  hasBorder
                  borderRadius="var(--app-general-border-radius-larger)"
                />
              </div>
            </label>
          </div>
        </FlexContainer>

        <div className={formStyles['form-actions-wrapper']}>
          <Button
            clicked={() => console.log('sent information from the form')}
            additionalStyles={requestFormStyles['request-form-submit']}
            type="button-primary-dark"
            text="Request"
          />
        </div>
      </form>
    </div>
  );
};

export default RequestFeature;
