import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import EyeIcon from 'components/Icons/Eye';
import EyeSlashIcon from 'components/Icons/EyeSlash';
import LockIcon from 'components/Icons/Lock';

import cx from 'classnames';
import formStyles from './Form.module.scss';

const PasswordField = forwardRef(
  (
    {
      placeholder,
      wrapperClassName,
      serverError,
      label,
      errors,
      autofocus,
      ...register
    },
    ref
  ) => {
    const [isPassowordVisible, setIsPasswordVisible] = useState(false);
    return (
      <div className={wrapperClassName}>
        <label className={formStyles['form-input-label']}>
          {label}
          <div
            className={cx(formStyles['form-field-wrapper'], {
              [formStyles['field-error-wrapper']]: errors,
            })}
          >
            <LockIcon
              color={
                errors
                  ? 'var(--theme-color-danger)'
                  : 'var(--theme-color-primary)'
              }
              width="1em"
              height="1.125em"
              className={formStyles['field-icon']}
            />
            <input
              type={isPassowordVisible ? 'text' : 'password'}
              autoFocus={autofocus}
              placeholder={placeholder}
              {...register}
              ref={ref}
              className={cx(formStyles['icon-input'], {
                [formStyles['field-error']]: errors || serverError,
              })}
            />

            <span
              className={formStyles['show-password-icon']}
              onClick={() => setIsPasswordVisible(!isPassowordVisible)}
            >
              {isPassowordVisible ? (
                <EyeIcon
                  color={
                    !!errors
                      ? 'var(--theme-color-danger)'
                      : 'var(--theme-color-primary)'
                  }
                  width="1.125em"
                  height="1.125em"
                />
              ) : (
                <EyeSlashIcon
                  color={
                    !!errors
                      ? 'var(--theme-color-danger)'
                      : 'var(--theme-color-primary)'
                  }
                  width="1.125em"
                  height="1.125em"
                />
              )}
            </span>
          </div>
          {serverError && (
            <span className={formStyles['error-message']}>
              {serverError.data.data[0]}
            </span>
          )}
          {errors && (
            <span className={formStyles['error-message']}>
              {errors.message}
            </span>
          )}
        </label>
      </div>
    );
  }
);

PasswordField.displayName = 'PasswordField';

PasswordField.propTypes = {
  placeholder: PropTypes.string,
  serverError: PropTypes.object,
  autofocus: PropTypes.bool,
  label: PropTypes.string,
  errors: PropTypes.object,
  wrapperClassName: PropTypes.string,
};

export default PasswordField;
