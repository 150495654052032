import React, { useReducer, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import tabReducer from '../reducers/tabReducer';

import { currentMonthObject } from 'utils/generateBillingMonths';
import { getCurrentWeek } from 'utils/weekDays';

const intialState = {
  searchFilter: undefined,
  billingPeriod: currentMonthObject(),
  calendarPeriod: currentMonthObject(),
  isTableExpanded: false,
  isTableSumMode: false,
  currentWeek: getCurrentWeek(),
  activeDay: new Date(),
};

const Context = createContext();

const TabContext = ({ children }) => {
  const [state, dispatch] = useReducer(tabReducer, intialState);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

TabContext.propTypes = {
  children: PropTypes.object,
};

export default TabContext;

export const useTabContext = () => useContext(Context);
