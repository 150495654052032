import React from 'react';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

import DateField from 'components/Form/DateField';
import AllActiveMembersSelect from 'components/Form/Selects/AllActiveMembersSelect';
import { useForm, useWatch } from 'react-hook-form';

import Button from 'components/Button/Button';
import SubmitButton from 'components/Form/SubmitButton';

import formStyles from '../../Form.module.scss';
import parseYearMonthDate, {
  getPreviousMonday,
  getFridayOfCurrentWeek,
} from 'utils/dateFunctions';

import { useMutation } from 'react-query';
import instance from 'axiosInstance';

const mutationFunction = async (variables) => {
  const params = new URLSearchParams();

  const ids = variables.members.map((member) => member.value);

  params.append('start_date', parseYearMonthDate(variables.startDate));
  params.append('end_date', parseYearMonthDate(variables.endDate));
  params.append('user_ids', ids.join(', '));

  const response = await instance.post(
    '/private/admin/employees-exports',
    params
  );

  return response;
};

const ExportReportsForm = () => {
  const { globalDispatch } = useGlobalContext();

  const { mutate, isError, error } = useMutation(mutationFunction, {
    mutationKey: `export-csv`,
    onSuccess: ({ data }) => {
      const fileName = '';
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', fileName + '.csv');
      a.click();

      globalDispatch({ type: 'setModalContent', payload: null });
    },
  });

  const { handleSubmit, control } = useForm({
    defaultValues: {
      startDate: getPreviousMonday(new Date()),
      endDate: getFridayOfCurrentWeek(),
    },
  });

  const startDate = useWatch({
    control,
    name: 'startDate',
  });

  const handleRejection = () => {
    globalDispatch({ type: 'setModalContent', payload: null });
  };

  const onSubmit = (data) => {
    mutate(data);
  };

  return (
    <div className={formStyles['export-reports-form']}>
      <h2 className={formStyles['form-title']}>Export Reports</h2>
      <form
        className={formStyles['form-wrapper']}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['full-width']}`}
        >
          <label className={formStyles['form-input-label']}>
            Members to export
            <AllActiveMembersSelect
              control={control}
              name="members"
              placeholder="Members to export"
              isMulti
              rules={{ required: 'At least one member is required' }}
            />
            {isError && (
              <span className={formStyles['error-message']}>
                {error?.response?.data?.message}
              </span>
            )}
          </label>
        </div>
        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['width-medium']}`}
        >
          <label className={formStyles['form-input-label']}>
            Period Start Date
            <DateField
              control={control}
              inputName="startDate"
              rules={{ valueAsDate: true }}
            />
          </label>
        </div>
        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['width-medium']}`}
        >
          <label className={formStyles['form-input-label']}>
            Period End Date
            <DateField
              control={control}
              inputName="endDate"
              minDate={startDate}
              rules={{ valueAsDate: true }}
            />
          </label>
        </div>

        <div className={formStyles['form-actions-wrapper']}>
          <Button
            clicked={handleRejection}
            type="button-primary-inline-adaptive"
            text="Cancel"
          />
          <SubmitButton
            type="button-success"
            additionalStyles={formStyles['edit-report-submit']}
            text="Export"
          />
        </div>
      </form>
    </div>
  );
};

export default ExportReportsForm;
