import React, { useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTabContext } from 'GlobalState/Context/TabContext';

// Table
import {
  useTable,
  useSortBy,
  useFlexLayout,
  useGlobalFilter,
} from 'react-table';
import Label from 'components/Label/Label';
import projectTableGlobalFilter from 'utils/projectsTableGlobalFilter';

import Heart from 'components/Icons/Heart';
import { HeartSolid } from 'components/Icons/Heart';

// UI components
import ProgressBar from '../../ProgressBar/ProgressBar';

// Styles
import tableStyles from '../Tables.module.scss';
import TableProjectsOverviewStyles from './TableProjectsOverview.module.scss';
import cx from 'classnames';

// Data fetching
import { useQuery, useMutation, useQueryClient } from 'react-query';
import instance from 'axiosInstance';

const toggleIsProjectFavourite = async ({ isFavourite, projectId }) => {
  let response;

  if (!isFavourite) {
    const params = new URLSearchParams();
    params.append('project_id', projectId);
    // It was not favourite and we have to set it as favourite
    response = await instance.post(
      '/private/employee-information/favourites',
      params
    );
  } else {
    // It was already favourite so we will have to remove it
    response = await instance.delete(
      '/private/employee-information/favourites/' + projectId
    );
  }

  return response.data;
};

const ProjectsOverviewTableUI = ({ data }) => {
  const {
    state: { searchFilter },
  } = useTabContext();
  const queryClient = useQueryClient();

  const globalFilter = useMemo(() => projectTableGlobalFilter, []);

  const { mutate, isLoading } = useMutation(toggleIsProjectFavourite, {
    onSuccess: () => {
      queryClient.invalidateQueries('projects');
      queryClient.invalidateQueries('favourite-projects');
    },
  });

  /**
   * The useTable hook is what gives us the instances to render the table.
   * @see https://react-table.js.org/api/useTable
   */

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'isFavourite',
        maxWidth: 60,
        minWidth: 40,
        width: 40,
        disableGlobalFilter: true,
        sortType: (a, b) =>
          a.original.isActive > b.original.isActive ? -1 : 1,
      },
      {
        Header: 'Project Name',
        accessor: 'project',
        maxWidth: 150,
        minWidth: 170,
        width: 170,
        sortType: (a, b) =>
          a.original.project.name < b.original.project.name ? -1 : 1,
      },
      {
        Header: 'QA',
        accessor: 'qaTime',
        maxWidth: 65,
        minWidth: 65,
        width: 65,
        sortType: (a, b) => (a.original.qaTime < b.original.qaTime ? -1 : 1),
      },
      {
        Header: 'Dev',
        accessor: 'devTime',
        maxWidth: 65,
        minWidth: 65,
        width: 65,
        sortType: (a, b) => (a.original.devTime < b.original.devTime ? -1 : 1),
      },
      {
        Header: 'PM',
        accessor: 'pmTime',
        maxWidth: 65,
        minWidth: 65,
        width: 65,
        sortType: (a, b) => (a.original.pmTime < b.original.pmTime ? -1 : 1),
      },
      {
        Header: 'Total',
        accessor: 'time',
        maxWidth: 80,
        minWidth: 75,
        width: 75,
        sortType: (a, b) => (a.original.time < b.original.time ? -1 : 1),
      },
      {
        Header: 'Retainer',
        accessor: 'retainer',
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        sortType: (a, b) =>
          a.original.retainer < b.original.retainer ? -1 : 1,
      },
      {
        Header: 'Project Owner',
        accessor: 'po',
        maxWidth: 170,
        minWidth: 170,
        width: 170,
        sortType: (a, b) => (a.original.po.name < b.original.po.name ? -1 : 1),
      },
      {
        Header: 'Project Manager',
        accessor: 'pm',
        maxWidth: 170,
        minWidth: 170,
        width: 170,
        sortType: (a, b) => (a.original.pm.name < b.original.pm.name ? -1 : 1),
      },
      {
        Header: 'Time to billing',
        accessor: 'billing',
        maxWidth: 130,
        minWidth: 190,
        width: 190,
        disableGlobalFilter: true,
        sortType: (a, b) => (a.original.billing < b.original.billing ? -1 : 1),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      globalFilter,
      columns,
      data,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    useFlexLayout
  );

  useEffect(() => {
    setGlobalFilter(searchFilter);
  }, [searchFilter]);

  /**
   * In the header, we are showing the label + can add sorting options
   */
  const tableHeader = headerGroups.map((headerGroup) => (
    <div
      key={headerGroup.id}
      className={cx(
        tableStyles.tr,
        tableStyles['sticky-row'],
        TableProjectsOverviewStyles['table-thead-row']
      )}
      {...headerGroup.getHeaderGroupProps()}
    >
      {headerGroup.headers.map((column, key) => (
        <div
          key={column.id}
          className={tableStyles.th}
          {...column.getHeaderProps(column.getSortByToggleProps())}
        >
          <Label fontStyle="bold" arrow={column.canSort ? 'arrow-down' : ''}>
            {column.render('Header')}
          </Label>
        </div>
      ))}
    </div>
  ));

  /**
   * The tableBody is the date we get from the API. Currently static :)
   */
  const tableBody = rows.map((row) => {
    // (Required) Any row that you intend to render in your table needs to be passed to
    // this function before every render.
    prepareRow(row);

    return (
      <div
        key={row.id}
        className={`${
          row.original.isInDanger
            ? TableProjectsOverviewStyles['emergency-project']
            : ''
        } ${tableStyles.tr}`}
        {...row.getRowProps()}
      >
        {row.cells.map((cell) => {
          let output = <Label>{cell.value}</Label>;

          if (cell.column.id === 'project') {
            output = (
              <Link to={`/project/${cell.value.slug}`}>{cell.value.name}</Link>
            );
          }

          if (cell.column.id === 'isFavourite') {
            const icon = row.original.isFavourite ? (
              <HeartSolid
                color={'var(--theme-color-primary)'}
                width="20"
                height="20"
              />
            ) : (
              <Heart
                color={'var(--theme-color-primary)'}
                width="20"
                height="20"
              />
            );

            output = (
              <span
                className={cx(
                  [isLoading ? 'disabled' : ''],
                  TableProjectsOverviewStyles['icon-content']
                )}
                onClick={() =>
                  mutate({
                    isFavourite: row.original.isFavourite,
                    projectId: row.original.project.id,
                  })
                }
              >
                {icon}
              </span>
            );
          }

          if (cell.column.id === 'po' || cell.column.id === 'pm') {
            output = (
              <Link to={`/member/${cell.value.slug}`}>{cell.value.name}</Link>
            );
          }

          if (
            cell.column.id === 'time' ||
            cell.column.id === 'retainer' ||
            cell.column.id === 'qaTime' ||
            cell.column.id === 'devTime' ||
            cell.column.id === 'pmTime'
          ) {
            output = <Label>{`${cell.value}h`}</Label>;
          }

          if (cell.column.id === 'time' && row.original.isInDanger) {
            output = (
              <span
                className={`${TableProjectsOverviewStyles['emergency-item']}`}
                to={cell.value.slug}
              >
                {`${cell.value}h`}
              </span>
            );
          }

          if (cell.column.id === 'billing') {
            let progressBarValue = Number(
              (3 * cell.value - 5).toString().replace('-', '')
            );
            if (progressBarValue === 5) {
              progressBarValue -= 5;
            }
            output = (
              <div
                className={TableProjectsOverviewStyles['td-centered-content']}
              >
                <ProgressBar
                  wrapperWidth="90px"
                  wrapperHeight="20px"
                  childWidth={`calc(100% - ${progressBarValue}px)`}
                  childHeight="100%"
                />
                <Label>{`${cell.value} days`}</Label>
              </div>
            );
          }

          return (
            <div
              key={cell.id}
              className={`${tableStyles.td} ${TableProjectsOverviewStyles.td} `}
              {...cell.getCellProps()}
            >
              {output}
            </div>
          );
        })}
      </div>
    );
  });

  return (
    <div {...getTableProps} className={tableStyles.table}>
      <div className={`${tableStyles['thead-sticky']} thead`}>
        {tableHeader}
      </div>
      <div
        className={`${tableStyles.tbody} ${TableProjectsOverviewStyles.tbody}`}
        {...getTableBodyProps()}
      >
        {tableBody}
      </div>
    </div>
  );
};

ProjectsOverviewTableUI.propTypes = {
  data: PropTypes.data,
};

const getProjects = async ({ queryKey }) => {
  const response = await instance.get(
    '/private/projects' + `?period=${queryKey[1]}`
  );
  return response.data;
};

const ProjectsOverviewTable = () => {
  const {
    state: { billingPeriod },
  } = useTabContext();

  const { data } = useQuery(['projects', billingPeriod.value], getProjects);

  return <ProjectsOverviewTableUI data={data} />;
};

export default ProjectsOverviewTable;
