import React from 'react';

const WarningBoldSolid = (props) => {
  return (
    <svg
      viewBox="0 0 44 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 0.105713C10.5703 0.105713 1.375 9.38697 1.375 20.7307C1.375 32.1604 10.5703 41.3557 22 41.3557C33.3437 41.3557 42.625 32.1604 42.625 20.7307C42.625 9.38697 33.3437 0.105713 22 0.105713ZM19.9374 11.7932C19.9374 10.676 20.7969 9.73071 22 9.73071C23.1173 9.73071 24.0626 10.676 24.0626 11.7932V22.7933C24.0626 23.9964 23.1173 24.8557 22 24.8557C20.7969 24.8557 19.9374 23.9964 19.9374 22.7933V11.7932ZM22 33.1057C20.4531 33.1057 19.25 31.9027 19.25 30.4417C19.25 28.9807 20.4531 27.7777 22 27.7777C23.461 27.7777 24.664 28.9807 24.664 30.4417C24.664 31.9027 23.461 33.1057 22 33.1057Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WarningBoldSolid;
