import React, { useReducer, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import globalReducer from 'GlobalState/reducers/globalReducer';

const intialState = {
  modalContent: null,
  confirmationPopup: null,
  notifications: [],
  isAdmin: false,
  stopwatchValue: null,
};

const Context = createContext();

const GlobalContext = ({ children }) => {
  const [globalState, globalDispatch] = useReducer(globalReducer, intialState);

  return (
    <Context.Provider value={{ globalState, globalDispatch }}>
      {children}
    </Context.Provider>
  );
};

GlobalContext.propTypes = {
  children: PropTypes.object,
};

export default GlobalContext;

export const useGlobalContext = () => useContext(Context);
