import React from 'react';

import AppTabs from 'components/AppTabs/AppTabs';

import TabOverview from './TabOverview/TabOverview';

import TabContext from 'GlobalState/Context/TabContext';

const pageTabs = [
  {
    to: '/projects',
    text: 'Overview',
  },
];

const Projects = () => {
  return (
    <>
      <AppTabs links={pageTabs} />
      <div className="app-body-content">
        <TabContext>
          <TabOverview />
        </TabContext>
      </div>
    </>
  );
};

export default Projects;
