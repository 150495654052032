import React, { Suspense } from 'react';

import Card from 'components/Card/Card';
import UserInformationOverview from 'components/UserInformationOverview/UserInformationOverview';

import styles from './TabStatistics.module.scss';

import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import SkeletonUserCard from 'components/LoadingSkeletons/SkeletonUserCard/SkeletonUserCard';
import WorkCalendar from 'components/WorkCalendar/WorkCalendar';

const TabStatistics = () => {
  return (
    <>
      <div className={styles['user-information-wrapper']}>
        <Card additionalStyles={styles['user-information']}>
          <ErrorBoundary>
            <Suspense fallback={<SkeletonUserCard />}>
              <UserInformationOverview />
            </Suspense>
          </ErrorBoundary>
        </Card>

        <Card
          title="Work recorded"
          hasPadding
          additionalStyles={styles['callendar']}
        >
          <WorkCalendar />
        </Card>
      </div>
    </>
  );
};

export default TabStatistics;
