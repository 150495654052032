import React from 'react';
import PropTypes from 'prop-types';

import CheckSolid from 'components/Icons/Check-solid';

import cx from 'classnames';
import styles from './ThemeColorPalette.module.scss';

const ThemeColorPalette = ({
  title,
  colorPrimary,
  colorSecondary,
  isActive,
  clicked,
}) => {
  return (
    <div
      className={cx(
        'flex-column',
        'centered-vertically',
        styles['color-palette'],
        isActive ? styles.active : ''
      )}
      onClick={clicked}
    >
      <div
        className={cx(
          'flex-row',
          'centered-horizontally',
          styles['color-palette-inner']
        )}
      >
        <div
          className={styles['palette-item']}
          style={{ backgroundColor: colorSecondary }}
        ></div>
        <div
          className={styles['palette-item']}
          style={{ backgroundColor: colorPrimary }}
        ></div>
      </div>
      <div
        className={cx(
          'flex-row',
          ' centered-horizontally ',
          'centered-vertically',
          styles['palette-header']
        )}
      >
        <span className={styles['palette-icon']}>
          <CheckSolid
            color={`var(--theme-color-accent)`}
            width="18px"
            height="14px"
          />
        </span>
        <p className={styles['palette-title']}>{title}</p>
      </div>
    </div>
  );
};

ThemeColorPalette.propTypes = {
  title: PropTypes.string,
  clicked: PropTypes.func,
  colorPrimary: PropTypes.string,
  colorSecondary: PropTypes.string,
  isActive: PropTypes.bool,
};

export default ThemeColorPalette;
