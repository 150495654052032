import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import ErrorSolid from 'components/Icons/Error-solid';

import cx from 'classnames';
import formStyles from './Form.module.scss';

const TextAreadField = forwardRef(
  (
    { placeholder, wrapperClassName, label, errors, rows, cols, ...register },
    ref
  ) => {
    return (
      <div className={wrapperClassName}>
        <label className={formStyles['form-input-label']}>
          {label}
          <div
            className={cx(formStyles['form-field-wrapper'], {
              [formStyles['field-error-wrapper']]: errors,
            })}
          >
            <textarea
              rows={rows}
              cols={cols}
              placeholder={placeholder}
              {...register}
              ref={ref}
              className={cx(formStyles['form-textarea'], {
                [formStyles['field-error']]: !!errors,
              })}
            />
            {errors && (
              <span className={formStyles['error-icon']}>
                <ErrorSolid
                  width="20"
                  height="20"
                  color={`var(--theme-color-danger-dark)`}
                />
              </span>
            )}
          </div>
          {errors && (
            <span className={formStyles['error-message']}>
              {errors.message}
            </span>
          )}
        </label>
      </div>
    );
  }
);

TextAreadField.displayName = 'TextAreadField';

TextAreadField.propTypes = {
  placeholder: PropTypes.string,
  rows: PropTypes.string,
  cols: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.object,
  wrapperClassName: PropTypes.string,
};

export default TextAreadField;
