import React from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';
import { useCookies } from 'react-cookie';

import { useMutation } from 'react-query';
import instance from 'axiosInstance';

// Form
import { useForm } from 'react-hook-form';
import EmailIcon from 'components/Icons/Email';
import SubmitButton from '../SubmitButton';
import InputFieldNoLabel from '../InputFieldNoLabel';
import PasswordField from '../PasswordField';

// Styles
import styles from './LoginForm.module.scss';
import formStyles from '../Form.module.scss';

const login = (variables) => {
  const params = new URLSearchParams();
  params.append('email', variables.email);
  params.append('password', variables.password);
  return instance.post('/public/login', params);
};

const LoginForm = () => {
  const [, setCookie] = useCookies(['auth_token']);
  const { globalDispatch } = useGlobalContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { error, isLoading, mutate } = useMutation(login, {
    mutationKey: 'login-mutation',
    onSuccess: ({ data }) => {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 20);
      setCookie('auth_token', data.auth_token, { expires: expirationDate });

      globalDispatch({
        type: 'pushNotification',
        payload: { type: 'success', text: 'Signed in successfully' },
      });
    },
  });

  const onSubmit = (data) => {
    mutate(data);
  };

  return (
    <div className={styles['login-form']}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative">
          <EmailIcon
            className={formStyles['field-icon']}
            color={
              errors.email
                ? 'var(--theme-color-danger)'
                : 'var(--theme-color-primary)'
            }
            width="1.125em"
            height="0.875em"
          />
          <InputFieldNoLabel
            placeholder="Enter your email"
            wrapperClassName={formStyles['full-width-input']}
            errors={errors.email}
            serverError={error && error.response.data.error}
            {...register('email', {
              required: '*Email is required',
              validate: (value) =>
                value.endsWith('@devrix.com') || 'Use a DevriX email',
            })}
          />
        </div>
        <PasswordField
          wrapperClassName={formStyles['full-width-input']}
          placeholder={'Enter your password'}
          {...register('password', {
            required: 'Password is required',
          })}
          errors={errors.password}
        />
        <SubmitButton
          additionalStyles={styles['sign-in-button']}
          disabled={isLoading}
          text="Sign In"
        />
      </form>

      <p className={styles['forgot-password-title']}>
        Forgot password?
        <Link
          className={`${styles['forgot-password-link']}`}
          to="forgot-password"
        >
          Click here to reset
        </Link>
      </p>
    </div>
  );
};
export default LoginForm;
