import React, { memo, Suspense } from 'react';
import PropTypes from 'prop-types';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Form
import { useForm } from 'react-hook-form';
import InputField from 'components/Form/InputField';
import Checkbox from 'components/Checkbox/Checkbox';
import Button from 'components/Button/Button';
import SubmitButton from 'components/Form/SubmitButton';
import PMSelect from 'components/Form/Selects/PMSelect';
import ClientSelect from 'components/Form/Selects/ClientSelect';
import AllActiveMembersSelect from 'components/Form/Selects/AllActiveMembersSelect';
import SkeletonLoadingField from 'components/LoadingSkeletons/SkeletonLoadingField/SkeletonLoadingField';

// UI
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

// Styles
import formStyles from '../../Form.module.scss';

// Data
import { useMutation, useQueryClient } from 'react-query';
import instance from 'axiosInstance';

const mutationFunction = async (variables) => {
  console.log(variables);
  const params = new URLSearchParams();
  params.append('name', variables.name);
  params.append('billable_hours', variables.retainer);
  params.append('is_retainer', variables.isRetainer);
  params.append('project_owner', variables.po.value);
  params.append('project_manager', variables.pm.value);
  params.append('client_id', variables.client.value);

  let response;
  if (variables.type === 'create') {
    response = await instance.post('/private/admin/projects', params);
  } else if (variables.type === 'edit') {
    response = await instance.put(
      '/private/admin/projects/' + variables.projectId,
      params
    );
  }

  return response.data;
};

// TODO: Add is retainer field
const ManageProjectsForm = ({ heading, row, type }) => {
  const { globalDispatch } = useGlobalContext();
  const queryClient = useQueryClient();

  const {
    project: { name, id: projectId } = { name: '', id: '' },
    po: { name: poName, id: poId } = { name: '', id: '' },
    pm: { name: pmName, id: pmId } = { name: '', id: '' },
    retainer,
    client: { name: clientName, id: clientId } = { name: '', id: '' },
    isRetainer = false,
  } = row || '';

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name,
      pm: pmId ? { label: pmName, value: pmId } : null,
      po: poId ? { label: poName, value: poId } : null,
      client: clientId ? { label: clientName, value: clientId } : null,
      retainer,
      isRetainer,
    },
  });

  const { mutate } = useMutation(mutationFunction, {
    mutationKey: `${type}-project`,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('projects');

      globalDispatch({ type: 'setModalContent', payload: null });

      globalDispatch({
        type: 'pushNotification',
        payload: {
          type: 'success',
          text: `Project ${
            type === 'create' ? 'created' : 'edited'
          } successfully`,
        },
      });
    },
    onError: () => {
      globalDispatch({
        type: 'pushNotification',
        payload: { type: 'error', text: 'Something went wrong! :(' },
      });
    },
  });

  const onSubmit = (newData) => {
    newData.type = type;
    newData.projectId = projectId;
    mutate(newData);
  };

  const handleRejection = () => {
    globalDispatch({ type: 'setModalContent', payload: null });
  };

  return (
    <div className={formStyles['edit-report-form']}>
      <h2 className={formStyles['form-title']}>{heading}</h2>
      <form
        className={formStyles['form-wrapper']}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputField
          placeholder="Project Name"
          wrapperClassName={`${formStyles['form-input-wrapper']} ${formStyles['width-medium']}`}
          errors={errors.name}
          label="Project Name"
          autoFocus
          {...register('name', {
            required: '*Project name is required',
          })}
        />
        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['width-medium']}`}
        >
          <label className={formStyles['form-input-label']}>
            Client Name
            <ErrorBoundary>
              <Suspense fallback={<SkeletonLoadingField />}>
                <ClientSelect control={control} />
              </Suspense>
            </ErrorBoundary>
          </label>
        </div>

        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['width-medium']}`}
        >
          <label className={formStyles['form-input-label']}>
            Project Owner
            <ErrorBoundary>
              <Suspense fallback={<SkeletonLoadingField />}>
                <AllActiveMembersSelect
                  control={control}
                  name="po"
                  rules={{
                    required: '*Project Owner is required!',
                  }}
                />
              </Suspense>
            </ErrorBoundary>
          </label>
        </div>

        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['width-medium']}`}
        >
          <label className={formStyles['form-input-label']}>
            Project Manager
            <ErrorBoundary>
              <Suspense fallback={<SkeletonLoadingField />}>
                <PMSelect control={control} />
              </Suspense>
            </ErrorBoundary>
          </label>
        </div>

        <InputField
          placeholder="Billable hours"
          type="number"
          wrapperClassName={`${formStyles['form-input-wrapper']} ${formStyles['width-medium']}`}
          errors={errors.retainer}
          label="Billable hours"
          {...register('retainer', {
            required: '*Billable hours is required',
          })}
        />

        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['width-medium']}`}
        >
          <label className={formStyles['form-input-label']}>
            Select is Retainer
            <Checkbox
              label="Is Retainer"
              control={control}
              inputName="isRetainer"
            />
          </label>
        </div>

        <div className={formStyles['form-actions-wrapper']}>
          <Button
            clicked={handleRejection}
            type="button-primary-inline-adaptive"
            text="Cancel"
          />
          <SubmitButton
            type="button-success"
            additionalStyles={formStyles['edit-report-submit']}
            text="Save"
          />
        </div>
      </form>
    </div>
  );
};

ManageProjectsForm.propTypes = {
  row: PropTypes.object,
  heading: PropTypes.string,
  type: PropTypes.string,
};

export default memo(ManageProjectsForm);
