import React from 'react';
import { createPortal } from 'react-dom';
import styles from './Button.module.scss';
import PlusIcon from 'components/Icons/PlusIcon';

const MobileAddButton = (props) => {
  return createPortal(
    <span className={styles['add-button']} {...props}>
      <PlusIcon width="30" height="30" style={{ display: 'block' }} />
    </span>,
    document.body
  );
};

export default MobileAddButton;
