import React from 'react';
import { Routes, Route } from 'react-router-dom';

import AppTabs from 'components/AppTabs/AppTabs';

import TabContext from 'GlobalState/Context/TabContext';
import TabRequestFeature from './TabRequestFeature/TabRequestFeature';

// One easy way to control where the tabs should lead to
// @todo: the /adimitrov/ to be replaced with the current user name
const pageTabs = [
  {
    to: '/request-feature',
    text: 'Request a feature',
  },
];

const RequestFeature = () => {
  return (
    <>
      <AppTabs links={pageTabs} />
      <div className="app-body-content">
        <Routes>
          <Route
            path="/*"
            element={
              <TabContext>
                <TabRequestFeature />
              </TabContext>
            }
          />
        </Routes>
      </div>
    </>
  );
};

export default RequestFeature;
