import React from 'react';
import PropTypes from 'prop-types';

// UX
import Button from 'components/Button/Button';
import CloudSolid from 'components/Icons/Cloud-solid';

// Styles
import cx from 'classnames';
import styles from './UploadImage.module.scss';

const UploadImage = ({ additionalStyles, hasBorder, borderRadius }) => {
  return (
    <div
      className={cx(
        'flex-column',
        'centered-vertically',
        'centered-horizontally',
        styles['upload-image-wrapper'],
        additionalStyles,
        hasBorder ? styles.border : ''
      )}
      style={{ borderRadius: borderRadius }}
    >
      <div
        className={cx(
          'flex-row',
          'centered-vertically',
          'centered-horizontally',
          styles['icon-wrapper']
        )}
      >
        <CloudSolid width="48px" height="36px" />
      </div>

      <div
        className={cx(
          styles['upload-image-button-wrapper'],
          'flex-row',
          'centered-vertically',
          'centered-horizontally',
          'wrap'
        )}
      >
        <Button
          text="Choose File"
          type="button-primary-dark-inline"
          additionalStyles={styles['upload-image-button']}
        />
        <span className={styles['text']}> or drag image</span>
      </div>
      <span className={styles['text']}>max. size: 10MG</span>
    </div>
  );
};

UploadImage.propTypes = {
  additionalStyles: PropTypes.string,
  hasBorder: PropTypes.bool,
  borderRadius: PropTypes.string,
};

export default UploadImage;
