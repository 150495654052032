import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTabContext } from 'GlobalState/Context/TabContext';

// Table
import { useTable, useSortBy, useFlexLayout, useFilters } from 'react-table';
import Label from 'components/Label/Label';

// styles
import cx from 'classnames';
import tableStyles from '../Tables.module.scss';
import styles from './TableEmployeeOverview.module.scss';

// Utils
import calendarCalculations from 'utils/calendarCalculations';

// Data
import { useQuery } from 'react-query';
import instance from 'axiosInstance';
/**
 * The one, the only
 *
 * This is the home page table and it's the most used component of the whole app
 * @see https://react-table.js.org/ - 90% of the stuff is there.
 * @return {JSX} My reports table
 */

// Todo: - Modal on top. Data changes on edit click
const TableEmployeeOverviewUI = ({ data }) => {
  const {
    state: { searchFilter, billingPeriod },
  } = useTabContext();

  const { calendarDays, currentDay, weekendDays } = calendarCalculations(
    billingPeriod.value
  );

  const minWorkHours = 6;

  const days = [];

  for (let i = 1; i <= calendarDays; i++) {
    days.push({
      Header: String(i),
      accessor: String(i),
      maxWidth: 40,
      minWidth: 40,
      width: 40,
    });
  }

  // accessor is the "key" in the data
  const columns = useMemo(
    () => [
      {
        Header: 'Employee',
        accessor: 'employee',
        maxWidth: 170,
        minWidth: 170,
        width: 170,
        sortType: (a, b) =>
          a.original.employee.full_name < b.original.employee.full_name
            ? -1
            : 1,
        filter: (rows, columnId, filterValue) =>
          rows.filter((row) =>
            row.original.employee.full_name
              .toLowerCase()
              .includes(filterValue.toLowerCase())
          ),
      },
      ...days,

      {
        Header: 'Total',
        accessor: 'total',
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        sortType: (a, b) => (a.original.total < b.original.total ? -1 : 1),
      },
    ],
    [calendarDays]
  );

  /**
   * The useTable hook is what gives us the instances to render the table.
   * @see https://react-table.js.org/api/useTable
   */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
    useFlexLayout
  );

  useEffect(() => {
    setFilter('employee', searchFilter);
  }, [searchFilter]);

  /**
   * In the header, we are showing the label + can add sorting options
   */

  const tableHeader = useMemo(
    () =>
      headerGroups.map((headerGroup, key) => (
        <div
          key={key}
          className={cx(tableStyles.tr, tableStyles['sticky-row'])}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map((column) => {
            let output = (
              <div
                key={column.id}
                className={`${tableStyles.th} ${styles.th} ${
                  currentDay == column.render('Header')
                    ? cx(styles['custom-item'], styles['current-day'])
                    : ''
                } ${'flex-row'} ${'centered-horizontally'} ${'centered-vertically'}`}
                {...column.getHeaderProps()}
              >
                <Label fontStyle="bold">{column.render('Header')}</Label>
              </div>
            );

            if (column.id === 'employee' || column.id === 'total') {
              output = (
                <div
                  key={column.id}
                  className={`${tableStyles.th} ${
                    styles.th
                  } ${'flex-row'} ${'centered-vertically'}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <Label fontStyle="bold" arrow="arrow-down">
                    {column.render('Header')}
                  </Label>
                </div>
              );
            }

            return output;
          })}
        </div>
      )),
    [currentDay, calendarDays]
  );

  /**
   * The tableBody is the date we get from the API. Currently static :)
   */
  const tableBody = useMemo(
    () =>
      rows.map((row) => {
        // (Required) Any row that you intend to render in your table needs to be passed to
        // this function before every render.
        prepareRow(row);

        return (
          <div key={row.id} className={tableStyles.tr} {...row.getRowProps()}>
            {row.cells.map((cell, i) => {
              let output = <Label>{cell.value}</Label>;

              if (cell.column.id === 'employee') {
                output = cell.value.slug ? (
                  <Link to={`/member/${cell.value.slug}`}>
                    {cell.value.full_name}
                  </Link>
                ) : (
                  <span>{cell.value.full_name}</span>
                );
              }

              // Standard output
              return (
                <div
                  key={cell.column.id}
                  className={cx(
                    tableStyles.td,
                    styles.td,
                    'flex-row',
                    cell.column.id !== 'employee'
                      ? 'centered-horizontally'
                      : '',
                    'centered-vertically',
                    weekendDays.includes(Number(cell.column.id))
                      ? cx(styles['custom-item'], styles['weekend-day'])
                      : '',
                    minWorkHours > Number(cell.value) &&
                      !weekendDays.includes(Number(cell.column.id)) &&
                      cell.column.id !== 'total'
                      ? cx(styles['custom-item'], styles['min-work-hours'])
                      : ''
                  )}
                  {...cell.getCellProps()}
                >
                  {output}
                </div>
              );
            })}
          </div>
        );
      }),
    [rows, calendarDays]
  );

  return (
    <div className={tableStyles.table} {...getTableProps}>
      <div className={`${tableStyles['thead-sticky']} thead`}>
        {tableHeader}
      </div>
      <div className={tableStyles.tbody} {...getTableBodyProps()}>
        {tableBody}
      </div>
    </div>
  );
};

TableEmployeeOverviewUI.propTypes = {
  data: PropTypes.array,
};

const getEmployeesHours = async ({ queryKey }) => {
  const response = await instance.get(
    '/private/admin/statistics-employees/' + queryKey[1]
  );
  return response.data;
};

const TableEmployeeOverview = () => {
  const {
    state: { billingPeriod },
  } = useTabContext();

  const { data } = useQuery(
    ['statistics-employees', billingPeriod.value],
    getEmployeesHours
  );

  return <TableEmployeeOverviewUI data={data} />;
};

export default TableEmployeeOverview;
