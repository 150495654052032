import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTabContext } from 'GlobalState/Context/TabContext';

// Table
import { useTable, useSortBy, useFlexLayout, useFilters } from 'react-table';
import Label from 'components/Label/Label';

// Form
import MultiplierForm from 'components/Form/SingleProject/MultiplierForm';

// styles
import cx from 'classnames';
import tableStyles from '../Tables.module.scss';

// utils

import { useQuery } from 'react-query';
import instance from 'axiosInstance';

/**
 * The one, the only
 *
 * This is the home page table and it's the most used component of the whole app
 * @see https://react-table.js.org/ - 90% of the stuff is there.
 * @return {JSX} My reports table
 */

// Todo: - Modal on top. Data changes on edit click
const TableInvolvedUI = ({ data }) => {
  // accessor is the "key" in the data
  const {
    state: { searchFilter },
  } = useTabContext();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'employeeName',
        maxWidth: 200,
        minWidth: 200,
        width: 500,
        filter: (rows, columnId, filterValue) =>
          rows.filter((row) =>
            row.original.employeeName
              .toLowerCase()
              .includes(filterValue.toLowerCase())
          ),
      },
      {
        Header: 'Multiplier',
        accessor: 'multiplier',
        minWidth: 100,
        width: 120,
      },
      {
        Header: 'TimeTotal',
        accessor: 'totalHours',
        minWidth: 100,
        width: 120,
      },
      {
        Header: 'Normalized Time',
        accessor: 'normalizedHours',
        minWidth: 250,
        width: 200,
        maxWidth: 50,
      },
    ],
    []
  );

  /**
   * The useTable hook is what gives us the instances to render the table.
   * @see https://react-table.js.org/api/useTable
   */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
    useFlexLayout
  );

  useEffect(() => {
    setFilter('employeeName', searchFilter);
  }, [searchFilter]);

  /**
   * In the header, we are showing the label + can add sorting options
   */

  const tableHeader = useMemo(
    () =>
      headerGroups.map((headerGroup, key) => (
        <div
          key={key}
          className={cx(tableStyles.tr, tableStyles['sticky-row'])}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map((column) => (
            <div
              key={column.id}
              className={tableStyles.th}
              {...column.getHeaderProps(column.getSortByToggleProps())}
            >
              <Label
                fontStyle="bold"
                arrow={column.canSort ? 'arrow-down' : ''}
              >
                {column.render('Header')}
              </Label>
            </div>
          ))}
        </div>
      )),
    []
  );

  /**
   * The tableBody is the date we get from the API. Currently static :)
   */
  const tableBody = useMemo(
    () =>
      rows.map((row) => {
        // (Required) Any row that you intend to render in your table needs to be passed to
        // this function before every render.
        prepareRow(row);

        return (
          <div key={row.id} className={tableStyles.tr} {...row.getRowProps()}>
            {row.cells.map((cell) => {
              let output = <Label>{cell.value}</Label>;
              // Standard output

              const includeHours = ['totalTime', 'normalizedTime'];

              if (includeHours.includes(cell.column.id)) {
                output = <Label>{cell.value + 'h'}</Label>;
              }

              if (cell.column.id === 'multiplier') {
                output = (
                  <MultiplierForm
                    id={row.original.employeeId}
                    multiplier={row.original.multiplier}
                  />
                );
              }

              return (
                <div
                  key={cell.column.id}
                  className={cx(tableStyles.td)}
                  {...cell.getCellProps()}
                >
                  {output}
                </div>
              );
            })}
          </div>
        );
      }),
    [rows]
  );

  return (
    <div className={tableStyles.table} {...getTableProps}>
      <div className={`${tableStyles['thead-sticky']} thead`}>
        {tableHeader}
      </div>
      <div className={tableStyles.tbody} {...getTableBodyProps()}>
        {tableBody}
      </div>
    </div>
  );
};

TableInvolvedUI.propTypes = {
  data: PropTypes.object,
};

const TableInvolved = () => {
  const { slug } = useParams();
  const {
    state: { billingPeriod },
  } = useTabContext();

  const getInvolvedMembersForProject = async ({ queryKey }) => {
    const response = await instance.get(
      '/private/admin/projects/members-involved?project=' +
        queryKey[1] +
        `&period=${queryKey[2]}`
    );
    return response.data;
  };

  const { data } = useQuery(
    ['involved-members', slug, billingPeriod.value],
    getInvolvedMembersForProject
  );

  return <TableInvolvedUI data={data} />;
};

export default TableInvolved;
