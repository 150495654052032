import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Form
import { useForm } from 'react-hook-form';
import InputField from 'components/Form/InputField';
import Button from 'components/Button/Button';
import SubmitButton from 'components/Form/SubmitButton';
import BilledAsSelect from 'components/Form/Selects/BilledAsSelect';

import { useMutation, useQueryClient } from 'react-query';
import instance from 'axiosInstance';

// Styles
import formStyles from '../../Form.module.scss';

const billedObject = {
  fe: 'Front-End',
  be: 'Back-End',
  pm: 'Project Management',
  qa: 'Quality Assurance',
};

const PositionsForm = ({ heading, row, type }) => {
  const { title, id, bill_as: billAs } = row || '';
  const { globalDispatch } = useGlobalContext();

  const {
    // register,
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title,
      billedAs: {
        label: billedObject[billAs],
        value: billAs,
      },
    },
  });

  const queryClient = useQueryClient();

  const mutationFunction = async (variables) => {
    const params = new URLSearchParams();
    params.append('title', variables.title);
    params.append('bill_as', variables.billedAs.value);

    let response;
    if (type === 'create') {
      response = await instance.post(
        '/private/admin/employees-positions',
        params
      );
    } else if (type === 'edit') {
      response = await instance.put(
        '/private/admin/employees-positions/' + id,
        params
      );
    }
    return response.data;
  };

  const { mutate } = useMutation(mutationFunction, {
    mutationKey: `${type}-position`,
    onSuccess: () => {
      queryClient.invalidateQueries('positions');
      globalDispatch({ type: 'setModalContent', payload: null });

      globalDispatch({
        type: 'pushNotification',
        payload: {
          type: 'success',
          text: `Position ${
            type === 'create' ? 'created' : 'edited'
          } successfully`,
        },
      });
    },
    onError: ({ response }) => {
      globalDispatch({
        type: 'pushNotification',
        payload: { type: 'error', text: 'Something went wrong! :(' },
      });
    },
  });

  const onSubmit = (newData) => {
    mutate(newData);
    globalDispatch({ type: 'setModalContent', payload: null });
  };

  const handleRejection = () => {
    globalDispatch({ type: 'setModalContent', payload: null });
  };

  return (
    <div className={formStyles['edit-report-form']}>
      <h2 className={formStyles['form-title']}>{heading}</h2>
      <form
        className={formStyles['form-wrapper']}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputField
          placeholder="Position"
          wrapperClassName={`${formStyles['form-input-wrapper']} ${formStyles['width-custom-large']}`}
          errors={errors.title}
          serverErrors={errors?.response}
          autofocus
          label="Position"
          {...register('title', {
            required: '*Position is required',
          })}
        />
        <div className={`${formStyles['form-input-wrapper']} `}>
          <label className={formStyles['form-input-label']}>
            Billed as
            <BilledAsSelect control={control} />
          </label>
        </div>
        <div className={formStyles['form-actions-wrapper']}>
          <Button
            clicked={handleRejection}
            type="button-primary-inline-adaptive"
            text="Cancel"
          />
          <SubmitButton
            type="button-success"
            additionalStyles={formStyles['edit-report-submit']}
            text="Save"
          />
        </div>
      </form>
    </div>
  );
};

PositionsForm.propTypes = {
  row: PropTypes.object,
  heading: PropTypes.string,
  type: PropTypes.string,
};

export default memo(PositionsForm);
