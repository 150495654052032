import React from 'react';
import PropTypes from 'prop-types';

// Styles
import cx from 'classnames';
import styles from './Label.module.scss';

const Label = ({ arrow, type, color, children, fontStyle }) => {
  // styles is array, why not append type to styles?
  return (
    <span
      className={cx(
        styles.label,
        styles[type],
        styles[color],
        styles[fontStyle],
        styles[arrow]
      )}
    >
      {children}
    </span>
  );
};

Label.propTypes = {
  arrow: PropTypes.string,
  type: PropTypes.array,
  color: PropTypes.string,
  fontStyle: PropTypes.string,
  children: PropTypes.any,
};

export default Label;
