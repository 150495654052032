import React, { memo, Suspense } from 'react';
import PropTypes from 'prop-types';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Form
import { useForm } from 'react-hook-form';
import Button from 'components/Button/Button';
import SubmitButton from './SubmitButton';
import PositionsSelect from './Selects/PositionsSelect';
import InputField from './InputField';
import TextAreadField from './TextAreaField';

import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import SkeletonLoadingField from 'components/LoadingSkeletons/SkeletonLoadingField/SkeletonLoadingField';

// Styles
import formStyles from './Form.module.scss';

import { useMutation, useQueryClient } from 'react-query';
import instance from 'axiosInstance';

const mutationFunction = async (variables) => {
  const params = new URLSearchParams();
  params.append('full_name', variables.fullName);
  params.append('position_id', variables.position.value);
  params.append('description', variables.description);
  params.append('receive_notification_emails', false);

  const response = await instance.put('/private/employee-information', params);

  return response.data;
};

const EditProfileInformationForm = ({ data }) => {
  const queryClient = useQueryClient();
  const { globalDispatch } = useGlobalContext();
  const { fullName, title, positionId, description } = data;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName,
      position: { label: title, value: positionId },
      description,
    },
  });

  const { mutate } = useMutation(mutationFunction, {
    mutationKey: `edit-profile`,
    onSuccess: () => {
      queryClient.invalidateQueries('user-information');
      globalDispatch({ type: 'setModalContent', payload: null });
      globalDispatch({
        type: 'pushNotification',
        payload: {
          type: 'success',
          text: 'User information changed successfully!',
        },
      });
    },
    onError: ({ response }) => {
      globalDispatch({
        type: 'pushNotification',
        payload: { type: 'error', text: 'Something went wrong' },
      });
    },
  });

  const onSubmit = (newData) => {
    mutate(newData);
  };

  const handleRejection = () => {
    globalDispatch({ type: 'setModalContent', payload: null });
  };

  return (
    <div className={formStyles['edit-report-form']}>
      <h2 className={formStyles['form-title']}>Edit Profile Information</h2>
      <form
        className={formStyles['form-wrapper']}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputField
          wrapperClassName={`${formStyles['form-input-wrapper']} ${formStyles['form-input-description']} ${formStyles['width-medium']}`}
          label="Name"
          placeholder="Name"
          errors={errors.fullName}
          autofocus
          {...register('fullName')}
        />
        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['width-medium']}`}
        >
          <label className={formStyles['form-input-label']}>
            Position
            <ErrorBoundary>
              <Suspense fallback={<SkeletonLoadingField />}>
                <PositionsSelect control={control} />
              </Suspense>
            </ErrorBoundary>
          </label>
        </div>

        <TextAreadField
          wrapperClassName={`${formStyles['form-input-wrapper']} ${formStyles['form-input-description']}`}
          rows="3"
          cols="50"
          placeholder="Description"
          {...register('description')}
        />

        <div className={formStyles['form-actions-wrapper']}>
          <Button
            clicked={handleRejection}
            type="button-primary-inline-adaptive"
            text="Cancel"
          />
          <SubmitButton
            type="button-success"
            additionalStyles={formStyles['edit-report-submit']}
            text="Save"
          />
        </div>
      </form>
    </div>
  );
};

EditProfileInformationForm.propTypes = {
  data: PropTypes.object,
};

export default memo(EditProfileInformationForm);
