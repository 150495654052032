import React, { useState } from 'react';

import { useAsyncDebounce } from 'react-table';
import { useTabContext } from 'GlobalState/Context/TabContext';

const SearchFilter = () => {
  const {
    state: { searchFilter },
    dispatch,
  } = useTabContext();
  const [searchValue, setSearchValue] = useState(searchFilter || '');

  const onChange = useAsyncDebounce((value) => {
    dispatch({ type: 'setSearchFilter', payload: value || undefined });
  }, 200);

  return (
    <input
      type="text"
      id="search"
      placeholder="Search the table"
      value={searchValue}
      onChange={(e) => {
        setSearchValue(e.target.value);
        onChange(e.target.value);
      }}
    />
  );
};

export default SearchFilter;
