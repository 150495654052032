import React from 'react';

const Crown = (props) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.75 2C16.0312 2 15.5 2.5625 15.5 3.25C15.5 3.53125 15.5938 3.78125 15.75 4L12.9375 6.25C12.75 6.40625 12.5312 6.46875 12.3125 6.46875C11.9688 6.46875 11.5938 6.25 11.4375 5.90625L9.625 2.3125C10 2.09375 10.25 1.71875 10.25 1.25C10.25 0.5625 9.6875 0 9 0C8.28125 0 7.75 0.5625 7.75 1.25C7.75 1.71875 7.96875 2.09375 8.34375 2.3125L6.53125 5.90625C6.375 6.25 6 6.46875 5.65625 6.46875C5.4375 6.46875 5.21875 6.40625 5.03125 6.25L2.21875 4C2.375 3.78125 2.5 3.53125 2.5 3.25C2.5 2.5625 1.9375 2 1.25 2C0.53125 2 0 2.5625 0 3.25C0 3.96875 0.53125 4.5 1.25 4.5L2.84375 13.1875C2.90625 13.6562 3.34375 14 3.8125 14H14.1562C14.625 14 15.0625 13.6562 15.125 13.1875L16.7188 4.5C16.7188 4.5 16.7188 4.5 16.75 4.5C17.4375 4.5 18 3.96875 18 3.25C18 2.5625 17.4375 2 16.75 2ZM13.7188 12.5H4.25L3.1875 6.6875L4.09375 7.40625C4.53125 7.78125 5.0625 7.96875 5.65625 7.96875C6.59375 7.96875 7.4375 7.4375 7.875 6.59375L9 4.375L10.0938 6.59375C10.5312 7.4375 11.375 7.96875 12.3125 7.96875C12.9062 7.96875 13.4375 7.78125 13.875 7.40625L14.7812 6.6875L13.7188 12.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Crown;
