import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';
import ErrorSolid from 'components/Icons/Error-solid';

import formStyles from '../Form.module.scss';
import styles from './TaskUrlField.module.scss';
import cx from 'classnames';

const createLinkInsideRichField = (link) => {
  if (!link.dataset.isAttached) {
    link.addEventListener('click', function (e) {
      // The clickable url
      const element = document.createElement('a');
      element.textContent = e.target.href;
      element.href = e.target.href;
      element.target = '_blank';
      element.className = styles['popup-link'];

      // The div wrapper
      const wrapper = document.createElement('div');
      wrapper.className = cx('popup-wrapper', styles['popup-wrapper']);

      // The heading
      const heading = document.createElement('h4');
      heading.className = styles.heading;
      heading.textContent = e.target.textContent;

      // The close button
      const closeButton = document.createElement('span');
      closeButton.className = styles['close-button'];
      closeButton.addEventListener('click', function () {
        document.querySelector('.rich-field-wrapper .popup-wrapper').remove();
        e.target.dataset.isPopupVisible = false;
      });
      closeButton.textContent = 'x';

      // Append everything to the container
      wrapper.append(heading);
      wrapper.append(element);
      wrapper.append(closeButton);
      if (
        e.target.dataset.isPopupVisible === 'false' ||
        e.target.dataset.isPopupVisible == undefined
      ) {
        document.querySelector('.rich-field-wrapper').append(wrapper);
        e.target.dataset.isPopupVisible = true;
      } else {
        document.querySelector('.rich-field-wrapper .popup-wrapper').remove();
        e.target.dataset.isPopupVisible = false;
      }
    });
    link.dataset.isAttached = true;
  }
};

// Form
const TaskUrlField = ({
  setValue,
  setError,
  clearErrors,
  control,
  getValues,
}) => {
  const {
    fieldState: { error },
    field: { value },
  } = useController({
    name: 'taskUrl',
    control,
  });

  const ref = useRef();

  useEffect(() => {
    if (value) {
      const name = getValues('taskTitle');
      const href = getValues('taskUrl');

      const link = document.createElement('a');
      link.target = '_blank';

      if (name && href) {
        link.href = href;
        link.textContent = name;
        ref.current.appendChild(link);

        createLinkInsideRichField(link);
      } else if (href) {
        link.href = href;
        link.textContent = 'Open Link';
        ref.current.appendChild(link);

        createLinkInsideRichField(link);
      }
    } else {
      ref.current.innerHTML = '';
    }
  }, [value]);

  const handleOnBlur = (e) => {
    const links = e.target.querySelectorAll('a');
    const textContent = e.target.textContent;

    if (links.length > 0) {
      const href = links[0].href;
      const name = links[0].textContent;

      if (
        !href.startsWith('https://app.asana.com/') &&
        !href.startsWith('https://devrix.slack.com/')
      ) {
        setError('taskUrl', {
          type: 'invalid-href',
          message: 'The link is not from asana or slack',
        });
      } else {
        clearErrors('taskUrl');
        setValue('taskUrl', href);
        setValue('taskTitle', name);
      }
    } else {
      if (
        textContent.startsWith('https://app.asana.com/') ||
        textContent.startsWith('https://devrix.slack.com/')
      ) {
        setValue('taskUrl', textContent);
        setValue('taskTitle', '');
        clearErrors('taskUrl');
      } else if (textContent === '') {
        clearErrors('taskUrl');
        setValue('taskUrl', '');
        setValue('taskTitle', '');
      } else {
        setError('taskUrl', {
          type: 'invalid-href',
          message: 'The link is not from asana or slack',
        });
      }
    }
  };

  const handleInput = (e) => {
    const link = e.target.querySelector('a');

    if (link) {
      createLinkInsideRichField(link);
    }
  };

  return (
    <div className={cx('rich-field-wrapper', styles['field-wrapper'])}>
      <div
        className={cx(styles['rich-field'], {
          [formStyles['field-error']]: error,
        })}
        contentEditable="true"
        data-placeholder="Task URL"
        onInput={handleInput}
        onBlur={handleOnBlur}
        id="taskurl"
        ref={ref}
      ></div>
      {error && (
        <span className={formStyles['error-icon']}>
          <ErrorSolid
            width="20"
            height="20"
            color={`var(--theme-color-danger-dark)`}
          />
        </span>
      )}
      {error && (
        <span className={formStyles['error-message']}>{error.message}</span>
      )}
    </div>
  );
};

TaskUrlField.propTypes = {
  setValue: PropTypes.func,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  control: PropTypes.object,
  getValues: PropTypes.func,
};

export default TaskUrlField;
