import React, { useState } from 'react';

import ThemeColorPalette from 'components/ThemeColorPalette/ThemeColorPalette';

import updateCssThemeColorVariables from './updateCssVariblesThemeColors';

import cx from 'classnames';
import styles from './ThemeOptions.module.scss';

const ThemeOptions = () => {
  let theme = localStorage.getItem('theme');

  if (!theme) {
    theme = 'default';
    localStorage.setItem('theme', 'default');
  }

  const [selectedTypeTheme, setSelectedTypeTheme] = useState(theme);

  const handleIsSelected = (typeTheme) => {
    switch (typeTheme) {
      case 'default':
        theme = typeTheme;
        localStorage.setItem('theme', typeTheme);
        break;
      case 'pink':
        theme = typeTheme;
        localStorage.setItem('theme', typeTheme);
        break;
      case 'blue':
        theme = typeTheme;
        localStorage.setItem('theme', typeTheme);
        break;
      case 'cherry':
        theme = typeTheme;
        localStorage.setItem('theme', typeTheme);
        break;
      case 'orange':
        theme = typeTheme;
        localStorage.setItem('theme', typeTheme);
        break;
      case 'yellow':
        theme = typeTheme;
        localStorage.setItem('theme', typeTheme);
        break;
      default:
        theme = 'default';
        localStorage.setItem('theme', 'default');
    }

    setSelectedTypeTheme(theme);
    updateCssThemeColorVariables(theme);
  };

  return (
    <div
      className={cx(
        'flex-row',
        'wrap',
        'separated-content',
        styles['theme-options']
      )}
    >
      <ThemeColorPalette
        title="Default"
        colorPrimary={`var(--theme-default-primary)`}
        colorSecondary={`var(--theme-default-accent)`}
        clicked={() => handleIsSelected('default')}
        isActive={selectedTypeTheme === 'default'}
      />
      <ThemeColorPalette
        title="Pink"
        colorPrimary={`var(--theme-pink-primary)`}
        colorSecondary={`var(--theme-pink-accent)`}
        clicked={() => handleIsSelected('pink')}
        isActive={selectedTypeTheme === 'pink'}
      />
      <ThemeColorPalette
        title="Blue"
        colorPrimary={`var(--theme-blue-primary)`}
        colorSecondary={`var(--theme-blue-accent)`}
        clicked={() => handleIsSelected('blue')}
        isActive={selectedTypeTheme === 'blue'}
      />
      <ThemeColorPalette
        title="Cherry"
        colorPrimary={`var(--theme-cherry-primary)`}
        colorSecondary={`var(--theme-cherry-accent)`}
        clicked={() => handleIsSelected('cherry')}
        isActive={selectedTypeTheme === 'cherry'}
      />
      <ThemeColorPalette
        title="Orange"
        colorPrimary={`var(--theme-orange-primary)`}
        colorSecondary={`var(--theme-orange-accent)`}
        clicked={() => handleIsSelected('orange')}
        isActive={selectedTypeTheme === 'orange'}
      />
      <ThemeColorPalette
        title="Yellow"
        colorPrimary={`var(--theme-yellow-primary)`}
        colorSecondary={`var(--theme-yellow-accent)`}
        clicked={() => handleIsSelected('yellow')}
        isActive={selectedTypeTheme === 'yellow'}
      />
    </div>
  );
};

export default ThemeOptions;
