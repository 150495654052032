import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import Select from 'components/Form/Selects/Select';

import Card from 'components/Card/Card';

import cx from 'classnames';
import styles from './ProfilePopup.module.scss';
import ButtonLink from 'components/Button/ButtonLink';
import Logout from 'components/Logout/Logout';
import ToggleDarkMode from 'components/Toggle/ToggleDarkMode/ToggleDarkMode';
import ThemeOptions from 'components/ThemeOptions/ThemeOptions';
import Button from 'components/Button/Button';
import CloseSolid from 'components/Icons/Close-solid';

const options = [
  { label: 'Project', value: 'project' },
  { label: 'Component', value: 'component' },
  { label: 'Task', value: 'taskUrl' },
  { label: 'Description', value: 'description' },
  { label: 'Time', value: 'hoursSpent' },
];

const ProfilePopup = ({ userName, isVisible, setIsVisible }) => {
  const [cookies, setCookie] = useCookies(['inputs-to-keep']);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.code === 'Escape') {
        setIsVisible(false);
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleSelectChange = (selected) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 5000);
    setCookie('inputs-to-keep', JSON.stringify(selected), {
      expires: expirationDate,
    });
  };

  return (
    <>
      {isVisible && (
        <div onClick={() => setIsVisible(false)} className="overlay"></div>
      )}
      <Card
        title={`Hey, ${userName}`}
        headerAdditional={
          <Button
            clicked={() => setIsVisible(false)}
            additionalStyles={styles['close-button']}
            icon={
              <CloseSolid
                color={`var(--theme-text-color)`}
                width="1.25em"
                height="1.25em"
              />
            }
          />
        }
        additionalStyles={`${styles['profile-popup']} ${
          isVisible ? styles.active : ''
        }`}
        hasPadding
        hasScroll
      >
        <div className={styles['card-item']}>
          <h4 className={styles['card-item-title']}>My preferences</h4>
          <div
            className={cx(
              'flex-row',
              'centered-vertically',
              styles['card-row']
            )}
          >
            <ToggleDarkMode />
            <p>Use dark mode</p>
          </div>
          <div
            className={cx(
              'flex-row',
              'centered-vertically',
              'wrap',
              styles['card-row']
            )}
          >
            <Select
              isMulti
              options={options}
              onChange={handleSelectChange}
              wrapperClassName="fullwidth"
              defaultValue={cookies['inputs-to-keep']}
            />
            <p style={{ marginTop: '1em' }}>
              Select which fields you would like to not be deleted after form
              submission
            </p>
          </div>
        </div>
        <div className={(styles['card-item'], styles['margin-top-medium'])}>
          <h4>Set Time Tracker theme</h4>
          <ThemeOptions />
          <p className={styles['card-exerpt']}>
            *Your settings will only be saved for the current browser you use.
          </p>
        </div>
        <div className={styles['card-item']}>
          <h4>Quick links:</h4>
          <div className={cx('flex-row', styles['cta-wrapper'])}>
            <ButtonLink
              text="My Account"
              pathname="/"
              type="button-primary-transparent"
              additionalStyles={styles['profile-button']}
            />
            <ButtonLink
              text="My Statistics"
              pathname="/my-statistics"
              type="button-primary-transparent"
              additionalStyles={styles['profile-button']}
            />

            <Logout additionalStyles={styles['profile-button']} />
          </div>
        </div>
      </Card>
    </>
  );
};

ProfilePopup.propTypes = {
  userName: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
};

export default ProfilePopup;
