const updateCssVariables = (isDarkMode) => {
  const root = document.documentElement;

  // These are the neutral colors that define the theme. Tweak them for dark mode.

  if (isDarkMode) {
    // Danger color settings:
    root.style.setProperty('--theme-danger-hue', '349');
    root.style.setProperty('--theme-danger-saturation', '52%');
    root.style.setProperty('--theme-danger-lightness', '61%');
    root.style.setProperty('--theme-button-background', '#fff');

    // Neutral colors that define the theme. Tweak them for dark mode.
    root.style.setProperty('--theme-neutral-lightness', '5%');
    root.style.setProperty('--theme-neutral-lightness-lighter', '6%');
    root.style.setProperty('--theme-neutral-lightness-darker', '-29%');
    root.style.setProperty('--theme-base-lightness', '0%');
    root.style.setProperty('--theme-base-darkness', '100%');

    // change saturation and lightness to specific colors
    root.style.setProperty(
      '--theme-text-color-invert-adaptive-lightness',
      '100%'
    );
    root.style.setProperty('--theme-text-color-lightness', '100%');

    // custom colors which are in diff shape on dark mode
    root.style.setProperty(
      '--theme-background-color-light-custom',
      'hsl(0, 0%, 10%)'
    );
    root.style.setProperty(
      '--theme-background-color-medium-custom',
      'hsl(240, 4%, 18%)'
    );
    root.style.setProperty('--theme-text-color-muted', 'hsl(0, 0%, 73%)');

    root.style.setProperty(
      '--field-skeleton-background-color',
      'hsl(0, 0%, 0%)'
    );
    root.style.setProperty(
      '--field-skeleton-shine-background-color',
      'hsl(0, 0%, 8%)'
    );
    root.style.setProperty(
      '--app-general-success-custom',
      'hsl(149, 46%, 42%)'
    );
    root.style.setProperty(
      '--app-general-primary-extra-light-custom',
      'hsl(217, 42%, 41%)'
    );
    root.style.setProperty(
      '--app-general-danger-extra-light',
      'hsl(var(--theme-danger-hue), calc(var(--theme-danger-saturation) - 11%), calc(var(--theme-danger-lightness)))'
    );
    root.style.setProperty(
      '--theme-color-primary-extra-light',
      'hsl(var(--theme-primary-hue), 0, 99%)'
    );

    // notification colors
    root.style.setProperty(
      '--app-general-success-dark-custom',
      'hsla(0, 0%, 100%, 1)'
    );
    root.style.setProperty(
      '--app-general-success-light-custom',
      'hsla(103, 64%, 39%, 1)'
    );
    root.style.setProperty(
      '--app-general-danger-dark-custom',
      'hsla(0, 68%, 96%, 1)'
    );
    root.style.setProperty(
      '--app-general-danger-light-custom',
      'hsla(0, 59%, 49%, 1)'
    );
    root.style.setProperty(
      '--app-general-warning-dark-custom',
      'hsla(44, 100%, 95%, 1)'
    );
    root.style.setProperty(
      '--app-general-warning-light-custom',
      'hsla(40, 100%, 58%, 1)'
    );
    root.style.setProperty(
      '--app-general-info-dark-custom',
      'hsla(0, 0%, 100%, 1)'
    );
    root.style.setProperty(
      '--app-general-info-light-custom',
      'hsla(210, 100%, 21%, 1)'
    );

    // theme default primary and accent colors:
    root.style.setProperty('--theme-default-primary', 'hsl(149, 58%, 31%)');
    root.style.setProperty('--theme-default-accent', 'hsl(149, 57%, 42%)');

    // custom equal colors to all themes  by elements
    root.style.setProperty(
      '--app-general-primary-extra-light',
      'hsl(var(--theme-primary-hue), 90%, 72%)'
    );
    root.style.setProperty(
      '--app-button-primary-adaptive-custom',
      'var(--theme-text-color-invert)'
    );

    root.classList.remove('white');
    root.classList.add('dark');
  } else {
    // Danger color settings:
    root.style.setProperty('--theme-danger-hue', '0');
    root.style.setProperty('--theme-danger-saturation', '79%');
    root.style.setProperty('--theme-danger-lightness', '50%');
    root.style.setProperty('--theme-button-background', '#ddd');

    // Neutral colors that define the theme. Tweak them for dark mode.
    root.style.setProperty('--theme-neutral-lightness', '97%');
    root.style.setProperty('--theme-neutral-lightness-lighter', '100%');
    root.style.setProperty('--theme-neutral-lightness-darker', '10%');
    root.style.setProperty('--theme-base-lightness', '100%');
    root.style.setProperty('--theme-base-darkness', '0%');

    // change saturation and lightness to specific colors
    root.style.setProperty(
      '--theme-text-color-invert-adaptive-lightness',
      '100%'
    );
    root.style.setProperty('--theme-text-color-lightness', '18%');

    // custom colors which are in diff shape on dark mode
    root.style.setProperty(
      '--theme-background-color-light-custom',
      'hsl(0, 0%, var(--theme-neutral-lightness-lighter))'
    );
    root.style.setProperty(
      '--theme-background-color-medium-custom',
      'hsl(0, 0%, 95%)'
    );
    root.style.setProperty('--theme-text-color-muted', 'hsl(210, 28%, 55%)');

    root.style.setProperty(
      '--field-skeleton-background-color',
      'hsl(0, 0%, 84%)'
    );
    root.style.setProperty(
      '--field-skeleton-shine-background-color',
      'hsl(0, 0%, 88%)'
    );
    root.style.setProperty(
      '--app-general-success-custom',
      'hsl(106, 39%, 94%)'
    );
    root.style.setProperty(
      '--app-general-primary-extra-light-custom',
      'hsla(210, 96%, 34%, 0.1)'
    );
    root.style.setProperty(
      '--app-general-danger-extra-light',
      'hsl(var(--theme-danger-hue), var(--theme-danger-saturation), calc(var(--theme-danger-lightness) + 44%))'
    );
    root.style.setProperty(
      '--theme-color-primary-extra-light',
      'hsl(var(--theme-primary-hue), 100%, 99%)'
    );

    // notification colors
    root.style.setProperty(
      '--app-general-success-dark-custom',
      'hsla(103, 64%, 39%, 1)'
    );
    root.style.setProperty(
      '--app-general-success-light-custom',
      'hsla(106, 39%, 94%, 1)'
    );
    root.style.setProperty(
      '--app-general-danger-dark-custom',
      'hsla(0, 59%, 49%, 1)'
    );
    root.style.setProperty(
      '--app-general-danger-light-custom',
      'hsla(0, 68%, 96%, 1)'
    );
    root.style.setProperty(
      '--app-general-warning-dark-custom',
      'hsla(45, 100%, 48%, 1)'
    );
    root.style.setProperty(
      '--app-general-warning-light-custom',
      'hsla(46, 100%, 95%, 1)'
    );
    root.style.setProperty(
      '--app-general-info-dark-custom',
      'hsla(210, 100%, 21%, 1)'
    );
    root.style.setProperty(
      '--app-general-info-light-custom',
      'hsla(210, 47%, 93%, 1)'
    );

    // theme default primary and accent colors:
    root.style.setProperty('--theme-default-primary', 'hsl(210, 96%, 34%)');
    root.style.setProperty('--theme-default-accent', 'hsl(103, 50%, 50%)');

    // custom equal colors to all themes  by elements
    root.style.setProperty(
      '--app-general-primary-extra-light',
      'hsl(var(--theme-primary-hue), 52%, 69%)'
    );
    root.style.setProperty(
      '--app-button-primary-adaptive-custom',
      'var(--theme-color-primary)'
    );
    root.classList.remove('dark');
    root.classList.add('white');
  }
};

export default updateCssVariables;
