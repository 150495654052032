import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import ReactSelect from 'react-select';
import ErrorSolid from 'components/Icons/Error-solid';

import cx from 'classnames';
import styles from './Select.module.scss';
import formStyles from '../Form.module.scss';

/**
 * Uses react-select library
 * https://www.figma.com/file/WjiiLv54k4VGDmUQsYi5Ka/Atrius.com-Page-Layouts?node-id=822%3A10886 - UX can be seen here
 * https://react-select.com/home#animated-components
 * @return {JSX} Select components that supports grpuping
 */
const Select = forwardRef(
  (
    {
      options,
      defaultValue,
      label,
      wrapperClassName,
      error,
      placeholder,
      ...field
    },
    ref
  ) => {
    const formatGroupLabel = (data) => (
      <div>
        <span>{data.label}</span>
        <span>{data.options.length}</span>
      </div>
    );

    return (
      <div
        className={cx(
          formStyles['form-field-wrapper'],
          {
            [formStyles['field-error-wrapper']]: !!error,
          },
          wrapperClassName
        )}
      >
        <ReactSelect
          defaultValue={defaultValue}
          options={options}
          classNamePrefix="select"
          menuPlacement="auto"
          menuPortalTarget={document.querySelector('body')}
          formatGroupLabel={formatGroupLabel}
          className={cx(styles['select'], {
            [styles['select-error']]: !!error,
          })}
          placeholder={placeholder}
          {...field}
          ref={ref}
        />
        {error && (
          <ErrorSolid
            className={cx(formStyles['error-icon'])}
            width="20"
            height="20"
            color={`var(--theme-color-danger-dark)`}
          />
        )}
        {error && (
          <span className={formStyles['error-message']}>{error.message}</span>
        )}
      </div>
    );
  }
);

Select.displayName = 'Select';

Select.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.object,
  wrapperClassName: PropTypes.string,
  error: PropTypes.object,
  placeholder: PropTypes.string,
  options: PropTypes.array,
};

export default Select;
