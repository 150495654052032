import React, { Suspense, useState } from 'react';

import DatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';

// UI Components
import Card from 'components/Card/Card';
import FlexContainer from 'components/FlexContainer/FlexContainer';
import TableReports from 'components/Tables/SingleMember/TableSingleMember';
import UserOverview from 'components/UserOverview/UserOverview';
import WorkCalendar from 'components/WorkCalendar/WorkCalendar';
import { ProjectSelectExternalState } from 'components/Form/Selects/ProjectsSelect';
import SkeletonTable from 'components/LoadingSkeletons/SkeletonTable/SkeletonTable';
import SkeletonLoadingField from 'components/LoadingSkeletons/SkeletonLoadingField/SkeletonLoadingField';

import parseYearMonthDate, {
  parseMonthsToNameFromDate,
} from 'utils/dateFunctions';

// styles
import styles from './SingleMember.module.scss';
import sharedStyles from 'styles/shared.module.scss';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

// data
import instance from 'axiosInstance';
import { useQuery } from 'react-query';

const getUserCard = async ({ queryKey }) => {
  const response = await instance.get(
    '/private/admin/employee-card?slug=' + queryKey[1]
  );

  return response.data;
};

const requestCSV = async ({ queryKey }) => {
  const response = await instance.get(
    `/private/admin/employee-exports/${queryKey[4]}?start_date=${
      queryKey[1]
    }&end_date=${queryKey[2]}${queryKey[3] ? `&project_id=${queryKey[3]}` : ''}`
  );

  return response.data;
};

const TabNewSingleMember = () => {
  const { slug } = useParams();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [project, setProject] = useState(null);

  const { data, isLoading } = useQuery(['user-card', slug], getUserCard, {
    suspense: false,
  });

  const { refetch } = useQuery(
    [
      'user-csv',
      parseYearMonthDate(startDate),
      parseYearMonthDate(endDate),
      project?.value,
      slug,
    ],
    requestCSV,
    {
      enabled: false,
      suspense: false,
      onSuccess: (response) => {
        const fileName = `${slug}'s report -${startDate}-${endDate}`;
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', fileName + '.csv');
        a.click();
      },
    }
  );
  return (
    <>
      <div className={styles['member-information-wrapper']}>
        <Card additionalStyles={styles['member-information-box']}>
          <ErrorBoundary>
            {!isLoading && <UserOverview data={data} />}
          </ErrorBoundary>
        </Card>
        <Card
          hasPadding
          additionalStyles={styles['member-information-reports']}
        >
          <FlexContainer align="align-center" justify="justify-between">
            <span className={styles['filter-title']}>Filter reports by:</span>
            <label className={styles['select-label']}>
              Project
              <ErrorBoundary>
                <Suspense fallback={<SkeletonLoadingField />}>
                  <ProjectSelectExternalState
                    value={project}
                    setterFunction={setProject}
                  />
                </Suspense>
              </ErrorBoundary>
            </label>

            <label className={styles['date-picker-label']}>
              Starting Date
              <DatePicker
                selected={new Date(startDate)}
                onChange={(date) => {
                  if (date <= endDate) {
                    setStartDate(date);
                  }
                }}
              />
            </label>
            <label className={styles['date-picker-label']}>
              Ending date
              <DatePicker
                selected={new Date(endDate)}
                onChange={(date) => {
                  if (date >= startDate) {
                    setEndDate(date);
                  }
                }}
              />
            </label>
          </FlexContainer>
        </Card>

        <div className={styles['member-data-calendar']}>
          <Card
            title="Work recorded"
            hasPadding
            additionalStyles={styles['member-data-calendar-inner']}
          >
            <ErrorBoundary>
              <Suspense fallback={<div>Loading</div>}>
                <WorkCalendar />
              </Suspense>
            </ErrorBoundary>
          </Card>
        </div>
      </div>
      <div className={styles['member-table-wrapper']}>
        <Card
          title={`${data?.employee.name}’s reports for the period of`}
          titleAccent={`${parseMonthsToNameFromDate(
            new Date(startDate)
          )} - ${parseMonthsToNameFromDate(new Date(endDate))}`}
          headerAdditional={
            <span
              onClick={() => {
                refetch();
              }}
              className={sharedStyles['action']}
            >
              <span>Export</span>
            </span>
          }
        >
          <ErrorBoundary>
            <Suspense fallback={<SkeletonTable countTableBodyRows={20} />}>
              <TableReports
                startDate={startDate}
                endDate={endDate}
                projectId={project?.value}
                slug={slug}
              />
            </Suspense>
          </ErrorBoundary>
        </Card>
      </div>
    </>
  );
};

export default TabNewSingleMember;
