import { monthDiff } from 'utils/dateFunctions';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const generateBillingMonths = (startDate) => {
  const difference = monthDiff(startDate, new Date());
  const items = [];

  for (let i = 0; i < difference + 1; i++) {
    const dateNow = new Date();
    const month = dateNow.getMonth();
    const year = dateNow.getFullYear();
    const newDate = new Date(year, month - i, 1);
    let newMonth = newDate.getMonth();
    const newMonthName = monthNames[newMonth];
    newMonth += 1;

    if (newMonth < 10) {
      newMonth = '0' + newMonth;
    }

    items.push({
      label: `${newMonthName} ${newDate.getFullYear()}`,
      value: `${newDate.getFullYear()}-${newMonth}`,
    });
  }

  return items;
};

export const currentMonthObject = () => {
  const newDate = new Date();
  let newMonth = newDate.getMonth();
  const newMonthName = monthNames[newMonth];
  newMonth += 1;

  if (newMonth < 10) {
    newMonth = '0' + newMonth;
  }

  return {
    label: `${newMonthName} ${newDate.getFullYear()}`,
    value: `${newDate.getFullYear()}-${newMonth}`,
  };
};

export default generateBillingMonths;
