import React from 'react';
import { Outlet } from 'react-router-dom';

import AppTabs from 'components/AppTabs/AppTabs';

const pageTabs = [
  {
    to: '/',
    text: 'Reports',
  },
  {
    to: '/my-statistics',
    text: 'My statistics',
  },
];

const Home = () => {
  return (
    <>
      <AppTabs links={pageTabs} />
      <div className="app-body-content">
        <Outlet />
      </div>
    </>
  );
};

export default Home;
