import React from 'react';
import PropTypes from 'prop-types';

import styles from './SubmitButton.module.scss';

const SubmitButton = ({ text, additionalStyles, disabled }) => {
  return (
    <button
      disabled={disabled}
      className={`${styles.button} ${additionalStyles}`}
    >
      {text}
    </button>
  );
};

SubmitButton.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  additionalStyles: PropTypes.string,
};

export default SubmitButton;
