const actionMap = {
  setConfirmationPopup: (state, payload) => ({
    ...state,
    confirmationPopup: payload,
  }),
  setModalContent: (state, payload) => ({ ...state, modalContent: payload }),
  pushNotification: (state, payload) => ({
    ...state,
    notifications: [...state.notifications, payload],
  }),
  setIsAdmin: (state, payload) => ({
    ...state,
    isAdmin: payload,
  }),
  setStopwatchValue: (state, payload) => ({
    ...state,
    stopwatchValue: payload,
  }),
};

const globalReducer = (state, action) => {
  const handler = actionMap[action.type];

  return handler ? handler(state, action.payload) : state;
};

export default globalReducer;
