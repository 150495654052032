import React from 'react';

const Lock = (props) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M13.5 6.75H12.375V4.5C12.375 2.03906 10.3359 0 7.875 0C5.37891 0 3.375 2.03906 3.375 4.5V6.75H2.25C0.984375 6.75 0 7.76953 0 9V15.75C0 17.0156 0.984375 18 2.25 18H13.5C14.7305 18 15.75 17.0156 15.75 15.75V9C15.75 7.76953 14.7305 6.75 13.5 6.75ZM5.0625 4.5C5.0625 2.95312 6.29297 1.6875 7.875 1.6875C9.42188 1.6875 10.6875 2.95312 10.6875 4.5V6.75H5.0625V4.5ZM14.0625 15.75C14.0625 16.0664 13.7812 16.3125 13.5 16.3125H2.25C1.93359 16.3125 1.6875 16.0664 1.6875 15.75V9C1.6875 8.71875 1.93359 8.4375 2.25 8.4375H13.5C13.7812 8.4375 14.0625 8.71875 14.0625 9V15.75Z"
      />
    </svg>
  );
};

export default Lock;
