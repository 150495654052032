import React from 'react';
import { useParams, Link } from 'react-router-dom';

// Components
import FlexContainer from 'components/FlexContainer/FlexContainer';
import ProgressCircle from 'components/ProgressCircle/ProgressCircle';
// import BoxBasic from 'components/Box/BoxBasic/BoxBasic';
import CardSolid from 'components/Card/CardSolid/CardSolid';
// import Boxes from 'components/Box/Boxes';
// import Thumbnail from 'components/Thumbnail/Thumbnail';
// import Button from 'components/Button/Button';

// Styles
import styles from './ProjectInformation.module.scss';
// import boxBasicStyles from 'components/Box/BoxBasic/BoxBasic.module.scss';

import { useQuery } from 'react-query';
import instance from 'axiosInstance';

const ProjectInformation = () => {
  const { slug } = useParams();

  const getProjectInformation = async ({ queryKey }) => {
    const response = await instance.get('/private/projects/' + queryKey[1]);
    return response.data;
  };

  const { data } = useQuery(['projects', slug], getProjectInformation);

  const { po, pm, project, retainer, pmTime, qaTime, devTime } = data[0];

  // const members = [
  //   { name: 'Miro', time: '20h as 80%' },
  //   { name: 'Monika', time: '4h as 10%' },
  //   { name: 'Anita', time: '4h as 10%' },
  // ];

  // const membersToRender = members.map((member) => (
  //   <BoxBasic key={member.name} hasBottomSpace>
  //     <p className={boxBasicStyles['box-title']}>{member.name}</p>
  //     <p className={boxBasicStyles['box-description']}>{member.time}</p>
  //   </BoxBasic>
  // ));

  return (
    <>
      <CardSolid
        title={project.name}
        description="Active members on the project in the past 7 days."
      >
        {/* <Boxes additionalStyles={boxBasicStyles['project-boxes-wrapper']}>
          {membersToRender}
        </Boxes> */}

        <div className={styles['project-thumbnail-wrapper']}>
          {/* <Thumbnail
            src={'https://i.ibb.co/8Kf4rvh/kustomer-project.png'}
            alt="Kustomer"
            customWidth="23.125em"
            borderRadius="var(--app-general-border-radius-larger)"
          /> */}

          {/* <Button
            text="www.kustomer.com"
            type="button-primary"
            additionalInlineStyles={{
              position: 'absolute',
              fontSize: '1em',
              padding: '0.5em 1em',
              bottom: '0.625em',
              right: '0.625em',
              zIndex: '1',
            }}
          /> */}
        </div>
      </CardSolid>
      <FlexContainer
        justify="justify-between"
        align="align-center"
        spaceY="space-y-1-75em"
        wrap="wrap"
      >
        <div className={styles['project-items']}>
          <FlexContainer justify="justify-between">
            <div className={styles['cell']}>
              <span className={styles['label']}>Retainer plan: </span>
              <span className={styles['value']}>{`${retainer}h`}</span>
            </div>
            <div className={styles['cell']}>
              <span className={styles['label']}>Project Owner: </span>
              <Link to={`/member/${po.slug}`} className={styles['value']}>
                {po.name}
              </Link>
            </div>
            <div className={styles['cell']}>
              <span className={styles['label']}>Project Manager: </span>
              <Link to={`/member/${pm.slug}`} className={styles['value']}>
                {pm.name}
              </Link>
            </div>
          </FlexContainer>
        </div>
        <FlexContainer>
          <FlexContainer
            align="align-center"
            justify="justify-center"
            spaceX="space-x-5px"
          >
            <ProgressCircle progress={pmTime} text="PM" />
          </FlexContainer>
          <FlexContainer
            align="align-center"
            justify="justify-center"
            spaceX="space-x-5px"
          >
            <ProgressCircle progress={devTime} text="Dev" />
          </FlexContainer>
          <FlexContainer
            align="align-center"
            justify="justify-center"
            spaceX="space-x-5px"
          >
            <ProgressCircle progress={qaTime} text="QA" />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </>
  );
};

export default ProjectInformation;
