import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Select from './Select';
import { useController } from 'react-hook-form';

import { useQuery } from 'react-query';
import instance from 'axiosInstance';

const getMembers = async () => {
  const response = await instance.get('/private/admin/employees');
  return response.data;
};
const AllActiveMembersSelect = ({ control, name, rules, ...props }) => {
  const { data } = useQuery('employees', getMembers);

  const options = useMemo(
    () =>
      data
        .filter((member) => member.isActive === true)
        .map((member) => ({
          label: member.employee.name,
          value: member.id,
        })),
    []
  );

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  return <Select error={error} {...field} options={options} {...props} />;
};

AllActiveMembersSelect.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  rules: PropTypes.object,
};

export default AllActiveMembersSelect;
