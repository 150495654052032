import React from 'react';
import { useTabContext } from 'GlobalState/Context/TabContext';
import { useParams } from 'react-router-dom';

import Select from './Select';

// Utils
import generateBillingMonths from 'utils/generateBillingMonths';
import { currentMonthObject } from 'utils/generateBillingMonths';

import { useQuery } from 'react-query';
import instance from 'axiosInstance';

const getAvailableBillingPeriods = async ({ queryKey }) => {
  const response = await instance.get(
    '/private/project-billable-period?project=' + queryKey[1]
  );

  return response.data;
};

const BillingReportFilter = () => {
  const { slug } = useParams();
  const { dispatch } = useTabContext();

  const { data } = useQuery(
    ['billable-period', slug],
    getAvailableBillingPeriods
  );

  const handleChange = (e) => {
    dispatch({ type: 'setBillingPeriod', payload: e });
  };
  return (
    <Select
      placeholder="Filter by month"
      onChange={handleChange}
      options={data}
      defaultValue={currentMonthObject}
    />
  );
};

export const OverviewBillingReportFilter = () => {
  const { dispatch } = useTabContext();

  const options = generateBillingMonths(new Date('2019-05-01'));

  const handleChange = (e) => {
    dispatch({ type: 'setBillingPeriod', payload: e });
  };

  return (
    <Select
      options={options}
      onChange={handleChange}
      placeholder="Filter by month"
      defaultValue={currentMonthObject}
    />
  );
};

const getUserInformation = async ({ queryKey }) => {
  const response = await instance.get(
    '/private/employee-information' +
      (queryKey[1] ? '?employee_slug=' + queryKey[1] : '')
  );
  return response.data;
};

export const CalendarMonthFilter = () => {
  const { dispatch } = useTabContext();
  const { slug } = useParams();

  const { data } = useQuery(
    ['user-information', slug || null],
    getUserInformation
  );

  const options = generateBillingMonths(new Date(data.dateOfStart));

  const handleChange = (e) => {
    dispatch({ type: 'setCalendarPeriod', payload: e });
  };

  return (
    <Select
      options={options}
      onChange={handleChange}
      placeholder="Filter by month"
      defaultValue={currentMonthObject}
    />
  );
};

export default BillingReportFilter;
