import React from 'react';
import PropTypes from 'prop-types';

import styles from './Card.module.scss';

const Card = ({
  title,
  titleAccent,
  leftAdditional,
  subtitle,
  children,
  headerAdditional,
  hasPadding,
  hasScroll,
  additionalStyles = '',
}) => {
  const header = (
    <header className={styles['header']}>
      <h2 className={styles['header-title']}>
        {title}
        <span className={styles['header-title-accent']}>{titleAccent}</span>
      </h2>
      {subtitle ? (
        <h3 className={styles['header-subtitle']}>{subtitle}</h3>
      ) : (
        ''
      )}
      {leftAdditional && (
        <div className={styles['header-left-additional']}>{leftAdditional}</div>
      )}
      <div className={styles['header-additional']}>{headerAdditional}</div>
    </header>
  );

  return (
    <section className={`${styles['card']} ${additionalStyles}`}>
      {/* We might not always want to have a header here, you see... */}
      {title ? header : ''}

      <div
        className={`${styles['body']} ${
          hasPadding ? '' : styles['no-padding']
        } ${hasScroll ? 'custom-scroll' : ''}`}
      >
        {children}
      </div>
    </section>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  titleAccent: PropTypes.string,
  leftAdditional: PropTypes.object,
  subtitle: PropTypes.string,
  headerAdditional: PropTypes.object,
  hasPadding: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  additionalStyles: PropTypes.string,
  hasScroll: PropTypes.bool,
};

Card.defaultProps = {
  hasPadding: false,
};

export default Card;
