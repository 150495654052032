import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './CardSolid.module.scss';

const CardSolid = ({ children, title, description }) => {
  const header = (
    <header className={cx(styles.header)}>
      <h2 className={cx(styles.title)}>{title}</h2>
    </header>
  );

  const footer = (
    <footer className={cx(styles.footer)}>
      <p className={cx(styles.description)}>{description}</p>
    </footer>
  );

  return (
    <div className={cx(styles.card)}>
      <div className={cx(styles['card-inner'])}>
        {title ? header : ''}
        <div className={cx(styles.main)}>{children}</div>
        {description ? footer : ''}
      </div>
    </div>
  );
};

CardSolid.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  description: PropTypes.string,
};

export default CardSolid;
