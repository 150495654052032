import React, { Suspense } from 'react';
import { useTabContext } from 'GlobalState/Context/TabContext';

// Table
import ProjectsOverviewTable from 'components/Tables/TableProjectsOverview/TableProjectsOverview';
import { OverviewBillingReportFilter } from 'components/Form/Selects/BillingReportFilter';
import SearchFilter from 'components/Tables/SearchField';
import SkeletonTable from 'components/LoadingSkeletons/SkeletonTable/SkeletonTable';

// Ui Components
import CardSolid from 'components/Card/CardSolid/CardSolid';
import Card from 'components/Card/Card';
import FlexContainer from 'components/FlexContainer/FlexContainer';
import Boxes from 'components/Box/Boxes';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import FavouriteProjects from 'components/FavouriteProjectsSection/FavouriteProjects';

// styles
import boxBasicStyles from 'components/Box/BoxBasic/BoxBasic.module.scss';

const TabOverview = () => {
  const {
    state: { billingPeriod },
  } = useTabContext();

  return (
    <div>
      <CardSolid
        title="Your favourite projects"
        description="You can use the heart icon from the table below to add projects to this section."
      >
        <Boxes additionalStyles={boxBasicStyles['project-boxes-wrapper']}>
          <ErrorBoundary>
            <Suspense fallback={null}>
              <FavouriteProjects />
            </Suspense>
          </ErrorBoundary>
        </Boxes>
      </CardSolid>
      <Card
        title="Project Overview"
        titleAccent={billingPeriod.label}
        headerAdditional={
          <FlexContainer justify="justify-between" wrap="wrap">
            <div className="width-medium">
              <ErrorBoundary>
                <OverviewBillingReportFilter />
              </ErrorBoundary>
            </div>
            <div className="width-medium">
              <SearchFilter />
            </div>
          </FlexContainer>
        }
      >
        <ErrorBoundary>
          <Suspense fallback={<SkeletonTable countTableBodyRows={40} />}>
            <ProjectsOverviewTable />
          </Suspense>
        </ErrorBoundary>
      </Card>
    </div>
  );
};

export default TabOverview;
