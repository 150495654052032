import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './AppHeader.module.scss';

import Logo from './Logo/Logo';
import Profile from './Profile/Profile';
import TimeTracker from 'components/TimeTracker/TimeTracker';

import MobileMenuIcon from 'components/Icons/Mobile-Menu';
import Button from 'components/Button/Button';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import SkeletonProfileCard from 'components/LoadingSkeletons/SkeletonProfileCard/SkeletonProfileCard';

const AppHeader = ({ isActiveMenu }) => {
  return (
    <header className={styles['header']}>
      <Link className={styles['logo-wrapper']} to="/">
        <Logo className={styles['logo']} />
      </Link>

      {/* The header group modifies the flex properties. */}
      <div className={styles['header-group']}>
        <TimeTracker />
      </div>
      <ErrorBoundary>
        <Suspense fallback={<SkeletonProfileCard />}>
          <Profile />
        </Suspense>
      </ErrorBoundary>
      <Button
        clicked={isActiveMenu}
        additionalStyles={styles['mobile-menu-wrapper']}
        icon={
          <MobileMenuIcon
            width="26"
            height="26"
            color={`var(--theme-text-color)`}
            className={styles['mobile-menu']}
          />
        }
      />
    </header>
  );
};

AppHeader.propTypes = {
  isActiveMenu: PropTypes.func,
};

export default AppHeader;
