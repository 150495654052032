import React, { useEffect, useMemo, Suspense } from 'react';
import PropTypes from 'prop-types';
import { useTabContext } from 'GlobalState/Context/TabContext';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Forms
const PositionsForm = React.lazy(() =>
  import('components/Form/Manage/ManagePositionsForm/PositionsForm')
);

// Table
import {
  useTable,
  useSortBy,
  useFlexLayout,
  useGlobalFilter,
} from 'react-table';
import Label from 'components/Label/Label';
import Edit from 'components/Icons/Edit';
import Button from 'components/Button/Button';
import IsActiveCheckbox from 'components/Checkbox/IsActiveCheckbox';

// styles
import cx from 'classnames';
import tableStyles from '../../Tables.module.scss';
import styles from '../Manage.module.scss';

// Data fetching
import { useQuery } from 'react-query';
import instance from 'axiosInstance';

/**
 * The one, the only
 *
 * This is the home page table and it's the most used component of the whole app
 * @see https://react-table.js.org/ - 90% of the stuff is there.
 * @return {JSX} My reports table
 */

// Todo: - Modal on top. Data changes on edit click
const TablePositionsUI = ({ data }) => {
  // accessor is the "key" in the data
  const {
    state: { searchFilter },
  } = useTabContext();

  const { globalDispatch } = useGlobalContext();
  const columns = useMemo(
    () => [
      {
        Header: 'Position',
        accessor: 'title',
        maxWidth: 1900,
        minWidth: 200,
        width: 200,
      },
      {
        Header: 'Billed As',
        accessor: 'bill_as',
        maxWidth: 300,
        minWidth: 200,
        width: 150,
      },
      {
        Header: 'Activity',
        accessor: 'isActive',
        maxWidth: 52,
        minWidth: 100,
        width: 100,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 14,
        minWidth: 120,
        width: 120,
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  const handleEditPopup = (row) => {
    globalDispatch({
      type: 'setModalContent',
      payload: (
        <Suspense fallback={<div>Loading</div>}>
          <PositionsForm row={row} heading="Edit Positions" type="edit" />
        </Suspense>
      ),
    });
  };

  /**
   * The useTable hook is what gives us the instances to render the table.
   * @see https://react-table.js.org/api/useTable
   */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    useFlexLayout
  );

  useEffect(() => {
    setGlobalFilter(searchFilter);
  }, [searchFilter]);

  /**
   * In the header, we are showing the label + can add sorting options
   */

  const tableHeader = useMemo(
    () =>
      headerGroups.map((headerGroup, key) => (
        <div
          key={key}
          className={cx(tableStyles.tr, tableStyles['sticky-row'])}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map((column) => (
            <div
              key={column.id}
              className={`${tableStyles.th} ${styles.th}`}
              {...column.getHeaderProps(column.getSortByToggleProps())}
            >
              <Label
                fontStyle="bold"
                arrow={column.canSort ? 'arrow-down' : ''}
              >
                {column.render('Header')}
              </Label>
            </div>
          ))}
        </div>
      )),
    []
  );

  /**
   * The tableBody is the date we get from the API. Currently static :)
   */
  const tableBody = useMemo(
    () =>
      rows.map((row) => {
        // (Required) Any row that you intend to render in your table needs to be passed to
        // this function before every render.
        prepareRow(row);

        return (
          <div key={row.id} className={tableStyles.tr} {...row.getRowProps()}>
            {row.cells.map((cell) => {
              let output = <Label>{cell.value}</Label>;
              // Standard output

              if (cell.column.id === 'actions') {
                output = (
                  <div className={tableStyles['actions-wrapper']}>
                    <Button
                      type="button-primary-inline"
                      icon={<Edit width="20px" height="20px" />}
                      clicked={() => handleEditPopup(row.original)}
                    />
                  </div>
                );
              }

              if (cell.column.id === 'isActive') {
                output = (
                  <IsActiveCheckbox
                    endpoint={
                      '/private/admin/employees-positions/active/' +
                      row.original.id
                    }
                    isChecked={row.original.is_active === '1' ? true : false}
                    queriesToInvalidate={['positions']}
                  />
                );
              }

              if (cell.column.id === 'bill_as') {
                output = cell.value?.toUpperCase();
              }

              return (
                <div
                  key={cell.column.id}
                  className={cx(tableStyles.td)}
                  {...cell.getCellProps()}
                >
                  {output}
                </div>
              );
            })}
          </div>
        );
      }),
    [rows]
  );

  return (
    <>
      <div className={tableStyles.table} {...getTableProps}>
        <div className={`${tableStyles['thead-sticky']} thead`}>
          {tableHeader}
        </div>
        <div className={tableStyles.tbody} {...getTableBodyProps()}>
          {tableBody}
        </div>
      </div>
    </>
  );
};
TablePositionsUI.propTypes = {
  data: PropTypes.object,
};
const getPositions = async () => {
  const response = await instance.get('/private/admin/employees-positions');
  return response.data;
};
const TablePositions = ({ shouldShowInactive }) => {
  const { data } = useQuery('positions', getPositions);

  const activePositions = data.filter((position) => position.is_active === '1');

  return (
    <TablePositionsUI data={shouldShowInactive ? data : activePositions} />
  );
};

TablePositions.propTypes = {
  shouldShowInactive: PropTypes.bool,
};

export default TablePositions;
