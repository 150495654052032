import MyTasksIcon from '../../components/Icons/ChecklistLight';
import ProjectsIcon from '../../components/Icons/FileLight';
import ManageIcon from '../../components/Icons/AddressCardLight';
import MembersOverviewIcon from '../../components/Icons/UsersLight';
// import ReportProblemIcon from '../../components/Icons/ExclamationLight';
// import RequestFeatureIcon from '../../components/Icons/FlaskLight';
// import HowToUseIcon from '../../components/Icons/QuestionLight';

export default [
  {
    label: 'Main menu',
    items: [
      {
        to: '/',
        text: 'My Tasks',
        icon: <MyTasksIcon height="16px" width="16px" />,
      },
      {
        to: '/projects',
        text: 'Projects',
        icon: <ProjectsIcon height="16px" width="16px" />,
      },
    ],
  },
  {
    label: 'Admin',
    items: [
      {
        to: '/manage/employees-positions',
        text: 'Manage',
        icon: <ManageIcon height="16px" width="16px" />,
      },
      {
        to: '/members-overview',
        text: 'Members Overview',
        icon: <MembersOverviewIcon height="16px" width="16px" />,
      },
    ],
  },
  // {
  //   label: 'Misc',
  //   items: [
  //     {
  //       to: '/report-problem',
  //       text: 'Report a problem',
  //       icon: <ReportProblemIcon height="16px" width="16px" />,
  //     },
  //     {
  //       to: '/request-feature',
  //       text: 'Request a feature',
  //       icon: <RequestFeatureIcon height="16px" width="16px" />,
  //     },
  //     {
  //       to: '/how-to',
  //       text: 'How to use',
  //       icon: <HowToUseIcon height="16px" width="16px" />,
  //     },
  //   ],
  // },
];
