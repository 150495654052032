import React from 'react';
import PropTypes from 'prop-types';

import styles from './DataOverview.module.scss';

const DataOverview = ({ accent, date, title }) => {
  const titleEl = title ? <p className={styles['data-title']}>{title}</p> : '';
  const dateEl = date ? <p className={styles['data-date']}>{date}</p> : '';
  const accentEl = accent ? (
    <p className={styles['data-accent']}>{accent}</p>
  ) : (
    ''
  );

  return (
    <div className={styles['data-overview-wrapper']}>
      {titleEl}
      {dateEl}
      {accentEl}
    </div>
  );
};

DataOverview.propTypes = {
  accent: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
};

export default DataOverview;
