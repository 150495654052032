import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import cx from 'classnames';
import styles from './Button.module.scss';

const ButtonLink = ({
  pathname,
  text,
  type,
  additionalStyles,
  additionalInlineStyles,
  icon,
}) => {
  return (
    <Link
      className={cx(styles.button, styles[type], additionalStyles)}
      style={additionalInlineStyles}
      to={pathname}
    >
      {text}
      {icon}
    </Link>
  );
};

// additionalStyles is to add new class
// additionalInlineStyles is to add new css to override the initial styles of the button
// type is used to set the design type of the button

ButtonLink.propTypes = {
  icon: PropTypes.symbol,
  text: PropTypes.string,
  type: PropTypes.string,
  additionalStyles: PropTypes.string,
  additionalInlineStyles: PropTypes.string,
  pathname: PropTypes.string,
};

export default ButtonLink;
