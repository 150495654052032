import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Table
import { useTable, useSortBy, useFlexLayout, useRowSelect } from 'react-table';
import Label from 'components/Label/Label';
import CheckSolid from 'components/Icons/Check-solid';

// styles
import cx from 'classnames';
import tableStyles from '../Tables.module.scss';

// utils
import parseYearMonthDate from 'utils/dateFunctions';

import { useQuery } from 'react-query';
import instance from 'axiosInstance';

/**
 * The one, the only
 *
 * This is the home page table and it's the most used component of the whole app
 * @see https://react-table.js.org/ - 90% of the stuff is there.
 * @return {JSX} My reports table
 */

// Todo: - Modal on top. Data changes on edit click
const TableSingleMemberUI = ({ data }) => {
  // accessor is the "key" in the data
  const columns = useMemo(
    () => [
      {
        accessor: 'toggleRowSelected',
        maxWidth: 60,
        minWidth: 60,
        width: 60,
      },
      {
        Header: 'Project Name',
        accessor: 'project',
        minWidth: 100,
        width: 200,
      },
      {
        Header: 'Component',
        accessor: 'componentName',
        minWidth: 100,
        width: 200,
      },
      {
        Header: 'Description',
        accessor: 'description',
        minWidth: 200,
        width: 300,
      },
      {
        Header: 'Task',
        accessor: 'task',
        minWidth: 100,
        width: 150,
      },
      {
        Header: 'Time',
        accessor: 'time',
        minWidth: 80,
        width: 100,
      },
    ],
    []
  );

  /**
   * The useTable hook is what gives us the instances to render the table.
   * @see https://react-table.js.org/api/useTable
   */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    isAllRowsSelected,
    footerGroups,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
    },
    useSortBy,
    useFlexLayout,
    useRowSelect
  );

  /**
   * In the header, we are showing the label + can add sorting options
   */

  const tableHeader = headerGroups.map((headerGroup, key) => (
    <div
      key={key}
      className={cx(tableStyles.tr, tableStyles['sticky-row'])}
      {...headerGroup.getHeaderGroupProps()}
    >
      {headerGroup.headers.map((column) => {
        let output = <Label>{column.render('Header')}</Label>;

        if (column.id === 'toggleRowSelected') {
          output = (
            <>
              <label
                htmlFor="toggle-all-rows-checkbox"
                className={cx(
                  tableStyles['checkbox-wrapper'],
                  tableStyles['w-40px'],
                  {
                    [tableStyles['has-color']]: isAllRowsSelected,
                  }
                )}
              >
                {isAllRowsSelected && (
                  <CheckSolid
                    color={`var(--theme-base-color)`}
                    width="18px"
                    height="14px"
                  />
                )}
              </label>
              <input
                className="is-hidden"
                type="checkbox"
                id="toggle-all-rows-checkbox"
                onChange={() => {
                  toggleAllRowsSelected(!isAllRowsSelected);
                }}
              />
            </>
          );
        }
        return (
          <div
            key={column.id}
            className={tableStyles.th}
            {...column.getHeaderProps(column.getSortByToggleProps())}
          >
            {output}
          </div>
        );
      })}
    </div>
  ));

  /**
   * The tableBody is the date we get from the API. Currently static :)
   */
  const tableBody = rows.map((row) => {
    // (Required) Any row that you intend to render in your table needs to be passed to
    // this function before every render.
    prepareRow(row);

    return (
      <div key={row.id} className={tableStyles.tr} {...row.getRowProps()}>
        {row.cells.map((cell) => {
          let output = <Label>{cell.value}</Label>;
          // Standard output

          if (cell.column.id === 'time') {
            output = <Label>{cell.value}h</Label>;
          }

          if (cell.column.id === 'project') {
            // In the future, set color for each project.
            output = (
              <Link to={`/project/${cell.value.slug}`}>{cell.value.name}</Link>
            );
          }

          if (cell.column.id === 'task') {
            if (cell.value.link && cell.value.link !== 'undefined') {
              output = (
                <a target="_blank" href={cell.value.link} rel="noreferrer">
                  {cell.value.title || 'Open Link'}
                </a>
              );
            } else {
              output = '';
            }
          }

          if (cell.column.id === 'toggleRowSelected') {
            output = (
              <>
                <label
                  htmlFor={`checkbox-${row.id}`}
                  className={cx(
                    tableStyles['checkbox-wrapper'],
                    tableStyles['w-40px'],
                    {
                      [tableStyles['has-color']]: row.isSelected,
                    }
                  )}
                >
                  {row.isSelected && (
                    <CheckSolid
                      color={`var(--theme-base-color)`}
                      width="18px"
                      height="14px"
                    />
                  )}
                </label>
                <input
                  className="is-hidden"
                  type="checkbox"
                  id={`checkbox-${row.id}`}
                  {...row.getToggleRowSelectedProps()}
                />
              </>
            );
          }

          return (
            <div
              key={cell.column.id}
              className={cx(tableStyles.td)}
              {...cell.getCellProps()}
            >
              {output}
            </div>
          );
        })}
      </div>
    );
  });

  let sum = 0;

  const selectedRows = rows.filter((row) => {
    prepareRow(row);

    return row.isSelected;
  });

  selectedRows.map((row) => {
    sum += Number(row.original.time);
  });

  const tableFooter = footerGroups.map((footerGroup, key) => (
    <div
      key={key}
      className={cx(tableStyles['sticky-row'])}
      {...footerGroup.getFooterGroupProps()}
    >
      {footerGroup.headers.map((column) => {
        let output = null;

        if (column.id === 'time') {
          output = <Label>{sum}h</Label>;
        }
        return (
          <div
            key={column.id}
            className={tableStyles.td}
            {...column.getFooterProps()}
          >
            {output}
          </div>
        );
      })}
    </div>
  ));

  return (
    <div className={tableStyles.table} {...getTableProps}>
      <div className={`${tableStyles['thead-sticky']} thead`}>
        {tableHeader}
      </div>
      <div className={tableStyles.tbody} {...getTableBodyProps()}>
        {tableBody}
      </div>
      <div className={tableStyles['tfoot-sticky']}>{tableFooter}</div>
    </div>
  );
};

TableSingleMemberUI.propTypes = {
  data: PropTypes.array,
};

const getSingleMemberTable = async ({ queryKey }) => {
  const response = await instance.get(
    `private/admin/employees-reports/get-by-period?start_date=${
      queryKey[1]
    }&end_date=${queryKey[2]}&employee_slug=${queryKey[3]}${
      queryKey[4] ? `&project=${queryKey[4]}` : ''
    }`
  );

  return response.data;
};

const TableSingleMember = ({ startDate, endDate, projectId, slug }) => {
  const { data } = useQuery(
    [
      'single-member-table',
      parseYearMonthDate(new Date(startDate)),
      parseYearMonthDate(new Date(endDate)),
      slug,
      projectId,
    ],
    getSingleMemberTable
  );

  return <TableSingleMemberUI data={data} />;
};

TableSingleMember.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  projectId: PropTypes.string,
  slug: PropTypes.string,
};

export default TableSingleMember;
