import React from 'react';

import Card from 'components/Card/Card';
import ReportProblemForm from 'components/Form/Request/ReportProblem/ReportProblem';

const TabRequestProblem = () => {
  return (
    <Card hasPadding>
      <ReportProblemForm />
    </Card>
  );
};

export default TabRequestProblem;
