import React from 'react';

const ArrowDown = (props) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="sort-down"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      className="svg-inline--fa fa-sort-down fa-w-10 fa-2x"
      {...props}
    >
      <path
        fill="currentColor"
        d="M287.968 288H32.038c-28.425 0-42.767 34.488-22.627 54.627l127.962 128c12.496 12.496 32.758 12.497 45.255 0l127.968-128C330.695 322.528 316.45 288 287.968 288zM160 448L32 320h256L160 448z"
        className=""
      ></path>
    </svg>
  );
};

export default ArrowDown;
