import React from 'react';

import styles from '../../AppHeader/Profile/Profile.module.scss';
import SkeletonCustomField from '../SkeletonCustomField/SkeletonCustomField';

const SkeletonProfileCard = () => {
  return (
    <div className={styles['profile']}>
      <span className={styles['name']}>
        <SkeletonCustomField
          height="18px"
          width="130px"
          backgroundColor="var(--field-skeleton-background-color)"
          backgroundShineColor="var(--field-skeleton-shine-background-color)"
        />
      </span>
      <div className={styles['profile-wrapper']}>
        <SkeletonCustomField
          height="2.75em"
          width="2.75em"
          additionalStyles={styles['profile-thumbnail']}
          backgroundColor="var(--theme-color-primary-light)"
          backgroundShineColor="var(--theme-color-custom-shine)"
          borderRadius="var(--app-general-border-radius-larger)"
        />
      </div>
    </div>
  );
};

export default SkeletonProfileCard;
