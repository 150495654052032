import React, { useState } from 'react';

import updateCssVariables from './updateCssVariblesDarkMode';

import Toggle from '../Toggle';
import updateCssThemeColorVariables from 'components/ThemeOptions/updateCssVariblesThemeColors';

const ToggleDarkMode = () => {
  const isDark = JSON.parse(localStorage.getItem('is-dark'));
  const [isToggled, setIsToggled] = useState(isDark);

  const handleDarkMode = () => {
    updateCssVariables(!isDark);

    setIsToggled((v) => !v);

    localStorage.setItem('is-dark', JSON.stringify(!isDark));

    const theme = localStorage.getItem('theme');
    updateCssThemeColorVariables(theme);
  };
  return (
    <Toggle
      clicked={handleDarkMode}
      htmlFor="toggle-dark-mode"
      isToggled={isToggled}
      type="toggle-muted"
    />
  );
};

export default ToggleDarkMode;
