import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import styles from './AppSidebar.module.scss';

import MenuGroup from './MenuGroup/MenuGroup';
import MenuExpandIcon, {
  MenuExpandArrows,
} from 'components/Icons/MenuExpandIcon';

import { useQuery } from 'react-query';
import { authorizationQueryFunc } from 'utils/sharedFetchers';

const AppSidebar = ({
  navigationMenuItems,
  isActiveMobileMenu,
  setIsActiveMobileMenu,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { data, isLoading } = useQuery(
    'authorization',
    authorizationQueryFunc,
    {
      suspense: false,
    }
  );

  const filteredNavigationItems = navigationMenuItems.filter(
    ({ label }) => label !== 'Admin' || (!isLoading && !!data.is_admin)
  );

  const menuGroups = filteredNavigationItems.map(({ label, items, state }) => (
    <MenuGroup
      key={label}
      state={state}
      menuItems={items}
      label={label}
      isExpanded={isExpanded}
      setIsActiveMobileMenu={setIsActiveMobileMenu}
    />
  ));

  return (
    <aside
      className={cx(
        styles['sidebar'],
        'custom-scroll',
        isActiveMobileMenu ? styles.active : ''
      )}
    >
      <div
        className={cx(styles['sidebar-inner'], {
          [styles['is-expanded']]: isExpanded,
        })}
      >
        <span
          className={styles['expand-icon']}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <MenuExpandIcon
            width="25"
            height="25"
            className={styles['expand-icon-box']}
          />
          <MenuExpandArrows className={styles['expand-icon-arrows']} />
        </span>
        {!isLoading && menuGroups}
      </div>
    </aside>
  );
};

AppSidebar.propTypes = {
  navigationMenuItems: PropTypes.array,
  isActiveMobileMenu: PropTypes.bool,
  setIsActiveMobileMenu: PropTypes.func,
  isAdmin: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default AppSidebar;
