import React from 'react';

import BoxBasic from 'components/Box/BoxBasic/BoxBasic';
import boxBasicStyles from 'components/Box/BoxBasic/BoxBasic.module.scss';

import instance from 'axiosInstance';
import { useQuery } from 'react-query';

const getFavouriteProjects = async () => {
  const response = await instance.get(
    '/private/employee-information/favourites'
  );

  return response.data;
};

const FavouriteProjects = () => {
  const { data } = useQuery('favourite-projects', getFavouriteProjects);

  const projects = data.map((project, key) => (
    <BoxBasic link={`/project/${project.projectSlug}`} key={key} hasBottomSpace>
      <p className={boxBasicStyles['box-title']}>{project.projectName}</p>
      <p className={boxBasicStyles['box-description']}>
        {project.retainerHours}h retainer
      </p>
    </BoxBasic>
  ));

  return projects;
};

export default FavouriteProjects;
