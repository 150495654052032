import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import styles from './Toggle.module.scss';

const Toggle = ({ clicked, htmlFor, isToggled, type }) => {
  return (
    <span
      onClick={clicked}
      className={cx(styles.toggle, styles[type])}
      htmlFor={htmlFor}
    >
      <span
        className={cx(styles['toggle-button'], {
          [styles['toggled']]: isToggled,
        })}
      />
    </span>
  );
};

Toggle.propTypes = {
  clicked: PropTypes.func,
  htmlFor: PropTypes.string,
  isToggled: PropTypes.bool,
  type: PropTypes.string,
};

export default Toggle;
