import React, { Suspense } from 'react';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';
import { useParams, Outlet } from 'react-router-dom';

import AppTabs from 'components/AppTabs/AppTabs';
import ProjectInformation from './ProjectInformation/ProjectInformation';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

const Project = () => {
  const { slug } = useParams();
  const {
    globalState: { isAdmin },
  } = useGlobalContext();

  const pageTabsAdmin = [
    {
      to: `/project/${slug}`,
      text: 'Project Reports Overview',
    },
    {
      to: `/project/${slug}/components-table`,
      text: 'Components',
    },
    {
      to: `/project/${slug}/involved-table`,
      text: 'Involved',
    },
  ];

  const pageTabs = [
    {
      to: `/project/${slug}`,
      text: 'Project Reports Overview',
    },
  ];

  return (
    <>
      <AppTabs links={isAdmin ? pageTabsAdmin : pageTabs} />
      <div className="app-body-content">
        <ErrorBoundary>
          <Suspense fallback={null}>
            <ProjectInformation />
          </Suspense>
        </ErrorBoundary>
        <Outlet />
      </div>
    </>
  );
};

export default Project;
