import React from 'react';
import SkeletonCustomField from '../SkeletonCustomField/SkeletonCustomField';

// Styles
import cx from 'classnames';
import styles from './skeletonWorkedDays.module.scss';
import workedDaysStyles from '../../WorkedHoursPerDay/WorkedHoursPerDay.module.scss';

const SkeletonWorkedDaysBox = (day) => {
  return (
    <div
      className={cx(
        workedDaysStyles.box,
        'flex-row',
        'centered-horizontally',
        'centered-vertically'
      )}
    >
      <span
        className={cx(workedDaysStyles.date, 'flex-row', 'centered-vertically')}
      >
        {day.day},
        {
          <SkeletonCustomField
            width="38px"
            height="18px"
            backgroundColor="var(--field-skeleton-background-color)"
            backgroundShineColor="var(--field-skeleton-shine-background-color)"
            additionalStyles={styles['skeleton-date']}
          />
        }
      </span>
      <SkeletonCustomField
        width="40px"
        height="18px"
        backgroundColor="var(--field-skeleton-background-color)"
        backgroundShineColor="var(--field-skeleton-shine-background-color)"
      />
    </div>
  );
};

export default SkeletonWorkedDaysBox;
