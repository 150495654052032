import React from 'react';

const CheckBoldSolid = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 0C10.0508 0 0.4375 9.70312 0.4375 21.5625C0.4375 33.5117 10.0508 43.125 22 43.125C33.8594 43.125 43.5625 33.5117 43.5625 21.5625C43.5625 9.70312 33.8594 0 22 0ZM32.332 17.6094L20.832 29.1094C20.3828 29.6484 19.7539 29.8281 19.125 29.8281C18.4062 29.8281 17.7773 29.6484 17.3281 29.1094L11.5781 23.3594C10.5898 22.3711 10.5898 20.8438 11.5781 19.8555C12.5664 18.8672 14.0938 18.8672 15.082 19.8555L19.125 23.8086L28.8281 14.1055C29.8164 13.1172 31.3438 13.1172 32.332 14.1055C33.3203 15.0938 33.3203 16.6211 32.332 17.6094Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckBoldSolid;
