import React from 'react';
import { Routes, Route, Navigate, Link } from 'react-router-dom';

import Logo from 'components/AppHeader/Logo/Logo';
import LoginForm from 'components/Form/Auth/LoginForm';
import ForgotPassword from 'components/Form/Auth/ForgotPassword';
import CreatePasswordForm from 'components/Form/Auth/CreatePasswordForm';

import cx from 'classnames';
import styles from './LoginView.module.scss';

const LoginView = () => {
  return (
    <div className={styles['login-view']}>
      <main
        className={cx(
          styles['login-view-sidebar'],
          'flex-row',
          'centered-vertically',
          'centered-horizontally'
        )}
      >
        <div className={styles['login-view-sidebar-inner']}>
          <div className={styles['sidebar-header']}>
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <div className={styles['sidebar-body']}>
            <header className={styles['header']}>
              <h2 className={styles['header-title']}>
                Welcome to the DX Time Tracker
              </h2>
              <h3 className={styles['header-subtitle']}>
                You can sign in with the same credentials from TT v1
              </h3>
            </header>

            <div className={styles['login-form-wrapper']}>
              <Routes>
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/" element={<LoginForm />} />
                <Route
                  path="/change-password"
                  element={<CreatePasswordForm />}
                />
                <Route
                  index
                  path="/*"
                  element={<Navigate replace to="/" />}
                ></Route>
              </Routes>
            </div>
          </div>
        </div>
      </main>
      <aside className={styles['login-view-section']}>
        <div className={styles['login-view-section-inner']}>
          <div className={styles['header']}>
            {/* <h2 className={styles['header-title']}>
                Smth may be here, noted that there is some pattern used on more
                than 1 place
              </h2> */}
            {/* <h3 className={styles['header-subtitle']}>
                Some kind of instructions are suitable here as well
              </h3> */}
          </div>
        </div>
      </aside>
    </div>
  );
};

export default LoginView;
