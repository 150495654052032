import React, { useMemo, Suspense, memo, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';
import { useTabContext } from 'GlobalState/Context/TabContext';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Table
import { useTable, useSortBy, useFlexLayout } from 'react-table';
import Label from 'components/Label/Label';

// Form
import { useForm } from 'react-hook-form';
import InputField from 'components/Form/InputField';
import SubmitButton from 'components/Form/SubmitButton';
import TaskUrlField from 'components/Form/MyReports/TaskUrlField';
import ComponentSelect from 'components/Form/Selects/ComponentSelect';
import ProjectSelect from 'components/Form/Selects/ProjectsSelect';
import SkeletonLoadingField from 'components/LoadingSkeletons/SkeletonLoadingField/SkeletonLoadingField';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import EditReportForm from 'components/Form/MyReports/EditReportForm/EditReportForm';
import DeletePrompt from 'components/Form/DeletePrompt/DeletePrompt';

import EditIcon from 'components/Icons/Edit';
import DeleteIcon from 'components/Icons/Delete';
import CopySolid from 'components/Icons/CopySolid';

// Utils
import parseYearMonthDate, {
  shouldShowMyReportForm,
} from 'utils/dateFunctions';
import { transformSecondsToHours } from 'utils/calculateTime';

// styles
import cx from 'classnames';
import tableStyles from '../Tables.module.scss';
import tableStylesReports from '../TablesReports.module.scss';
import tableMyReportsStyles from './TableMyReports.module.scss';
import styles from './TableMyReports.module.scss';

import { useQuery, useMutation, useQueryClient } from 'react-query';
import instance from 'axiosInstance';

const createEntry = async (variables) => {
  const params = new URLSearchParams();
  params.append('project_id', variables.project.value);
  params.append('is_billable', true);
  params.append('component_id', variables.component.value);
  params.append(
    'task_title',
    variables.taskTitle === 'Open Link' ? '' : variables.taskTitle
  );
  params.append('task_link', variables.taskUrl);
  params.append('task_description', variables.description);
  params.append('hours_spent', variables.hoursSpent);
  params.append('created_on', parseYearMonthDate(variables.activeDay));

  const response = await instance.post('private/employees-reports', params);
  return response.data;
};

/**
 * The one, the only
 *
 * This is the home page table and it's the most used component of the whole app
 * @see https://react-table.js.org/ - 90% of the stuff is there.
 * @return {JSX} My reports table
 */

// Todo: - Modal on top. Data changes on edit click
const TableMyReportsUI = memo(({ data }) => {
  const [cookies] = useCookies(['inputs-to-keep']);

  const queryClient = useQueryClient();
  const {
    globalState: { stopwatchValue },
    globalDispatch,
  } = useGlobalContext();

  const {
    state: { activeDay },
  } = useTabContext();

  const {
    handleSubmit,
    control,
    resetField,
    reset,
    register,
    setValue,
    setFocus,
    clearErrors,
    setError,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      project: '',
      component: '',
      taskUrl: '',
      taskTitle: '',
      description: '',
      hoursSpent: '',
    },
  });

  const project = watch('project');

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'project' && value) {
        setValue('component', null);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (stopwatchValue !== null) {
      setValue('hoursSpent', transformSecondsToHours(stopwatchValue));
      setFocus('project');
    }
  }, [stopwatchValue]);

  const handleEditPopup = (row) => {
    globalDispatch({
      type: 'setModalContent',
      payload: (
        <ErrorBoundary>
          <Suspense fallback={<div>Loading</div>}>
            <EditReportForm row={row} type="edit" activeDay={activeDay} />
          </Suspense>
        </ErrorBoundary>
      ),
    });
  };

  const handleDeletePopup = (row) => {
    const parseActiveDay = parseYearMonthDate(activeDay);

    globalDispatch({
      type: 'setModalContent',
      payload: (
        <DeletePrompt
          heading={row.description}
          additionalInformation={`Are you sure you want to delete this report entry?`}
          id={row.id}
          endpoint={`/private/employees-reports/${row.id}`}
          queriesToInvalidate={[['my-reports', parseActiveDay], 'week-hours']}
        />
      ),
    });
  };

  const handleCopy = (row) => {
    const { project, component, description, hoursSpent, task } = row;

    setValue('project', { label: project.name, value: project.id });
    setValue('description', description);
    setValue('hoursSpent', Number(hoursSpent));
    setValue('taskUrl', task.link || '');
    setValue('taskTitle', task.title || '');

    setTimeout(() => {
      setValue('component', { label: component.name, value: component.id });
    }, 50);
  };

  const { mutate } = useMutation(createEntry, {
    mutationKey: `create-entry`,
    onMutate: async (data) => {
      const parseActiveDay = parseYearMonthDate(activeDay);

      const newEntry = {
        component: { name: data.component.label, id: data.component.value },
        description: data.description,
        hoursSpent: data.hoursSpent,
        project: { name: data.project.label, id: data.project.value },
        task: { title: data.taskTitle, link: data.taskUrl },
      };

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['my-reports', parseActiveDay]);

      // Snapshot the previous value
      const previousReports = queryClient.getQueryData([
        'my-reports',
        parseActiveDay,
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(['my-reports', parseActiveDay], (old) => [
        ...old,
        newEntry,
      ]);

      // Return a context object with the snapshotted value
      return { previousReports };
    },
    onSuccess: () => {
      globalDispatch({
        type: 'pushNotification',
        payload: {
          type: 'success',
          text: 'Report entry added!',
        },
      });
    },
    onError: (err, newTodo, context) => {
      const parseActiveDay = parseYearMonthDate(activeDay);

      queryClient.setQueryData(
        ['my-reports', parseActiveDay],
        context.previousReports
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries('week-hours');
      queryClient.invalidateQueries('my-reports');
    },
  });

  const onSubmit = (newData) => {
    newData.activeDay = activeDay;
    mutate(newData);
    const inputsToKeep = cookies['inputs-to-keep'] || [];

    const inputsToKeepValues = inputsToKeep.map((input) => input.value);

    const inputs = [
      'project',
      'component',
      'taskUrl',
      'description',
      'hoursSpent',
    ];

    if (inputsToKeepValues.length === 0) {
      reset();
    } else {
      for (const input of inputs) {
        if (!inputsToKeepValues.includes(input)) {
          resetField(input);
        }
      }
    }
  };

  // accessor is the "key" in the data
  const columns = useMemo(() => {
    const columns = [
      {
        Header: 'Project Name',
        accessor: 'project',
        maxWidth: 200,
        minWidth: 160,
        width: 160,
        sortType: (a, b) =>
          a.original.project.name < b.original.project.name ? -1 : 1,
      },
      {
        Header: 'Component',
        accessor: 'component',
        maxWidth: 200,
        minWidth: 160,
        width: 160,
        sortType: (a, b) =>
          a.original.component.name < b.original.component.name ? -1 : 1,
      },
      {
        Header: 'Task',
        accessor: 'task',
        maxWidth: 300,
        minWidth: 200,
        width: 200,
      },
      {
        Header: 'Description',
        accessor: 'description',
        maxWidth: 500,
        minWidth: 320,
        width: 320,
      },
      {
        Header: 'Time',
        accessor: 'hoursSpent',
        maxWidth: 85,
        minWidth: 120,
        width: 120,
        sortType: (a, b) =>
          a.original.hoursSpent < b.original.hoursSpent ? -1 : 1,
      },
    ];
    if (shouldShowMyReportForm(activeDay)) {
      columns.push({
        accessor: 'actions',
        Header: 'Actions',
        disableSortBy: true,
        maxWidth: 90,
        minWidth: 125,
        width: 125,
        Footer: <SubmitButton text="Save" />,
      });
    }

    return columns;
  }, [activeDay]);

  // Temporary method to manage classes.
  const tableClasses = cx(
    tableStyles.table,
    tableMyReportsStyles.table,
    'custom-scroll'
  );

  /**
   * The useTable hook is what gives us the instances to render the table.
   * @see https://react-table.js.org/api/useTable
   */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
    },
    useSortBy,
    useFlexLayout
  );

  /**
   * In the header, we are showing the label + can add sorting options
   */

  const tableHeader = useMemo(
    () =>
      headerGroups.map((headerGroup, key) => (
        <div
          key={key}
          className={tableStyles.tr}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map((column, key) => (
            <div
              key={key}
              className={`${tableStyles.th} ${styles.th}`}
              {...column.getHeaderProps(column.getSortByToggleProps())}
            >
              <Label fontStyle="bold">{column.render('Header')}</Label>
            </div>
          ))}
        </div>
      )),
    [headerGroups]
  );

  /**
   * The tableBody is the date we get from the API. Currently static :)
   */
  const tableBody = useMemo(
    () =>
      rows.map((row) => {
        // (Required) Any row that you intend to render in your table needs to be passed to
        // this function before every render.
        prepareRow(row);

        return (
          <div
            key={row.id}
            className={`${tableStyles.tr} ${styles.tr}`}
            {...row.getRowProps()}
          >
            {row.cells.map((cell) => {
              const cellClass = cell.column.id;

              // Standard output
              let output;

              if (cell.value !== '' && cellClass === 'taskUrl') {
                output = (
                  <div
                    className={tableMyReportsStyles.taskUrl}
                    dangerouslySetInnerHTML={{ __html: cell.value }}
                  />
                );
              }

              if (cell.value !== '' && cellClass === 'project') {
                // In the future, set color for each project.
                output = (
                  <Link to={`/project/${cell.value.slug}`}>
                    {cell.value.name}
                  </Link>
                );
              }

              if (cell.value !== '' && cellClass === 'description') {
                output = <Label>{cell.value}</Label>;
              }

              if (cellClass === 'component') {
                output = (
                  <Link
                    to={`/project/${row.original.project.slug}/component/${cell.value.id}`}
                  >
                    {cell.value.name}
                  </Link>
                );
              }

              if (cellClass === 'task') {
                if (cell.value.link && cell.value.link !== 'undefined') {
                  output = (
                    <a target="_blank" href={cell.value.link} rel="noreferrer">
                      {cell.value.title || 'Open Link'}
                    </a>
                  );
                } else {
                  output = '';
                }
              }

              if (cell.value !== '' && cellClass === 'hoursSpent') {
                output = <Label>{cell.value}h</Label>;
              }

              if (
                cellClass === 'actions' &&
                shouldShowMyReportForm(activeDay)
              ) {
                output = (
                  <div className={tableStyles['actions-wrapper']}>
                    <EditIcon
                      width="20px"
                      height="20px"
                      onClick={() => handleEditPopup(row.original)}
                      color="var(--theme-color-primary)"
                    />
                    <CopySolid
                      width="20px"
                      height="20px"
                      onClick={() => handleCopy(row.original)}
                      color="var(--theme-color-primary)"
                    />
                    <DeleteIcon
                      width="20px"
                      height="20px"
                      onClick={() => handleDeletePopup(row.original)}
                      color="var(--theme-color-danger)"
                    />
                  </div>
                );
              }

              return (
                <div
                  key={cellClass}
                  className={cx(tableStyles.td, tableMyReportsStyles.td, {
                    [tableStylesReports[cellClass]]:
                      !!tableStylesReports[cellClass],
                  })}
                  {...cell.getCellProps()}
                >
                  {output}
                </div>
              );
            })}
          </div>
        );
      }),
    [rows]
  );

  const tableFooter = footerGroups.map((footerGroup, key) => (
    <div
      key={key}
      className={`${tableStyles.tr}  ${tableMyReportsStyles.tr}`}
      {...footerGroup.getFooterGroupProps()}
    >
      {footerGroup.headers.map((column) => {
        let output = column.render('Footer');

        if (column.id === 'project') {
          output = (
            <ErrorBoundary>
              <Suspense fallback={<SkeletonLoadingField />}>
                <ProjectSelect control={control} />
              </Suspense>
            </ErrorBoundary>
          );
        }

        if (column.id === 'component') {
          output = (
            <ErrorBoundary>
              <Suspense fallback={<SkeletonLoadingField />}>
                <ComponentSelect control={control} projectId={project.value} />
              </Suspense>
            </ErrorBoundary>
          );
        }

        if (column.id === 'task') {
          output = (
            <ErrorBoundary>
              <TaskUrlField
                setValue={setValue}
                getValues={getValues}
                setError={setError}
                control={control}
                clearErrors={clearErrors}
              />
            </ErrorBoundary>
          );
        }

        if (column.id === 'description') {
          output = (
            <InputField
              placeholder="Description"
              wrapperClassName={styles['table-input-wrapper']}
              type="text"
              errors={errors.description}
              {...register('description', {
                required:
                  '*Required! The descriptions helps us form the reports',
              })}
            />
          );
        }

        if (column.id === 'hoursSpent') {
          output = (
            <InputField
              placeholder="Time"
              wrapperClassName={styles['table-input-wrapper']}
              type="number"
              step="any"
              errors={errors.hoursSpent}
              {...register('hoursSpent', {
                required: '*Time is required',
                min: { value: 0, message: 'Time cannot be negative' },
                validate: {
                  timeFormat: (v) =>
                    (v % 0.25 === 0 && v !== 0) ||
                    'You must pick a valid time. Ex: 1, 1.25, 2.75',
                },
              })}
            />
          );
        }
        return (
          <div
            key={column.id}
            className={tableStyles.td}
            {...column.getFooterProps()}
          >
            {output}
          </div>
        );
      })}
    </div>
  ));

  return (
    <div {...getTableProps} className={tableClasses}>
      <div className={cx(tableStyles.tfoot, tableMyReportsStyles.tfoot)}>
        <form
          className={styles['task-report-form']}
          onSubmit={handleSubmit(onSubmit)}
        >
          {shouldShowMyReportForm(activeDay) && tableFooter}
        </form>
      </div>
      <div className="thead">{tableHeader}</div>
      <div
        className={`${tableStyles.tbody} ${styles.tbody}`}
        {...getTableBodyProps()}
      >
        {tableBody}
      </div>
    </div>
  );
});

TableMyReportsUI.displayName = 'TableMyReports';

TableMyReportsUI.propTypes = {
  data: PropTypes.array,
};

const getMyReports = async ({ queryKey }) => {
  const response = await instance.get(
    '/private/employees-reports-day?date=' + queryKey[1]
  );
  return response.data;
};
const TableMyReports = () => {
  const {
    state: { activeDay },
  } = useTabContext();

  const parseActiveDay = parseYearMonthDate(activeDay);

  const { data } = useQuery(['my-reports', parseActiveDay], getMyReports);

  return <TableMyReportsUI data={data} />;
};

export default TableMyReports;
