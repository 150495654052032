import React from 'react';
import SkeletonCustomField from '../SkeletonCustomField/SkeletonCustomField';

// Styles
import cx from 'classnames';
import workedDaysStyles from '../../WorkedHoursPerDay/WorkedHoursPerDay.module.scss';
import SkeletonWorkedDaysBox from './SkeletonWorkedDaysBox';

const SkeletonWorkedHoursPerDay = () => {
  const calendarDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const workedDays = calendarDays.map((day) => {
    return <SkeletonWorkedDaysBox key={day} day={day} />;
  });
  return (
    <div className={cx(workedDaysStyles['week-calendar'], 'flex-row')}>
      {workedDays}
      <div
        className={cx(
          workedDaysStyles.box,
          workedDaysStyles['box-total'],
          'flex-row',
          'centered-horizontally',
          'centered-vertically'
        )}
      >
        <span className={workedDaysStyles['box-title']}>Total</span>
        <SkeletonCustomField
          width="40px"
          height="18px"
          backgroundColor="var(--field-skeleton-background-color)"
          backgroundShineColor="var(--field-skeleton-shine-background-color)"
        />
      </div>
    </div>
  );
};

export default SkeletonWorkedHoursPerDay;
