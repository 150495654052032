import React, { Suspense } from 'react';
import { useTabContext } from 'GlobalState/Context/TabContext';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Table
import TableEmployeeOverview from 'components/Tables/TableEmplyeeOverview/TableEmployeeOverview';
import { OverviewBillingReportFilter } from 'components/Form/Selects/BillingReportFilter';
import SearchFilter from 'components/Tables/SearchField';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import SkeletonTable from 'components/LoadingSkeletons/SkeletonTable/SkeletonTable';

// form
import ExportReportsForm from 'components/Form/Manage/ExportReportsForm/ExportReportsForm';

// Ui components
import DataOverview from 'components/UserOverview/DataOverview/DataOverview';
import AppTabs from 'components/AppTabs/AppTabs';
import Card from 'components/Card/Card';
import FlexContainer from 'components/FlexContainer/FlexContainer';

import cx from 'classnames';
import styles from './MembersOverview.module.scss';
import Button from 'components/Button/Button';

const dataReports = [];

const pageTabs = [
  {
    to: '/members-overview',
    text: 'Members Overview',
  },
];

const MembersOverview = () => {
  const {
    state: { billingPeriod },
  } = useTabContext();

  const { globalDispatch } = useGlobalContext();

  const reports = dataReports.map((row) => {
    return <DataOverview key={row.id} title={row.title} accent={row.accent} />;
  });

  const handleExportPopup = () => {
    globalDispatch({
      type: 'setModalContent',
      payload: (
        <Suspense fallback={<div>Loading</div>}>
          <ExportReportsForm />
        </Suspense>
      ),
    });
  };

  return (
    <>
      <AppTabs links={pageTabs} />
      <div className="app-body-content">
        <Card hasPadding>
          <div className={cx(styles['reports-wrapper'], 'flex-row', 'wrap')}>
            {reports}
          </div>
        </Card>

        <Card
          title="Employees activity"
          titleAccent={billingPeriod.label}
          leftAdditional={
            <Button
              clicked={handleExportPopup}
              text="Export"
              type="button-primary"
              additionalInlineStyles={{
                minWidth: '6.25em',
                paddingTop: '0.375em',
                paddingBottom: '0.375em',
              }}
            />
          }
          headerAdditional={
            <FlexContainer justify="justify-between" wrap="wrap">
              <div className="width-medium">
                <OverviewBillingReportFilter />
              </div>
              <div className="width-medium">
                <SearchFilter />
              </div>
            </FlexContainer>
          }
        >
          <ErrorBoundary>
            <Suspense fallback={<SkeletonTable countTableBodyRows={50} />}>
              <TableEmployeeOverview />
            </Suspense>
          </ErrorBoundary>
        </Card>
      </div>
    </>
  );
};

export default MembersOverview;
