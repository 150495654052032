import React, { Suspense, useState } from 'react';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Form
import PositionsForm from 'components/Form/Manage/ManagePositionsForm/PositionsForm';

// Table
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import TablePositions from 'components/Tables/Mangage/TablePositions/TablePositions';
import SearchFilter from 'components/Tables/SearchField';
import SkeletonTable from 'components/LoadingSkeletons/SkeletonTable/SkeletonTable';
import ExternalStateCheckbox from 'components/Checkbox/ExternalStateCheckbox';

// UI components
import FlexContainer from 'components/FlexContainer/FlexContainer';
import Card from 'components/Card/Card';
import Button from 'components/Button/Button';

const PositionsSubtab = () => {
  const { globalDispatch } = useGlobalContext();
  const [shouldShowInactive, setShouldShowInactive] = useState(false);

  const handleAddPopup = () => {
    globalDispatch({
      type: 'setModalContent',
      payload: (
        <ErrorBoundary>
          <Suspense fallback={<div>Loading</div>}>
            <PositionsForm type="create" heading="Add Positions" />
          </Suspense>
        </ErrorBoundary>
      ),
    });
  };
  return (
    <>
      <Card
        title="Positions"
        leftAdditional={
          <label>
            Show Inactive
            <ExternalStateCheckbox
              setIsChecked={setShouldShowInactive}
              isChecked={shouldShowInactive}
            />
          </label>
        }
        headerAdditional={
          <FlexContainer spaceX="children-x-10px">
            <SearchFilter />
            <Button
              clicked={handleAddPopup}
              text="Add"
              type="button-primary"
              additionalInlineStyles={{
                minWidth: '6.25em',
                paddingTop: '0.375em',
                paddingBottom: '0.375em',
              }}
            />
          </FlexContainer>
        }
      >
        <ErrorBoundary>
          <Suspense fallback={<SkeletonTable countTableBodyRows={50} />}>
            <TablePositions shouldShowInactive={shouldShowInactive} />
          </Suspense>
        </ErrorBoundary>
      </Card>
    </>
  );
};

export default PositionsSubtab;
