import React from 'react';
import { useParams } from 'react-router-dom';

// UX Components
import Thumbnail from 'components/Thumbnail/Thumbnail';

// Styles
import cx from 'classnames';
import styles from './UserInformationOverview.module.scss';

import { useQuery } from 'react-query';
import instance from 'axiosInstance';

// utils
import { monthDiff } from 'utils/dateFunctions';

const getUserInformation = async ({ queryKey }) => {
  const response = await instance.get(
    '/private/employee-information?employee_slug=' + queryKey[1]
  );
  return response.data;
};

const UserInformationPublic = () => {
  const badges = [];
  const { slug } = useParams();

  const { data } = useQuery(['user-information', slug], getUserInformation);

  const monthInCompany = monthDiff(new Date(data.dateOfStart), new Date());
  const yearsInCompany = monthInCompany / 12;

  for (let i = 1; i <= yearsInCompany; i++) {
    badges.push(
      <Thumbnail
        key={i}
        src={`/badges/${i}-years-badge.png`}
        customWidth="3.875em"
        customHeight="3.875em"
      />
    );
  }

  return (
    <>
      <div className={styles['header-wrapper']}>
        <div className={styles['header-background-wrapper']}>
          <Thumbnail
            src="https://i.ibb.co/fdRgq7k/user-background.png"
            customHeight="14.875em"
            customWidth="100%"
            borderRadius="var(--app-general-border-radius-larger) var(--app-general-border-radius-larger) 0 0"
          />
        </div>
        <div className={cx(styles['header-wrapper-inner'], 'flex-row')}>
          <div className={styles['primary-container']}>
            <div className={styles['user-thumbnail']}>
              <Thumbnail
                src={data.imageUrl || '/dixy.png'}
                alt="user-thumbnail"
                customHeight="13.5em"
                customWidth="13.5em"
                borderRadius="50%"
                additionalStyles={styles['image-wrapper']}
              />
            </div>
          </div>
          <div
            className={cx(
              styles['secondary-container'],
              'flex-row',
              'separated-content',
              'centered-end'
            )}
          >
            <div className={styles['user-information']}>
              <h1 className={styles['user-name']}>{data.fullName}</h1>
              <p className={styles['user-position']}>{data.title}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={cx(styles['body-content-wrapper'], 'flex-row')}>
        <div className={styles['primary-container']}>
          <div
            className={cx(
              styles['badge-list-wrapper'],
              'flex-row',
              'wrap',
              'centered-horizontally'
            )}
          >
            {badges}
          </div>
          <p className={styles['badges-subheader']}>Collected badges</p>
        </div>

        {data.description && (
          <div className={styles['secondary-container']}>
            <div className={styles['user-description-wrapper']}>
              <p className={styles['user-description']}>{data.description}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserInformationPublic;
