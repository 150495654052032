import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './ProgressBar.module.scss';

const ProgressBar = ({
  wrapperWidth,
  wrapperHeight,
  childHeight,
  childWidth,
}) => {
  return (
    <div
      className={styles['progress-bar']}
      style={{ width: wrapperWidth, height: wrapperHeight }}
    >
      <span
        className={styles['progress-bar-inner']}
        style={{ width: childWidth, height: childHeight }}
      ></span>
    </div>
  );
};

ProgressBar.propTypes = {
  wrapperHeight: PropTypes.string,
  wrapperWidth: PropTypes.string,
  childWidth: PropTypes.string,
  childHeight: PropTypes.string,
};

export default ProgressBar;
