import Card from 'components/Card/Card';
import Thumbnail from 'components/Thumbnail/Thumbnail';
import React from 'react';

// Images
import loginImage from 'components/Images/HowToUse/login.png';
import ProfileSettingsImage from 'components/Images/HowToUse/profile-settings.png';

// Styles
import cx from 'classnames';
import styles from './HowToUse.module.scss';

const HowToUse = () => {
  return (
    <div className="app-body-content">
      <Card hasPadding additionalStyles={styles.card}>
        <div
          className={cx(
            'flex-row',
            'separated-content',
            'wrap',
            styles.heading
          )}
        >
          <div className={styles['primary-container']}>
            <h1 className={styles['heading-title']}>
              DX Time Tracker User Guide
            </h1>
            <p className={styles['heading-subtitle']}>
              Welcome to the awesome DX Time Tracker tool! Remember to fill in
              your reports on a daily basis - it’s easier!
            </p>
          </div>
          <div className={styles['secondary-container']}>
            <Thumbnail
              src={'/dixy.png'}
              customWidth="13.625em"
              customHeight="13.75em"
              additionalStyles={`${styles.thumbnail} ${styles['custom-size']}`}
            />
          </div>
        </div>
        <div className={styles.separator}></div>
        <p>
          The Time Tracker is here is to keep track and manage our time in an
          effortless way. It will generate an up-to-date reports. It will
          compare the hours we have estimated for projects against the actual
          time spent. Here is a short list of the top features it provides:
        </p>
        <ol className={styles['points-list']}>
          <li>Easily track daily, weekly or monthly time logs</li>
          <li>Share and/or send reports for approval</li>
          <li>Validate all work hours and make sure nothing goes overlooked</li>
          <li>Visualize the workflows within each project</li>
          <li>
            Provide an overview of the current state of all projects and outline
            problematic projects
          </li>
        </ol>
        <div className={styles.separator}></div>
        <h2>How to use:</h2>
        <h3 className={styles['title-medium']}>Step 1. Login</h3>
        <p>
          You will receive your account credentials via your DX email. Use them
          to log into the app. It is possible that you will have to reset your
          password, just use your DevriX account for this.
        </p>
        <Thumbnail
          src={loginImage}
          customWidth="48%"
          customHeight="33%"
          additionalStyles={`${styles.thumbnail} ${styles['custom-size']}`}
          borderRadius="var(--app-general-border-radius-larger)"
        />
        <h3 className={styles['title-medium']}>Step 2. User’s panel:</h3>
        <h4 className={styles['title-small']}>
          A) If you have User privileges
        </h4>
        <p style={{ color: 'red' }}>TODO we have to add picture here</p>
        <p>
          <strong>Top level menus in the sidebar:</strong>
        </p>
        <ol className={styles['points-list']}>
          <li>
            <strong>Home button</strong> - The page where you enter your reports
          </li>
          <li>
            <strong>Projects button</strong> - Overview view of your time by
            projects
          </li>
          <li>
            <strong>How to use</strong> - A detailed guide on how to use the app
            projects
          </li>
        </ol>
        <h4 className={styles['title-small']}>
          B) If you have Admin privileges
        </h4>
        <p style={{ color: 'red' }}>TODO we have to add picture here</p>
        <p>
          <strong>Top level menus in the sidebar:</strong>
        </p>
        <ol className={styles['points-list']}>
          <li>
            <strong>Home button</strong> - The page where you enter your reports
          </li>
          <li>
            <strong>Projects button</strong> - Overview view of your time by
            projects
          </li>
          <li>
            <strong>Manage button</strong> - Overview view of Employees and
            positions, Projects and Clients projects
          </li>
          <ol className={styles['points-list']}>
            <li>
              <strong>Employees and positions button</strong> - opens the
              section where you can add and edit employees’ and positions’
              information
            </li>
            <li>
              <strong>Projects button</strong> - opens the section where you can
              add and edit projects’ information
            </li>
            <li>
              <strong>Clients button</strong> - opens the section where you can
              add and edit clients’ information
            </li>
          </ol>
          <li>
            <strong>Members Overview button</strong> - summarizes the hours per
            people.You can check the summary for the whole month and make
            references for pastmonths.
          </li>
          <li>
            <strong>How to use</strong> - A detailed guide on how to use the app
            projects
          </li>
        </ol>
        <h3 className={styles['title-medium']}>
          Step 3. Fill in your daily reports
        </h3>
        <p>
          Login and click the “Home” button on the left side menu - you should
          open Today section.
        </p>
        <p style={{ color: 'red' }}>TODO we have to add picture here</p>
        <h4 className={styles['title-small']}>Submitting a task:</h4>
        <p style={{ color: 'red' }}>TODO we have to add picture here</p>
        <ol className={styles['points-list']}>
          <li>
            Select the Project from the “Project” drop-down list. If your
            Project for some reason is missing, please contact Yulia Oleynik
          </li>
          <li>
            If you have an Asana task - post the link to the task in the second
            field “Task Link”
          </li>
          <li>
            Choose the “Component” of the project you’ve worked on. If your
            “Component” is missing you can easily add it by just typing and
            pressing Submit, which will make it visible for everyone else. For
            clients like Obsev or Popular Everything having other sites such as
            WhatsThat or HypeGalore you should use this field to indicate
            whether you have worked on that particular sub-project. Example: You
            have worked on a task for Hypegalore, add/create the component,
            followed by adding the task link and filling in the description
            accordingly.
          </li>
          <li>
            Description - enter a brief explanation of what have you done,
            especially if task was non-billable, please explain why so NB: Keep
            in mind that adding a link is not mandatory, you could easily write
            the name of the task in this field should you need such
          </li>
          <li>
            Time to complete - enter the time you spent on this task in
            accordance with the accepted DX time segments (0.25/0.5/0.75/1.0,
            etc. where 0.25 means 15mins, 0.5. means 30mins, etc.)
          </li>
          <li>When you are ready with the task explanation, hit “Submit”</li>
        </ol>
        <p>Here is a short video on how to do this</p>
        <p style={{ color: 'red' }}>TODO we have to add video here</p>
        <h4 className={styles['title-small']}>Edit Entry</h4>
        <p>
          You can edit your task’s entry - Click on the “pencil” button on the
          right side of your task entry to edit the submitted information.
        </p>
        <p style={{ color: 'red' }}>TODO we have to add picture here</p>
        <h4 className={styles['title-small']}>Delete an Entry</h4>
        <p>
          You can delete an entry by clicking on the third icon after that a
          confirmation will be needed, so you have to press Yes
        </p>
        <p style={{ color: 'red' }}>TODO we have to add picture here</p>
        <div className={styles.separator}></div>
        <h3 className={styles['title-medium']}>Additional functionalities</h3>
        <Thumbnail
          src={ProfileSettingsImage}
          customWidth="100%"
          customHeight="53%"
          additionalStyles={styles.thumbnail}
          borderRadius="var(--app-general-border-radius-larger)"
        />
        <p>
          <strong>Your Preferences:</strong>
        </p>
        <ol className={styles['points-list']}>
          <li>Select Light or Dark mode</li>
          <li>Select “Clear inputs after submitting a record”</li>
        </ol>
        <p>
          <strong>Set Time Tracker theme:</strong>
        </p>
        <ol className={styles['points-list']}>
          <li>Select one of six colorful themes</li>
        </ol>
        <p>
          <strong>Quick links:</strong>
        </p>
        <ol className={styles['points-list']}>
          <li>
            <strong>My Account</strong> - quick access to your daily report
          </li>
          <li>
            <strong>My Statistics</strong> - quick access to your statistics
          </li>
          <li>
            <strong>Logout</strong> - access to logout
          </li>
        </ol>
        <div className={styles.separator}></div>
        <h2>TRACK THE TIME!</h2>
      </Card>
    </div>
  );
};

export default HowToUse;
