import React from 'react';
import PropTypes from 'prop-types';

import Select from './Select';
import { useController } from 'react-hook-form';

const BilledAsSelect = ({ control }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'billedAs',
    control,
    rules: {
      required: 'Billed as is requires',
    },
  });

  const options = [
    { label: 'Front-End', value: 'fe' },
    { label: 'Back-End', value: 'be' },
    { label: 'Project Management', value: 'pm' },
    { label: 'Quality Assurance', value: 'qa' },
  ];

  return (
    <Select
      placeholder="This position will be billed as fe, be, qa or pm time?"
      error={error}
      options={options}
      {...field}
    />
  );
};

BilledAsSelect.propTypes = {
  control: PropTypes.object,
};

export default BilledAsSelect;
