import React, { useState, useEffect } from 'react';

// Style and icons
import styling from './TimeTracker.module.scss';
import CheckSolid from '../Icons/Check-solid';
import PauseSolid from '../Icons/Pause-solid';
import PlaySolid from '../Icons/Play-solid';
import ResetSolid from '../Icons/Reset-solid';

// Util function to change the format from seconds to {1.75h}
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

/**
 * It has 4
 * simple actions - start, stop, reset and record.
 *
 * Record will fill in the time directly in the time tracker report table.
 * @return {JSX}
 */
const TimeTracker = () => {
  const { globalDispatch } = useGlobalContext();
  let storageActive = localStorage.getItem('trackerActive');
  const storageElapsed = parseInt(localStorage.getItem('trackerElapsed') || 0);

  // Kill me...
  storageActive = storageActive === 'false' ? false : true;
  const [timerPlaying, setTimerPlaying] = useState(storageActive);
  const [timerSeconds, setTimerSeconds] = useState(storageElapsed);

  /**
   * Handle t--theme-color-primaryhe start and stop of the timer.
   */
  function handleTimerToggle() {
    if (!timerPlaying) {
      // We save the current time when the start button is pressed in the local storage
      localStorage.setItem('timeStarted', new Date().getTime() / 1000);
    } else {
      // We save the passed time in the local storage when we click the pause button
      localStorage.setItem('pausedTime', Number(timerSeconds));
    }
    // On/Off the stopwatch
    setTimerPlaying(!timerPlaying);
    localStorage.setItem('trackerActive', !timerPlaying);
  }

  /**
   * Handle clicking the reset time button
   */
  function handleTimerReset() {
    // Clear the application state
    setTimerPlaying(false);
    setTimerSeconds(0);

    // Clear the local storage data
    localStorage.setItem('trackerActive', false);
    localStorage.setItem('trackerElapsed', 0);
    localStorage.setItem('pausedTime', 0);
  }

  /**
   * Handle the click of the save time function
   * Dispatch the saveTime action and save in the global state
   */
  function handleTimeSave() {
    globalDispatch({ type: 'setStopwatchValue', payload: timerSeconds });
  }

  useEffect(() => {
    // We should be able to stop the timer, so it's a variable.
    // We keep the start timeNow in local storage for when user refreshes the page
    // or encounters an error/problem that causes the brower to close.
    let timeout;

    if (timerPlaying) {
      timeout = setTimeout(() => {
        // We get the current time in seconds
        const timeNow = new Date().getTime() / 1000;

        // We get the time when the start button was clicked from the local storage in seconds
        const timeStarted = localStorage.getItem('timeStarted');

        // We get the passed time if the user ever paused the stopwatch
        const pausedTime = localStorage.getItem('pausedTime');

        // Update the timer.
        const CalculatedTimerSeconds =
          Math.floor(timeNow) - Math.floor(timeStarted) + Number(pausedTime);
        setTimerSeconds(CalculatedTimerSeconds);
        localStorage.setItem('trackerElapsed', timerSeconds);
      }, 1000);
    } else {
      clearTimeout(timeout);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [timerPlaying, timerSeconds]);

  return (
    <div
      className={`${
        timerPlaying ? styling['is-running'] : styling['is-paused']
      } ${styling['time-tracker']}`}
    >
      <span className={styling['elapsed']}>
        {new Date(timerSeconds * 1000).toISOString().substr(11, 8)}
      </span>

      {/* The toggle button starts/stops the timer. */}
      <span className={styling['toggle']} onClick={handleTimerToggle}>
        {timerPlaying ? (
          <PauseSolid
            color={`var(--theme-color-primary)`}
            width="20px"
            height="20px"
          />
        ) : (
          <PlaySolid
            color={`var(--theme-color-accent)`}
            width="20px"
            height="20px"
          />
        )}
      </span>

      <span className={styling['reset']} onClick={handleTimerReset}>
        <ResetSolid
          color="var(--theme-color-danger)"
          width="20px"
          height="20px"
        />
      </span>

      {/* The report button will open the table to store the time recorded. */}
      <span className={styling['report']} onClick={handleTimeSave}>
        <CheckSolid
          color={`var(--theme-color-accent)`}
          width="20px"
          height="20px"
        />
      </span>
    </div>
  );
};

export default TimeTracker;
