import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import styles from './SkeletonCustomField.module.scss';

const SkeletonCustomField = ({
  width,
  height,
  backgroundColor,
  backgroundShineColor,
  borderRadius,
  additionalStyles,
}) => {
  const ref = useRef();

  useEffect(() => {
    ref.current.style.setProperty('--field-width', width);
    ref.current.style.setProperty('--field-height', height);
    ref.current.style.setProperty('--field-border-radius', borderRadius);
    ref.current.style.setProperty('--field-background-color', backgroundColor);
    ref.current.style.setProperty(
      '--field-background-shine-color',
      backgroundShineColor
    );
  }, []);

  return <div className={cx(styles.field, additionalStyles)} ref={ref}></div>;
};

SkeletonCustomField.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  additionalStyles: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundShineColor: PropTypes.string,
  borderRadius: PropTypes.string,
};
export default SkeletonCustomField;
