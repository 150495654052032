import React, { Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useTabContext } from 'GlobalState/Context/TabContext';

// Table
import SkeletonTable from 'components/LoadingSkeletons/SkeletonTable/SkeletonTable';
import TableSingleComponent from 'components/Tables/TableSingleProject/TableSingleComponent';

// UI
import Card from 'components/Card/Card';

import sharedStyles from 'styles/shared.module.scss';

import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

const SingleComponentTab = () => {
  const [searchParams] = useSearchParams();
  const name = searchParams.get('name');

  const {
    state: { isTableSumMode },
    dispatch,
  } = useTabContext();

  return (
    <>
      <Card
        title={`${name ? name : ''} Component Overview`}
        headerAdditional={
          <span
            className={sharedStyles['action']}
            onClick={() => {
              dispatch({ type: 'setIsTableExpanded', payload: false });
              dispatch({ type: 'toggleIsTableSumMode' });
            }}
          >
            <span>{isTableSumMode ? 'Expand mode' : 'Component Sum Mode'}</span>
          </span>
        }
      >
        <ErrorBoundary>
          <Suspense fallback={<SkeletonTable countTableBodyRows={20} />}>
            <TableSingleComponent />
          </Suspense>
        </ErrorBoundary>
      </Card>
    </>
  );
};

export default SingleComponentTab;
