import React from 'react';
import PropTypes from 'prop-types';

// Styles
import style from './SkeletonField.module.scss';

const SkeletonField = ({ additionalStyles }) => {
  return (
    <div className={`${style['skeleton-field']} ${additionalStyles}`}></div>
  );
};

SkeletonField.propTypes = {
  additionalStyles: PropTypes.string,
};

export default SkeletonField;
