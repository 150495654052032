import React, { useMemo } from 'react';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Table
import { useTable, useSortBy, useFlexLayout, useRowSelect } from 'react-table';
import Label from 'components/Label/Label';
import CheckSolid from 'components/Icons/Check-solid';

// Styles
import cx from 'classnames';
import tableStyles from '../Tables.module.scss';
import projectTableStyles from './TableSingleProject.module.scss';

const columnsWithHoursArray = ['timeSpent', 'normalizedTimeSpent'];

const TableSubRow = ({ data }) => {
  /**
   * The useTable hook is what gives us the instances to render the table.
   * @see https://react-table.js.org/api/useTable
   */

  const {
    globalState: { isAdmin },
  } = useGlobalContext();

  const columns = useMemo(() => {
    const columns = [
      {
        accessor: 'toggleRowSelected',
        maxWidth: 60,
        minWidth: 60,
        width: 60,
        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: 'employee',
        minWidth: 185,
        width: 185,
        sortType: (a, b) =>
          a.original.employee.name < b.original.employee.name ? -1 : 1,
      },
      {
        Header: 'Task URL',
        accessor: 'task',
        minWidth: 160,
        width: 160,
      },
      {
        Header: 'Description',
        accessor: 'description',
        minWidth: 400,
        width: 400,
      },
      {
        Header: 'Time',
        accessor: 'timeSpent',
        minWidth: 118,
        width: 118,
        sortType: (a, b) =>
          a.original.timeSpent < b.original.timeSpent ? -1 : 1,
      },
    ];

    if (isAdmin) {
      columns.push({
        Header: 'Normalized Time',
        accessor: 'normalizedTimeSpent',
        minWidth: 118,
        width: 118,
        sortType: (a, b) =>
          a.original.normalizedTimeSpent < b.original.normalizedTimeSpent
            ? -1
            : 1,
      });
    }
    return columns;
  }, [isAdmin]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    isAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
    },
    useSortBy,
    useFlexLayout,
    useRowSelect
  );

  /**
   * In the header, we are showing the label + can add sorting options
   */
  const tableHeader = headerGroups.map((headerGroup) => (
    <div
      key={headerGroup.id}
      className={`${tableStyles.tr} ${projectTableStyles.tr}`}
      {...headerGroup.getHeaderGroupProps()}
    >
      {headerGroup.headers.map((column) => {
        let output = <Label fontStyle="bold">{column.render('Header')}</Label>;

        if (column.id === 'toggleRowSelected') {
          output = (
            <label
              className={cx(
                tableStyles['checkbox-wrapper'],
                tableStyles['w-40px'],
                {
                  [tableStyles['has-color']]: isAllRowsSelected,
                }
              )}
            >
              {isAllRowsSelected && (
                <CheckSolid
                  color={`var(--theme-base-color)`}
                  width="18px"
                  height="14px"
                />
              )}
              <input
                className="is-hidden"
                type="checkbox"
                onChange={() => {
                  toggleAllRowsSelected(!isAllRowsSelected);
                }}
              />
            </label>
          );
        }
        return (
          <div
            key={column.id}
            className={tableStyles.th}
            {...column.getHeaderProps(column.getSortByToggleProps())}
          >
            {output}
          </div>
        );
      })}
    </div>
  ));

  const tableBody = rows.map((row) => {
    // (Required) Any row that you intend to render in your table needs to be passed to
    // this function before every render.
    prepareRow(row);

    return (
      <div
        key={row.id}
        className={`${tableStyles.tr} ${projectTableStyles.tr}`}
        {...row.getRowProps()}
      >
        {row.cells.map((cell) => {
          let output = <Label>{cell.value}</Label>;

          if (cell.column.id === 'toggleRowSelected') {
            output = (
              <label
                className={cx(
                  tableStyles['checkbox-wrapper'],
                  tableStyles['w-40px'],
                  {
                    [tableStyles['has-color']]: row.isSelected,
                  }
                )}
              >
                {row.isSelected && (
                  <CheckSolid
                    color={`var(--theme-base-color)`}
                    width="18px"
                    height="14px"
                  />
                )}
                <input
                  className="is-hidden"
                  type="checkbox"
                  {...row.getToggleRowSelectedProps()}
                />
              </label>
            );
          }

          if (
            cell.column.id === 'timeSpent' ||
            cell.column.id === 'normalizedTimeSpent'
          ) {
            output = <Label>{cell.value + 'h'}</Label>;
          }

          if (cell.column.id === 'task') {
            if (cell.value.link && cell.value.link !== 'undefined') {
              output = (
                <a target="_blank" href={cell.value.link} rel="noreferrer">
                  {cell.value.title || 'Open Link'}
                </a>
              );
            } else {
              output = '';
            }
          }

          if (cell.column.id === 'employee') {
            output = (
              <Link to={`/member/${cell.value.slug}`}>{cell.value.name}</Link>
            );
          }

          return (
            <div
              key={cell.id}
              className={tableStyles.td}
              {...cell.getCellProps()}
            >
              {output}
            </div>
          );
        })}
      </div>
    );
  });

  let selectedRows = rows.filter((row) => {
    prepareRow(row);

    return row.isSelected;
  });

  if (selectedRows.length === 0) {
    selectedRows = rows;
  }

  const sumSelectedRows = {
    timeSpent: selectedRows.reduce(
      (prev, current) => prev + Number(current.original.timeSpent),
      0
    ),

    normalizedTimeSpent: selectedRows.reduce(
      (prev, current) => prev + Number(current.original.normalizedTimeSpent),
      0
    ),
  };

  const tableFooter = footerGroups.map((footerGroup, key) => (
    <div
      key={key}
      className={cx(tableStyles['sticky-row'])}
      {...footerGroup.getFooterGroupProps()}
    >
      {footerGroup.headers.map((column) => {
        let output = '';
        if (column.id === 'employee') {
          output = <Label color={`var(--theme-base-color)`}>Sum:</Label>;
        }

        if (columnsWithHoursArray.includes(column.id)) {
          output = (
            <Label color={`var(--theme-base-color)`}>
              {sumSelectedRows[column.id]}h
            </Label>
          );
        }
        return (
          <div
            key={column.id}
            className={tableStyles.td}
            {...column.getFooterProps()}
          >
            {output}
          </div>
        );
      })}
    </div>
  ));

  return (
    <div
      {...getTableProps}
      className={cx(
        tableStyles.table,
        projectTableStyles['subrow-table'],
        'fade-in'
      )}
    >
      <div className="thead">{tableHeader}</div>
      <div
        className={`${tableStyles.tbody} ${projectTableStyles.tbody}`}
        {...getTableBodyProps()}
      >
        {tableBody}
      </div>
      <div className={tableStyles['tfoot-sticky']}>{tableFooter}</div>
    </div>
  );
};

TableSubRow.propTypes = {
  data: PropTypes.array,
};

export default TableSubRow;
