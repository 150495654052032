import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Form
import { useForm } from 'react-hook-form';
import DateField from 'components/Form/DateField';
import InputField from 'components/Form/InputField';
import SubmitButton from 'components/Form/SubmitButton';
import Button from 'components/Button/Button';

// Styles
import formStyles from '../../Form.module.scss';

// Utils
import parseYearMonthDate from 'utils/dateFunctions';

// Requests
import { useMutation, useQueryClient } from 'react-query';
import instance from 'axiosInstance';

const parseSearchParams = (variables) => {
  const params = new URLSearchParams();
  params.append('client_name', variables.name);
  params.append(
    'contract_date',
    parseYearMonthDate(new Date(variables.contractDate))
  );
  params.append(
    'billing_day',
    parseYearMonthDate(new Date(variables.billingDate))
  );

  return params;
};

const mutationFunction = async (variables) => {
  const params = parseSearchParams(variables);
  let response;
  if (variables.type === 'create') {
    response = await instance.post('/private/admin/clients', params);
  } else if (variables.type === 'edit') {
    response = await instance.put(
      '/private/admin/clients/' + variables.id,
      params
    );
  } else {
    throw new Error('Specify the type of the mutation');
  }

  return response.data;
};

const ClientsForm = ({ heading, row, type }) => {
  const {
    id,
    name = '',
    contractDate = new Date(),
    billingDate = new Date(),
  } = row || '';

  const { globalDispatch } = useGlobalContext();
  const queryClient = useQueryClient();

  const { error, mutate } = useMutation(mutationFunction, {
    mutationKey: `${type}-client`,
    onSuccess: () => {
      queryClient.invalidateQueries('clients');
      globalDispatch({ type: 'setModalContent', payload: null });

      globalDispatch({
        type: 'pushNotification',
        payload: {
          type: 'success',
          text: `Client ${
            type === 'create' ? 'created' : 'edited'
          } successfully`,
        },
      });
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name,
      contractDate,
      billingDate,
    },
  });

  const onSubmit = (data) => {
    data.type = type;
    data.id = id;
    mutate(data);
  };

  const handleRejection = () => {
    globalDispatch({ type: 'setModalContent', payload: null });
  };

  return (
    <div className={formStyles['edit-report-form']}>
      <h2 className={formStyles['form-title']}>{heading}</h2>
      <form
        className={formStyles['form-wrapper']}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputField
          placeholder="Client Name"
          wrapperClassName={`${formStyles['form-input-wrapper']} ${formStyles['width-small']}`}
          errors={errors.name}
          serverErrors={error?.response}
          autofocus
          label="Client Name"
          {...register('name', {
            required: '*Client Name is required',
          })}
        />

        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['width-small']}`}
        >
          <label className={formStyles['form-input-label']}>
            Contract Date
            <DateField control={control} inputName="contractDate" />
          </label>
        </div>

        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['width-small']}`}
        >
          <label className={formStyles['form-input-label']}>
            Billing Date
            <DateField control={control} inputName="billingDate" />
          </label>
        </div>

        <div className={formStyles['form-actions-wrapper']}>
          <Button
            clicked={handleRejection}
            type="button-primary-inline-adaptive"
            text="Cancel"
          />
          <SubmitButton
            type="button-success"
            additionalStyles={formStyles['edit-report-submit']}
            text="Save"
          />
        </div>
      </form>
    </div>
  );
};

ClientsForm.propTypes = {
  type: PropTypes.string.isRequired,
  row: PropTypes.object,
  heading: PropTypes.string,
};

export default memo(ClientsForm);
