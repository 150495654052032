const calendarCalculations = (billingPeriod) => {
  const date = new Date(billingPeriod);
  const month = date.getMonth();
  const year = date.getFullYear();
  const calendarDays = new Date(year, month + 1, 0).getDate();

  const now = new Date();
  let currentDay;

  if (
    now.getMonth() === date.getMonth() &&
    now.getFullYear() === date.getFullYear()
  ) {
    currentDay = now.getDate();
  }

  const weekendDays = [];

  for (let i = 1; i <= calendarDays; i++) {
    const dayOfWeek = new Date(year, month, i).getDay();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      weekendDays.push(i);
    }
  }

  return {
    currentDay,
    calendarDays,
    weekendDays,
  };
};

export default calendarCalculations;
