import React, { Suspense } from 'react';
import { useTabContext } from 'GlobalState/Context/TabContext';

// Table
import TableInvolved from 'components/Tables/TableSingleProject/TableInvolved';
import SkeletonTable from 'components/LoadingSkeletons/SkeletonTable/SkeletonTable';

import Card from 'components/Card/Card';
import FlexContainer from 'components/FlexContainer/FlexContainer';
import SearchFilter from 'components/Tables/SearchField';

import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import BillingReportFilter from 'components/Form/Selects/BillingReportFilter';

// Styles
import tableProjectStyles from './Project.module.scss';
import SkeletonCustomField from 'components/LoadingSkeletons/SkeletonCustomField/SkeletonCustomField';

const InvolvedTab = () => {
  const {
    state: { billingPeriod },
  } = useTabContext();

  return (
    <>
      <Card
        hasPadding
        additionalStyles={tableProjectStyles['table-project-card']}
      >
        <FlexContainer align="align-center" wrap="wrap">
          <span className={tableProjectStyles['card-filter-title']}>
            Select billable period:
          </span>

          <ErrorBoundary>
            <Suspense
              fallback={
                <SkeletonCustomField
                  width="200px"
                  height="40px"
                  backgroundColor="var(--field-skeleton-background-color)"
                  backgroundShineColor="var(--field-skeleton-shine-background-color)"
                  borderRadius="5px"
                />
              }
            >
              <div className="mw-200">
                <BillingReportFilter />
              </div>
            </Suspense>
          </ErrorBoundary>
        </FlexContainer>
      </Card>
      <Card
        title="Involved in"
        titleAccent={billingPeriod.label}
        headerAdditional={
          <FlexContainer spaceX="children-x-10px">
            <SearchFilter />
          </FlexContainer>
        }
      >
        <ErrorBoundary>
          <Suspense fallback={<SkeletonTable countTableBodyRows={20} />}>
            <TableInvolved />
          </Suspense>
        </ErrorBoundary>
      </Card>
    </>
  );
};

export default InvolvedTab;
