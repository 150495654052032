const updateCssThemeColorVariables = (theme) => {
  const isDarkMode = JSON.parse(localStorage.getItem('is-dark'));
  const root = document.documentElement;

  const defaultThemeColorVariables = (isDarkMode) => {
    if (isDarkMode) {
      // default theme has different colors depend of dark mode
      // Primary color settings:
      root.style.setProperty('--theme-primary-hue', '149');
      root.style.setProperty('--theme-primary-saturation', '58%');
      root.style.setProperty('--theme-primary-lightness', '31%');
      // Accent color settings:
      root.style.setProperty('--theme-accent-hue', '149');
      root.style.setProperty('--theme-accent-saturation', '57%');
      root.style.setProperty('--theme-accent-lightness', '42%');
      // Primary color variations:
      root.style.setProperty(
        '--theme-color-primary-light',
        'hsl(var(--theme-primary-hue), 40%, 45%)'
      );
      // custom colors which are in diff shape on dark mode
      root.style.setProperty(
        '--theme-primary-dark-transparent',
        'var(--theme-color-primary-light)'
      );
      // custom colors by elements
      root.style.setProperty(
        '--theme-custom-text-color',
        'var(--theme-text-color-invert)'
      );
      root.style.setProperty(
        '--app-sidebar-background-color',
        'var(--theme-background-color-light-custom)'
      );

      root.style.setProperty(
        '--theme-color-custom-shine',
        'hsl(var(--theme-primary-hue), 40%, 47%)'
      );
    } else {
      // default theme has different colors depend of dark mode
      // Primary color settings:
      root.style.setProperty('--theme-primary-hue', '210');
      root.style.setProperty('--theme-primary-saturation', '96%');
      root.style.setProperty('--theme-primary-lightness', '34%');
      // Accent color settings:
      root.style.setProperty('--theme-accent-hue', '103');
      root.style.setProperty('--theme-accent-saturation', '50%');
      root.style.setProperty('--theme-accent-lightness', '50%');
      // Primary color variations:
      root.style.setProperty(
        '--theme-color-primary-light',
        'hsl(var(--theme-primary-hue), 83%, 41%)'
      );
      // custom colors which are in diff shape on dark mode
      root.style.setProperty(
        '--theme-primary-dark-transparent',
        'hsla(var(--theme-primary-hue), var(--theme-primary-saturation), calc(var(--theme-primary-lightness) - 13%), 0.1)'
      );
      // custom colors by elements
      root.style.setProperty(
        '--theme-custom-text-color',
        'var(--theme-text-color-invert)'
      );
      root.style.setProperty(
        '--app-sidebar-background-color',
        'var(--theme-color-primary-dark)'
      );
      root.style.setProperty(
        '--theme-color-custom-shine',
        'hsl(var(--theme-primary-hue), 80%, 45%)'
      );
    }
  };

  switch (theme) {
    case 'default':
      defaultThemeColorVariables(isDarkMode);
      break;
    case 'pink':
      if (isDarkMode) {
        root.style.setProperty('--theme-custom-text-color', 'hsl(0, 0%, 6%)');
        // custom colors by elements
        root.style.setProperty(
          '--app-sidebar-background-color',
          'var(--theme-background-color-light-custom)'
        );
      } else {
        root.style.setProperty(
          '--app-sidebar-background-color',
          'hsl(180, 50%, 8%)'
        );
        root.style.setProperty(
          '--theme-custom-text-color',
          'var(--theme-text-color-invert)'
        );
      }

      // pink theme has equal colors for light and dark mode.
      // Primary color settings:
      root.style.setProperty('--theme-primary-hue', '327');
      root.style.setProperty('--theme-primary-saturation', '98%');
      root.style.setProperty('--theme-primary-lightness', '75%');
      // Accent color settings: !TODO create accent color
      root.style.setProperty('--theme-accent-hue', '327');
      root.style.setProperty('--theme-accent-saturation', '57%');
      root.style.setProperty('--theme-accent-lightness', '42%');
      // Primary color variations:
      root.style.setProperty(
        '--theme-color-primary-light',
        'hsl(calc(var(--theme-primary-hue) - 10), 100%, 82%)'
      );

      // custom colors which are in diff shape on dark mode
      root.style.setProperty(
        '--theme-primary-dark-transparent',
        'var(--theme-color-primary-light)'
      );
      root.style.setProperty(
        '--theme-color-custom-shine',
        'hsl(calc(var(--theme-primary-hue) - 15), 95%, 84%)'
      );
      break;
    case 'blue':
      if (isDarkMode) {
        root.style.setProperty('--theme-custom-text-color', 'hsl(0, 0%, 6%)');
        // custom colors by elements
        root.style.setProperty(
          '--app-sidebar-background-color',
          'var(--theme-background-color-light-custom)'
        );
      } else {
        root.style.setProperty(
          '--app-sidebar-background-color',
          'hsl(180, 50%, 8%)'
        );
        root.style.setProperty(
          '--theme-custom-text-color',
          'var(--theme-text-color-invert)'
        );
      }

      // blue theme has equal colors for light and dark mode.
      // Primary color settings:
      root.style.setProperty('--theme-primary-hue', '204');
      root.style.setProperty('--theme-primary-saturation', '100%');
      root.style.setProperty('--theme-primary-lightness', '62%');
      // Accent color settings: !TODO create accent color
      root.style.setProperty('--theme-accent-hue', '204');
      root.style.setProperty('--theme-accent-saturation', '57%');
      root.style.setProperty('--theme-accent-lightness', '42%');
      // Primary color variations:
      root.style.setProperty(
        '--theme-color-primary-light',
        'hsl(calc(var(--theme-primary-hue) - 4), 100%, 66%)'
      );

      // custom colors which are in diff shape on dark mode
      root.style.setProperty(
        '--theme-primary-dark-transparent',
        'var(--theme-color-primary-light)'
      );
      root.style.setProperty(
        '--theme-color-custom-shine',
        'hsl(calc(var(--theme-primary-hue) - 4), 90%, 70%)'
      );
      break;
    case 'cherry':
      // cherry theme has equal colors for light and dark mode.
      // Primary color settings:
      root.style.setProperty('--theme-primary-hue', '342');
      root.style.setProperty('--theme-primary-saturation', '79%');
      root.style.setProperty('--theme-primary-lightness', '41%');
      // Accent color settings: !TODO create accent color
      root.style.setProperty('--theme-accent-hue', '342');
      root.style.setProperty('--theme-accent-saturation', '57%');
      root.style.setProperty('--theme-accent-lightness', '42%');
      // Primary color variations:
      root.style.setProperty(
        '--theme-color-primary-light',
        'hsl(var(--theme-primary-hue), 67%, 52%)'
      );
      // custom colors which are in diff shape on dark mode
      root.style.setProperty(
        '--theme-primary-dark-transparent',
        'var(--theme-color-primary-light)'
      );

      // custom colors by elements
      root.style.setProperty(
        '--app-sidebar-background-color',
        'hsl(180, 50%, 8%)'
      );

      // not changable colors
      root.style.setProperty('--theme-custom-text-color', 'hsl(0, 0%, 100%)');
      root.style.setProperty(
        '--theme-color-custom-shine',
        'hsl(var(--theme-primary-hue), 70%, 58%)'
      );
      break;
    case 'orange':
      // orange theme has equal colors for light and dark mode.
      // Primary color settings:
      root.style.setProperty('--theme-primary-hue', '24');
      root.style.setProperty('--theme-primary-saturation', '93%');
      root.style.setProperty('--theme-primary-lightness', '52%');
      // Accent color settings: !TODO create accent color
      root.style.setProperty('--theme-accent-hue', '24');
      root.style.setProperty('--theme-accent-saturation', '57%');
      root.style.setProperty('--theme-accent-lightness', '42%');
      // Primary color variations:
      root.style.setProperty(
        '--theme-color-primary-light',
        'hsl(calc(var(--theme-primary-hue) + 1), 100%, 58%)'
      );
      // custom colors which are in diff shape on dark mode
      root.style.setProperty(
        '--theme-primary-dark-transparent',
        'var(--theme-color-primary-light)'
      );

      // custom colors by elements
      root.style.setProperty(
        '--app-sidebar-background-color',
        'hsl(0, 0%, 10%)'
      );

      // not changable colors
      root.style.setProperty('--theme-custom-text-color', 'hsl(0, 0%, 100%)');
      root.style.setProperty(
        '--theme-color-custom-shine',
        'hsl(var(--theme-primary-hue), 90%, 65%)'
      );
      break;
    case 'yellow':
      if (isDarkMode) {
        // custom colors by elements
        root.style.setProperty(
          '--app-sidebar-background-color',
          'var(--theme-background-color-light-custom)'
        );
      } else {
        root.style.setProperty(
          '--app-sidebar-background-color',
          'hsl(0, 0%, 6%)'
        );
      }

      // yellow theme has equal colors for light and dark mode.
      // Primary color settings:
      root.style.setProperty('--theme-primary-hue', '45');
      root.style.setProperty('--theme-primary-saturation', '96%');
      root.style.setProperty('--theme-primary-lightness', '64%');
      // Accent color settings: !TODO create accent color
      root.style.setProperty('--theme-accent-hue', '45');
      root.style.setProperty('--theme-accent-saturation', '57%');
      root.style.setProperty('--theme-accent-lightness', '42%');
      // Primary color variations:
      root.style.setProperty(
        '--theme-color-primary-light',
        'hsl(calc(var(--theme-primary-hue) + 5), 100%, 69%)'
      );
      // custom colors which are in diff shape on dark mode
      root.style.setProperty(
        '--theme-primary-dark-transparent',
        'var(--theme-color-primary-light)'
      );

      // not changable colors
      root.style.setProperty('--theme-custom-text-color', 'hsl(0, 0%, 6%)');
      root.style.setProperty(
        '--theme-color-custom-shine',
        'hsl(calc(var(--theme-primary-hue) + 10), 85%, 75%)'
      );
      break;
    default:
      defaultThemeColorVariables(isDarkMode);
  }
};

export default updateCssThemeColorVariables;
