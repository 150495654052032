import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './PageHeader.module.scss';

const PageHeader = ({ title }) => {
  return (
    <div className={styles['page-header']}>
      <h1>{title}</h1>
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageHeader;
