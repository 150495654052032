import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useParams, Link } from 'react-router-dom';

import { useTabContext } from 'GlobalState/Context/TabContext';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Forms
import ComponentsProjectForm from 'components/Form/SingleProject/ComponentsProjectForm';
import MergeComponentsForm from 'components/Form/SingleProject/MergeComponentsForm';

// Table
import {
  useTable,
  useSortBy,
  useFlexLayout,
  useFilters,
  useRowSelect,
} from 'react-table';
import Label from 'components/Label/Label';
import Edit from 'components/Icons/Edit';
import Button from 'components/Button/Button';
import IsActiveCheckbox from 'components/Checkbox/IsActiveCheckbox';
import CheckSolid from 'components/Icons/Check-solid';

// styles
import cx from 'classnames';
import tableStyles from '../Tables.module.scss';
import tableProjectStyles from './TableSingleProject.module.scss';

import { useQuery } from 'react-query';
import instance from 'axiosInstance';

/**
 * The one, the only
 *
 * This is the home page table and it's the most used component of the whole app
 * @see https://react-table.js.org/ - 90% of the stuff is there.
 * @return {JSX} My reports table
 */

// Todo: - Modal on top. Data changes on edit click
const TableComponentsProjectUI = ({ data }) => {
  // accessor is the "key" in the data
  const {
    state: { searchFilter },
  } = useTabContext();

  const { slug } = useParams();

  const { globalDispatch } = useGlobalContext();

  const columns = useMemo(
    () => [
      {
        Header: 'Component',
        accessor: 'name',
        maxWidth: 1900,
        minWidth: 200,
        width: 200,
        filter: (rows, columnId, filterValue) =>
          rows.filter((row) =>
            row.original.name.toLowerCase().includes(filterValue.toLowerCase())
          ),
      },
      {
        Header: 'Select to Merge',
        accessor: 'merge',
        maxWidth: 50,
        minWidth: 120,
        width: 120,
        disableSortBy: true,
      },
      {
        Header: 'Activity',
        accessor: 'isActive',
        maxWidth: 15,
        minWidth: 120,
        width: 120,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 15,
        minWidth: 120,
        width: 120,
        disableSortBy: true,
      },
    ],
    []
  );

  const handleComponentMerge = () => {
    const selectedComponents = selectedRows.map((row) => row.original.id);
    console.log(selectedComponents);
    globalDispatch({
      type: 'setModalContent',
      payload: (
        <MergeComponentsForm
          slug={slug}
          selectedComponents={selectedComponents}
        />
      ),
    });
  };

  const handleEditPopup = (row) => {
    globalDispatch({
      type: 'setModalContent',
      payload: (
        <ComponentsProjectForm
          heading="Edit Component"
          row={row}
          type="edit"
          slug={slug}
        />
      ),
    });
  };

  /**
   * The useTable hook is what gives us the instances to render the table.
   * @see https://react-table.js.org/api/useTable
   */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useRowSelect
  );

  useEffect(() => {
    setFilter('name', searchFilter);
  }, [searchFilter]);

  /**
   * In the header, we are showing the label + can add sorting options
   */

  const tableHeader = useMemo(
    () =>
      headerGroups.map((headerGroup, key) => (
        <div
          key={key}
          className={cx(tableStyles.tr, tableStyles['sticky-row'])}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map((column) => (
            <div
              key={column.id}
              className={`${tableStyles.th}`}
              {...column.getHeaderProps(column.getSortByToggleProps())}
            >
              <Label
                fontStyle="bold"
                arrow={column.canSort ? 'arrow-down' : ''}
              >
                {column.render('Header')}
              </Label>
            </div>
          ))}
        </div>
      )),
    []
  );

  /**
   * The tableBody is the date we get from the API. Currently static :)
   */
  const tableBody = rows.map((row) => {
    // (Required) Any row that you intend to render in your table needs to be passed to
    // this function before every render.
    prepareRow(row);

    return (
      <div key={row.id} className={tableStyles.tr} {...row.getRowProps()}>
        {row.cells.map((cell) => {
          let output = <Label>{cell.value}</Label>;
          // Standard output

          if (cell.column.id === 'name') {
            output = (
              <Link
                to={`/project/${slug}/component/${row.original.id}/?name=${row.original.name}`}
              >
                {row.original.name}
              </Link>
            );
          }

          if (cell.column.id === 'isActive') {
            output = (
              <IsActiveCheckbox
                endpoint={
                  '/private/admin/project-components/active/' + row.original.id
                }
                isChecked={row.original.isActive}
                queriesToInvalidate={['components']}
              />
            );
          }

          if (cell.column.id === 'merge') {
            output = (
              <>
                <label
                  htmlFor={`checkbox-${row.id}`}
                  className={cx(
                    tableStyles['checkbox-wrapper'],
                    tableStyles['w-40px'],
                    {
                      [tableStyles['has-color']]: row.isSelected,
                    }
                  )}
                >
                  {row.isSelected && (
                    <CheckSolid
                      color={`var(--theme-base-color)`}
                      width="18px"
                      height="14px"
                    />
                  )}
                </label>
                <input
                  className="is-hidden"
                  type="checkbox"
                  id={`checkbox-${row.id}`}
                  {...row.getToggleRowSelectedProps()}
                />
              </>
            );
          }

          if (cell.column.id === 'actions') {
            output = (
              <div className={tableStyles['actions-wrapper']}>
                <Button
                  type="button-primary-inline"
                  icon={<Edit width="20px" height="20px" />}
                  clicked={() => handleEditPopup(row.original)}
                />
              </div>
            );
          }

          return (
            <div
              key={cell.column.id}
              className={cx(tableStyles.td, 'relative')}
              {...cell.getCellProps()}
            >
              {output}
            </div>
          );
        })}
      </div>
    );
  });

  const selectedRows = rows.filter((row) => {
    prepareRow(row);

    return row.isSelected;
  });

  const SelectedComponents = selectedRows.map((row, key) => {
    prepareRow(row);

    if (row.isSelected) {
      return (
        <span className={tableProjectStyles['component-in-footer']} key={key}>
          {row.original.name}
        </span>
      );
    }
  });

  return (
    <div className={tableProjectStyles['table-wrapper']}>
      <div
        {...getTableProps}
        className={cx(tableStyles.table, 'custom-scroll')}
      >
        <div
          className={cx(tableProjectStyles.thead, tableStyles['thead-sticky'])}
        >
          {tableHeader}
        </div>
        <div
          className={`${tableStyles.tbody} ${tableProjectStyles.tbody}`}
          {...getTableBodyProps()}
        >
          {tableBody}
        </div>
        {selectedRows.length > 0 && (
          <div className={cx(tableStyles.tfoot, tableStyles['tfoot-sticky'])}>
            <div
              className={cx(
                tableStyles['sticky-row'],
                tableProjectStyles['merge-row']
              )}
            >
              {SelectedComponents}
              <Button
                clicked={handleComponentMerge}
                text="Merge"
                additionalInlineStyles={{
                  minWidth: '6.25em',
                  paddingTop: '0.375em',
                  paddingBottom: '0.375em',
                  marginLeft: 'auto',
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

TableComponentsProjectUI.propTypes = {
  data: PropTypes.array,
};

const getComponents = async ({ queryKey }) => {
  const response = await instance.get(
    '/private/project-components/names?project_slug=' + queryKey[1]
  );
  return response.data;
};

const TableComponentsProject = ({ shouldShowInactive }) => {
  const { slug } = useParams();
  const { data } = useQuery(['components', slug], getComponents);
  const activeProjects = data.filter((project) => project.isActive);

  return (
    <TableComponentsProjectUI
      data={shouldShowInactive ? data : activeProjects}
    />
  );
};

TableComponentsProject.propTypes = {
  shouldShowInactive: PropTypes.bool,
};

export default TableComponentsProject;
