import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useTabContext } from 'GlobalState/Context/TabContext';

// Table
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import TableSingleMemberTablesView from 'components/Tables/SingleMember/TableSingleMemberTablesView';
import SkeletonCustomField from 'components/LoadingSkeletons/SkeletonCustomField/SkeletonCustomField';
import SkeletonTable from 'components/LoadingSkeletons/SkeletonTable/SkeletonTable';
import { CalendarMonthFilter } from 'components/Form/Selects/BillingReportFilter';

// Utils
import { parseMonthsToNameFromDate } from 'utils/dateFunctions';

import styles from './SingleMember.module.scss';

// Data
import { useQuery } from 'react-query';
import instance from 'axiosInstance';

// Requiest
const getTables = async ({ queryKey }) => {
  const [year, month] = queryKey[2].split('-');
  const response = await instance.get(
    `/private/admin/employees-reports-month/${queryKey[1]}/month/${month}/year/${year}`
  );

  return response.data;
};

const TabSingleMemberTables = () => {
  return (
    <div>
      <ErrorBoundary>
        <Suspense
          fallback={
            <SkeletonCustomField
              width="100%"
              height="50px"
              backgroundColor="var(--field-skeleton-background-color)"
              backgroundShineColor="var(--field-skeleton-shine-background-color)"
              borderRadius="5px"
            />
          }
        >
          <CalendarMonthFilter />
        </Suspense>
        <Suspense fallback={<SkeletonTable countTableBodyRows={30} />}>
          <Tables />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

const Tables = () => {
  const { slug } = useParams();
  const {
    state: { calendarPeriod },
  } = useTabContext();
  const { data } = useQuery(
    ['month-tables', slug, calendarPeriod.value],
    getTables
  );

  const tables = Object.keys(data)
    .sort((a, b) => (a > b ? -1 : 1))
    .map((date) => {
      const sum = data[date].reduce(
        (prev, current) => prev + Number(current['hours_spent']),
        0
      );
      return (
        <div key={date}>
          <div className={styles['date-hours-wrapper']}>
            <h2 className={styles['date-heading']}>
              {parseMonthsToNameFromDate(new Date(date))}
            </h2>
            <p className={styles['hours-sum']}>Daily: {sum}h</p>
          </div>
          <TableSingleMemberTablesView data={data[date]} />
        </div>
      );
    });

  return <div>{tables}</div>;
};

export default TabSingleMemberTables;
