import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import styles from './Button.module.scss';

const Button = ({
  clicked,
  text,
  type,
  additionalStyles,
  additionalInlineStyles,
  icon,
}) => {
  return (
    <span
      className={cx(styles.button, styles[type], additionalStyles)}
      style={additionalInlineStyles}
      onClick={clicked}
    >
      {text}
      {icon}
    </span>
  );
};

// additionalStyles is to add new class
// additionalInlineStyles is to add new css to override the initial styles of the button
// type is used to set the design type of the button

Button.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  type: PropTypes.string,
  additionalStyles: PropTypes.string,
  additionalInlineStyles: PropTypes.string,
  clicked: PropTypes.func,
};

export default Button;
