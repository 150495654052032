import React, { Suspense, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useTabContext } from 'GlobalState/Context/TabContext';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Forms
const ManageProjectsForm = React.lazy(() =>
  import('components/Form/Manage/ManageProjectsForm/ManageProjectsForm')
);

// Table
import {
  useTable,
  useSortBy,
  useFlexLayout,
  useGlobalFilter,
} from 'react-table';
import Label from 'components/Label/Label';
import CheckSolid from 'components/Icons/Check-solid';
import Edit from 'components/Icons/Edit';
import Button from 'components/Button/Button';
import projectTableGlobalFilter from 'utils/projectsTableGlobalFilter';
import IsActiveCheckbox from 'components/Checkbox/IsActiveCheckbox';

// styles
import cx from 'classnames';
import tableStyles from '../../Tables.module.scss';
import styles from '../Manage.module.scss';

// Fetch data
import { useQuery } from 'react-query';
import instance from 'axiosInstance';

/**
 * The one, the only
 *
 * This is the home page table and it's the most used component of the whole app
 * @see https://react-table.js.org/ - 90% of the stuff is there.
 * @return {JSX} My reports table
 */

// Todo: - Modal on top. Data changes on edit click
const TableManageProjectsUI = ({ data }) => {
  const {
    state: { searchFilter },
  } = useTabContext();

  const { globalDispatch } = useGlobalContext();

  const globalFilter = useMemo(() => projectTableGlobalFilter, []);
  // accessor is the "key" in the data
  const columns = useMemo(
    () => [
      {
        Header: 'Project Name',
        accessor: 'project',
        maxWidth: 200,
        minWidth: 160,
        width: 160,
        sortType: (a, b) =>
          a.original.project.name < b.original.project.name ? -1 : 1,
      },
      {
        Header: 'Billable Hours',
        accessor: 'retainer',
        maxWidth: 160,
        minWidth: 160,
        width: 160,
      },
      {
        Header: 'Project Owner',
        accessor: 'po',
        maxWidth: 200,
        minWidth: 180,
        width: 180,
        sortType: (a, b) => (a.original.po.name < b.original.po.name ? -1 : 1),
      },
      {
        Header: 'Project Manager',
        accessor: 'pm',
        maxWidth: 200,
        minWidth: 180,
        width: 180,
        sortType: (a, b) => (a.original.pm.name < b.original.pm.name ? -1 : 1),
      },
      {
        Header: 'Client Name',
        accessor: 'client',
        maxWidth: 200,
        minWidth: 160,
        width: 160,
        sortType: (a, b) =>
          a.original.client.name < b.original.client.name ? -1 : 1,
      },
      {
        Header: 'Is retainer',
        accessor: 'isRetainer',
        maxWidth: 120,
        minWidth: 120,
        width: 120,
        disableGlobalFilter: true,
        sortType: (a, b) =>
          a.original.isRetainer > b.original.isRetainer ? -1 : 1,
      },
      {
        Header: 'Activity',
        accessor: 'isActive',
        maxWidth: 120,
        minWidth: 120,
        width: 120,
        disableGlobalFilter: true,
        sortType: (a, b) =>
          a.original.isActive > b.original.isActive ? -1 : 1,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 90,
        minWidth: 120,
        width: 120,
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  const handleEditPopup = (row) => {
    globalDispatch({
      type: 'setModalContent',
      payload: (
        <Suspense fallback={<div>Loading</div>}>
          <ManageProjectsForm heading="Edit Project" row={row} type="edit" />
        </Suspense>
      ),
    });
  };

  /**
   * The useTable hook is what gives us the instances to render the table.
   * @see https://react-table.js.org/api/useTable
   */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      globalFilter,
      columns,
      data,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    useFlexLayout
  );

  useEffect(() => {
    setGlobalFilter(searchFilter);
  }, [searchFilter]);

  /**
   * In the header, we are showing the label + can add sorting options
   */

  const tableHeader = useMemo(
    () =>
      headerGroups.map((headerGroup, key) => (
        <div
          key={key}
          className={cx(tableStyles.tr, tableStyles['sticky-row'])}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map((column) => (
            <div
              key={column.id}
              className={`${tableStyles.th} ${styles.th}`}
              {...column.getHeaderProps(column.getSortByToggleProps())}
            >
              <Label
                fontStyle="bold"
                arrow={column.canSort ? 'arrow-down' : ''}
              >
                {column.render('Header')}
              </Label>
            </div>
          ))}
        </div>
      )),
    []
  );

  /**
   * The tableBody is the date we get from the API. Currently static :)
   */
  const tableBody = useMemo(
    () =>
      rows.map((row) => {
        // (Required) Any row that you intend to render in your table needs to be passed to
        // this function before every render.
        prepareRow(row);

        return (
          <div key={row.id} className={tableStyles.tr} {...row.getRowProps()}>
            {row.cells.map((cell) => {
              let output = <Label>{cell.value}</Label>;
              // Standard output

              if (cell.column.id === 'project') {
                output = (
                  <Link to={`/project/${cell.value.slug}`}>
                    {cell.value.name}
                  </Link>
                );
              }

              if (cell.column.id === 'po' || cell.column.id === 'pm') {
                output = (
                  <Link to={`/member/${cell.value.slug}`}>
                    {cell.value.name}
                  </Link>
                );
              }

              if (cell.column.id === 'client') {
                output = (
                  <Link to={`/client/${cell.value.slug}`}>
                    {cell.value.name}
                  </Link>
                );
              }

              if (cell.column.id === 'isRetainer') {
                output = cell.value === true && (
                  <span className={tableStyles['centered-td-content']}>
                    <CheckSolid
                      color={'var(--theme-color-primary)'}
                      width="20"
                      height="20"
                    />
                  </span>
                );
              }

              if (cell.column.id === 'isActive') {
                output = (
                  <IsActiveCheckbox
                    endpoint={
                      '/private/admin/projects/active/' +
                      row.original.project.id
                    }
                    isChecked={row.original.isActive}
                    queriesToInvalidate={['projects']}
                  />
                );
              }

              if (cell.column.id === 'retainer') {
                output = <Label>{cell.value}h</Label>;
              }

              if (cell.column.id === 'actions') {
                output = (
                  <div className={tableStyles['actions-wrapper']}>
                    <Button
                      type="button-primary-inline"
                      icon={<Edit width="20px" height="20px" />}
                      clicked={() => handleEditPopup(row.original)}
                    />
                  </div>
                );
              }

              return (
                <div
                  key={cell.column.id}
                  className={cx(tableStyles.td)}
                  {...cell.getCellProps()}
                >
                  {output}
                </div>
              );
            })}
          </div>
        );
      }),
    [rows]
  );

  return (
    <div className={tableStyles.table} {...getTableProps}>
      <div className={`${tableStyles['thead-sticky']} thead`}>
        {tableHeader}
      </div>
      <div className={tableStyles.tbody} {...getTableBodyProps()}>
        {tableBody}
      </div>
    </div>
  );
};

TableManageProjectsUI.propTypes = {
  data: PropTypes.array,
};

const getProjects = async () => {
  const response = await instance.get('/private/admin/projects/?all=yes');
  return response.data;
};
const TableManageProjects = ({ shouldShowInactive }) => {
  const { data } = useQuery('projects', getProjects);

  const activeProjects = data.filter((project) => project.isActive);

  return (
    <TableManageProjectsUI data={shouldShowInactive ? data : activeProjects} />
  );
};

TableManageProjects.propTypes = {
  shouldShowInactive: PropTypes.bool,
};

export default TableManageProjects;
