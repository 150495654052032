import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Form
import { useForm } from 'react-hook-form';
import InputField from '../InputField';
import SubmitButton from '../SubmitButton';
import Button from 'components/Button/Button';

// styles
import formStyles from '../Form.module.scss';

// Data
import { useMutation, useQueryClient } from 'react-query';
import instance from 'axiosInstance';

const mergeComponents = async (variables) => {
  const params = new URLSearchParams();
  params.append('project', variables.project);
  params.append('aggregatorComponent', variables.aggregatorComponent);

  for (const component of variables.selectedComponents) {
    params.append('selectedComponents[]', component);
  }

  const response = await instance.post(
    '/private/admin/project-components/merge',
    params
  );
  return response.data;
};

const MergeComponentsForm = ({ slug, selectedComponents }) => {
  const queryClient = useQueryClient();
  const { globalDispatch } = useGlobalContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutate } = useMutation(mergeComponents, {
    mutationKey: 'merge-components',
    onSuccess: () => {
      queryClient.invalidateQueries(['components', slug]);
      globalDispatch({ type: 'setModalContent', payload: null });

      globalDispatch({
        type: 'pushNotification',
        payload: { type: 'success', text: 'Components merged successfully!' },
      });
    },

    onError: () => {
      globalDispatch({
        type: 'pushNotification',
        payload: { type: 'error', text: 'Something went wrong :(' },
      });
    },
  });

  const handleRejection = () => {
    globalDispatch({ type: 'setModalContent', payload: null });
  };

  const onSubmit = (data) => {
    data.selectedComponents = selectedComponents;
    data.project = slug;
    mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputField
        placeholder="Aggregator Component name"
        wrapperClassName={`${formStyles['form-input-wrapper']}`}
        errors={errors.aggregatorComponent}
        autofocus
        label="Aggregator Component"
        {...register('aggregatorComponent', {
          required: '*Aggregator Component name is required',
        })}
      />
      <div className={formStyles['form-actions-wrapper']}>
        <Button
          clicked={handleRejection}
          type="button-primary-inline-adaptive"
          text="Cancel"
        />
        <SubmitButton
          type="button-success"
          additionalStyles={formStyles['edit-report-submit']}
          text="Save"
        />
      </div>
    </form>
  );
};

MergeComponentsForm.propTypes = {
  selectedComponents: PropTypes.array,
  slug: PropTypes.string,
};

export default MergeComponentsForm;
