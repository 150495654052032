import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// Table
import { useTable, useSortBy, useFlexLayout } from 'react-table';
import Label from 'components/Label/Label';

// styles
import cx from 'classnames';
import tableStyles from '../Tables.module.scss';

/**
 * The one, the only
 *
 * This is the home page table and it's the most used component of the whole app
 * @see https://react-table.js.org/ - 90% of the stuff is there.
 * @return {JSX} My reports table
 */

// Todo: - Modal on top. Data changes on edit click
const TableSingleMemberTablesView = ({ data }) => {
  // accessor is the "key" in the data
  const columns = useMemo(
    () => [
      {
        Header: 'Project Name',
        accessor: 'project_name',
        minWidth: 100,
        width: 200,
      },
      {
        Header: 'Component',
        accessor: 'component_name',
        minWidth: 100,
        width: 200,
      },
      {
        Header: 'Description',
        accessor: 'task_description',
        minWidth: 200,
        width: 500,
      },
      {
        Header: 'Task',
        accessor: 'task',
        minWidth: 100,
        width: 300,
      },
      {
        Header: 'Time',
        accessor: 'hours_spent',
        minWidth: 50,
        width: 120,
        sortType: (a, b) =>
          Number(a.original.hours_spent) > Number(b.original.hours_spent)
            ? -1
            : 1,
      },
    ],
    []
  );

  /**
   * The useTable hook is what gives us the instances to render the table.
   * @see https://react-table.js.org/api/useTable
   */
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableSortRemove: true,
      },
      useSortBy,
      useFlexLayout
    );

  /**
   * In the header, we are showing the label + can add sorting options
   */

  const tableHeader = headerGroups.map((headerGroup, key) => (
    <div
      key={key}
      className={cx(tableStyles.tr, tableStyles['sticky-row'])}
      {...headerGroup.getHeaderGroupProps()}
    >
      {headerGroup.headers.map((column) => {
        const output = <Label>{column.render('Header')}</Label>;

        return (
          <div
            key={column.id}
            className={tableStyles.th}
            {...column.getHeaderProps(column.getSortByToggleProps())}
          >
            {output}
          </div>
        );
      })}
    </div>
  ));

  /**
   * The tableBody is the date we get from the API. Currently static :)
   */
  const tableBody = rows.map((row) => {
    // (Required) Any row that you intend to render in your table needs to be passed to
    // this function before every render.
    prepareRow(row);

    return (
      <div key={row.id} className={tableStyles.tr} {...row.getRowProps()}>
        {row.cells.map((cell) => {
          let output = <Label>{cell.value}</Label>;

          if (cell.column.id === 'task' && row.original.task_link) {
            output = (
              <a target="__blank" rel="nofollow" href={row.original.task_link}>
                {row.original.task_title || 'Open Link'}
              </a>
            );
          }

          return (
            <div
              key={cell.column.id}
              className={cx(tableStyles.td)}
              {...cell.getCellProps()}
            >
              {output}
            </div>
          );
        })}
      </div>
    );
  });

  return (
    <div className={tableStyles.table} {...getTableProps}>
      <div className={`${tableStyles['thead-sticky']} thead`}>
        {tableHeader}
      </div>
      <div className={tableStyles.tbody} {...getTableBodyProps()}>
        {tableBody}
      </div>
    </div>
  );
};

TableSingleMemberTablesView.propTypes = {
  data: PropTypes.array,
};

export default TableSingleMemberTablesView;
