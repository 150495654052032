import React, { Suspense, useState } from 'react';
import { useTabContext } from 'GlobalState/Context/TabContext';
import { useParams } from 'react-router-dom';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Table
import TableSingleProject from 'components/Tables/TableSingleProject/TableSingleProject';
import SkeletonTable from 'components/LoadingSkeletons/SkeletonTable/SkeletonTable';

// UI components
import Card from 'components/Card/Card';
import FlexContainer from 'components/FlexContainer/FlexContainer';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

// Styles
import tabProjectStyles from './Project.module.scss';
import sharedStyles from 'styles/shared.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import BillingReportFilter from 'components/Form/Selects/BillingReportFilter';
import SkeletonCustomField from 'components/LoadingSkeletons/SkeletonCustomField/SkeletonCustomField';

import { useQuery } from 'react-query';
import instance from 'axiosInstance';

const requestCSV = async ({ queryKey }) => {
  const response = await instance.get(
    `/private/admin/projects-exports/${queryKey[3]}?type=${queryKey[1]}&period=${queryKey[2]}`
  );

  return response;
};

const ProjectTab = () => {
  const {
    state: { isTableExpanded, billingPeriod, isTableSumMode },
    dispatch,
  } = useTabContext();

  const {
    globalState: { isAdmin },
  } = useGlobalContext();

  const { slug } = useParams();
  const [exportType, setExportType] = useState('full');

  const { refetch } = useQuery(
    ['csv', exportType, billingPeriod.value, slug],
    requestCSV,
    {
      enabled: false,
      suspense: false,
      onSuccess: (response) => {
        const fileName = response.headers['project-details'];
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', fileName + '.csv');
        a.click();
      },
    }
  );

  return (
    <>
      <Card
        hasPadding
        additionalStyles={tabProjectStyles['table-project-card']}
      >
        <FlexContainer align="align-center" wrap="wrap">
          <span className={tabProjectStyles['card-filter-title']}>
            Select billable period:
          </span>
          <ErrorBoundary>
            <Suspense
              fallback={
                <SkeletonCustomField
                  width="322px"
                  height="40px"
                  backgroundColor="var(--field-skeleton-background-color)"
                  backgroundShineColor="var(--field-skeleton-shine-background-color)"
                  borderRadius="5px"
                />
              }
            >
              <div className="mw-200">
                <BillingReportFilter />
              </div>
            </Suspense>
          </ErrorBoundary>
        </FlexContainer>
      </Card>
      <Card
        title="Report for"
        titleAccent={billingPeriod.label}
        headerAdditional={
          <div className={layoutStyles['row-wrap']}>
            <span
              className={sharedStyles['action']}
              onClick={() => {
                dispatch({ type: 'setIsTableExpanded', payload: false });
                dispatch({ type: 'toggleIsTableSumMode' });
              }}
            >
              <span>
                {isTableSumMode ? 'Expand mode' : 'Component Sum Mode'}
              </span>
            </span>

            {!isTableExpanded ? (
              <span
                className={sharedStyles['action']}
                onClick={() =>
                  dispatch({ type: 'setIsTableExpanded', payload: true })
                }
              >
                <span>Expand all</span>
              </span>
            ) : (
              <span
                className={sharedStyles['action']}
                onClick={() =>
                  dispatch({ type: 'setIsTableExpanded', payload: false })
                }
              >
                <span>Collapse all</span>
              </span>
            )}
            {isAdmin && (
              <>
                <span
                  className={sharedStyles['action']}
                  onClick={() => {
                    setExportType('full');
                    setTimeout(() => {
                      refetch();
                    }, 100);
                  }}
                >
                  <span>Export Raw</span>
                </span>
                <span
                  className={sharedStyles['action']}
                  onClick={() => {
                    setExportType('normalized');
                    setTimeout(() => {
                      refetch();
                    }, 100);
                  }}
                >
                  <span>Export Normalized</span>
                </span>
              </>
            )}
          </div>
        }
      >
        <ErrorBoundary>
          <Suspense fallback={<SkeletonTable countTableBodyRows={10} />}>
            <TableSingleProject />
          </Suspense>
        </ErrorBoundary>
      </Card>
    </>
  );
};

export default ProjectTab;
