const actionMap = {
  setSearchFilter: (state, payload) => ({ ...state, searchFilter: payload }),
  setIsTableExpanded: (state, payload) => ({
    ...state,
    isTableExpanded: payload,
  }),
  toggleIsTableSumMode: (state) => ({
    ...state,
    isTableSumMode: !state.isTableSumMode,
  }),
  setBillingPeriod: (state, payload) => ({
    ...state,
    billingPeriod: payload,
  }),
  setCalendarPeriod: (state, payload) => ({
    ...state,
    calendarPeriod: payload,
  }),
  setCurrentWeek: (state, payload) => ({
    ...state,
    currentWeek: payload,
  }),
  setActiveDay: (state, payload) => ({
    ...state,
    activeDay: payload,
  }),
};

const tabReducer = (state, action) => {
  const handler = actionMap[action.type];

  return handler ? handler(state, action.payload) : state;
};

export default tabReducer;
