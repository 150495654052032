import React from 'react';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';
import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';
import { useTabContext } from 'GlobalState/Context/TabContext';

// Form
import { useForm } from 'react-hook-form';
import InputFieldNoLabel from '../InputFieldNoLabel';

// Styles
import formStyles from '../Form.module.scss';

// Data
import { useMutation, useQueryClient } from 'react-query';
import instance from 'axiosInstance';

const submitBilledTime = async (variables) => {
  const params = new URLSearchParams();
  params.append('billed', variables.billed);
  params.append('period', variables.billingPeriodValue);

  const response = await instance.post(
    '/private/admin/project-components/billed/' + variables.id,
    params
  );
  return response.data;
};

const BilledForm = ({ id, billed }) => {
  const queryClient = useQueryClient();
  const { globalDispatch } = useGlobalContext();
  const {
    state: { billingPeriod },
  } = useTabContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { billed } });

  const { mutate } = useMutation(submitBilledTime, {
    mutationKey: 'billed',
    onSuccess: () => {
      queryClient.invalidateQueries(['project_components']);

      globalDispatch({
        type: 'pushNotification',
        payload: { type: 'success', text: 'Billed hours updated!' },
      });
    },
    onError: () => {
      globalDispatch({
        type: 'pushNotification',
        payload: { type: 'error', text: 'Something went wrong :(' },
      });
    },
  });

  const debounceSubmit = useAsyncDebounce(() => {
    handleSubmit(onSubmit)();
  }, 400);

  const onSubmit = (data) => {
    data.id = id;
    data.billingPeriodValue = billingPeriod.value;

    if (billed !== data.billed) {
      mutate(data);
    }
  };

  const { name, onChange, onBlur, ref } = register('billed', {
    required: 'Required',
    valueAsNumber: true,
    min: {
      value: 0,
      message: "Value shouldn't be less than a 0",
    },
    pattern: {
      value: /^\d+(?:\.\d{1,2})?$/,
      message: "Value shouldn't have more than 2 numbers after decimal",
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputFieldNoLabel
        type="number"
        errors={errors.billed}
        wrapperClassName={formStyles['in-table-wrapper']}
        name={name}
        onBlur={onBlur}
        ref={ref}
        onChange={(e) => {
          onChange(e);
          debounceSubmit();
        }}
      />
    </form>
  );
};

BilledForm.propTypes = {
  id: PropTypes.number,
  billed: PropTypes.number,
};

export default BilledForm;
