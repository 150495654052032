import React from 'react';
import { useTabContext } from 'GlobalState/Context/TabContext';

import Select from './Select';

import generateWeeks from 'utils/weekDays';
import { getCurrentWeek } from 'utils/weekDays';

const WeekFilter = () => {
  const options = generateWeeks();

  const { dispatch } = useTabContext();

  const handleChange = (e) => {
    dispatch({ type: 'setCurrentWeek', payload: e });
    const monday = new Date(e.value.split(' ')[0]);

    dispatch({ type: 'setActiveDay', payload: monday });
  };

  return (
    <Select
      options={options}
      placeholder="Filter by week"
      onChange={handleChange}
      defaultValue={getCurrentWeek()}
      isSearchable={false}
    />
  );
};

export default WeekFilter;
