import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';
import Select from './Select';

import { useQuery } from 'react-query';
import instance from 'axiosInstance';

const getProjectNames = async () => {
  const response = await instance.get('/private/projects-names');
  return response.data;
};

const ProjectSelect = ({ control }) => {
  const { data } = useQuery('projects-names', getProjectNames);

  const options = useMemo(
    () =>
      data.map((project) => ({
        label: project.projectName,
        value: project.id,
      })),
    [data]
  );

  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'project',
    control,
    rules: { required: '*Project is required' },
  });

  return (
    <Select placeholder="Project" error={error} options={options} {...field} />
  );
};

ProjectSelect.propTypes = {
  control: PropTypes.object,
};

export default ProjectSelect;

export const ProjectSelectExternalState = ({ value, setterFunction }) => {
  const { data } = useQuery('projects-names', getProjectNames);

  const options = useMemo(
    () =>
      data.map((project) => ({
        label: project.projectName,
        value: project.id,
      })),
    [data]
  );

  return (
    <Select
      placeholder="Project"
      options={[{ value: null, label: 'All Projects' }, ...options]}
      value={value}
      onChange={(e) => setterFunction(e)}
    />
  );
};

ProjectSelectExternalState.propTypes = {
  value: PropTypes.object,
  setterFunction: PropTypes.func,
};
