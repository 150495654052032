import React from 'react';

import styles from './Logo.module.scss';

const Logo = () => {
  return (
    <span className={styles['logo']}>
      <svg
        width="38"
        height="38"
        viewBox="0 0 950 950"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="596.086"
          y="-151.022"
          width="209.523"
          height="676.026"
          transform="rotate(45 596.086 -151.022)"
          fill={`var(--theme-color-accent)`}
        />
        <rect
          x="-213.07"
          y="292.178"
          width="209.523"
          height="719.005"
          transform="rotate(-45 -213.07 292.178)"
          fill={`var(--theme-color-accent)`}
        />
        <rect
          width="209.523"
          height="676.026"
          transform="matrix(0.707107 0.707107 0.707107 -0.707107 206.068 953.178)"
          fill={`var(--theme-color-accent-dark)`}
        />
        <rect
          width="209.523"
          height="719.005"
          transform="matrix(-0.707107 0.707107 0.707107 0.707107 654.967 149.721)"
          fill={`var(--theme-color-accent-dark)`}
        />
      </svg>

      <span>Time Tracker</span>
    </span>
  );
};

export default Logo;
