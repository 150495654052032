import React from 'react';
import PropTypes from 'prop-types';
import { useTabContext } from 'GlobalState/Context/TabContext';

// UI components
import WorkDayBox from './WorkDayBox';

// data
import { useQuery } from 'react-query';
import instance from 'axiosInstance';

// Styles
import cx from 'classnames';
import styles from './WorkedHoursPerDay.module.scss';

const WorkedHoursPerDayUI = ({ data }) => {
  let totalHours = 0;

  const workedDays = Object.keys(data).map((date, key) => {
    totalHours += Number(data[date]);

    return <WorkDayBox key={key} date={date} hours={data[date]} index={key} />;
  });

  return (
    <div className={cx(styles['week-calendar'])}>
      {workedDays}
      <div className={cx(styles.box, styles['box-total'])}>
        <span className={styles['box-title']}>Total</span>
        <span className={styles['hours']}>{totalHours.toFixed(2)}h</span>
      </div>
    </div>
  );
};

WorkedHoursPerDayUI.propTypes = {
  data: PropTypes.object,
};

const getCurrentWeekHours = async ({ queryKey }) => {
  const [startDate, endDate] = queryKey[1].split(' ');
  const response = await instance.get(
    `/private/employees-reports/weekly-hours?start_date=${startDate}&end_date=${endDate}`
  );
  return response.data;
};

const WorkedHoursPerDay = () => {
  const {
    state: { currentWeek },
  } = useTabContext();

  const { data } = useQuery(
    ['week-hours', currentWeek.value],
    getCurrentWeekHours
  );

  return <WorkedHoursPerDayUI data={data} />;
};

export default WorkedHoursPerDay;
