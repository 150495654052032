import React from 'react';

const CloseBoldSolid = (props) => {
  return (
    <svg
      viewBox="0 0 44 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 0.105713C10.5703 0.105713 1.375 9.38697 1.375 20.7307C1.375 32.1604 10.5703 41.3557 22 41.3557C33.3437 41.3557 42.625 32.1604 42.625 20.7307C42.625 9.38697 33.3437 0.105713 22 0.105713ZM28.875 24.8557C29.7344 25.6291 29.7344 26.9181 28.875 27.6917C28.1016 28.551 26.8126 28.551 26.039 27.6917L22 23.6527L17.875 27.6917C17.1016 28.551 15.8125 28.551 15.0391 27.6917C14.1797 26.9181 14.1797 25.6291 15.0391 24.8557L19.0781 20.7307L15.0391 16.6916C14.1797 15.9182 14.1797 14.6291 15.0391 13.8557C15.8125 12.9963 17.1016 12.9963 17.875 13.8557L22 17.8947L26.039 13.8557C26.8126 12.9963 28.1016 12.9963 28.875 13.8557C29.7344 14.6291 29.7344 15.9182 28.875 16.6916L24.836 20.7307L28.875 24.8557Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloseBoldSolid;
