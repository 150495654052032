import React, { useState } from 'react';

import styles from './Profile.module.scss';
import Thumbnail from 'components/Thumbnail/Thumbnail';
import ProfilePopup from '../ProfilePopup/ProfilePopup';

import { useQuery } from 'react-query';
import instance from 'axiosInstance';

const getUserInformation = async () => {
  const response = await instance.get('/private/employee-information');
  return response.data;
};

const Profile = () => {
  const [isVisible, setIsVisible] = useState(false);

  const { data } = useQuery(['user-information', null], getUserInformation);

  const avatarImage = (
    <>
      <div
        className={styles['profile-wrapper']}
        onClick={() => setIsVisible(!isVisible)}
      >
        <Thumbnail
          customHeight="2.75em"
          customWidth="2.75em"
          src={data.imageUrl || '/dixy.png'}
          alt={`Profile of ${data.fullName}`}
          borderRadius="var(--app-general-border-radius-larger)"
          additionalStyles={styles['profile-thumbnail']}
        />
      </div>

      <ProfilePopup
        userName={data.fullName}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
    </>
  );

  return (
    <div className={styles['profile']}>
      <span className={styles['name']}>
        Hi, <strong>{data.fullName}</strong>
      </span>
      {avatarImage}
    </div>
  );
};

export default Profile;
