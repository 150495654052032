import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useTabContext } from 'GlobalState/Context/TabContext';

import { CalendarMonthFilter } from 'components/Form/Selects/BillingReportFilter';

// import ErrorTriangleSolid from 'components/Icons/ErrorTriangleSolid';

// Styles
import cx from 'classnames';
import styles from './WorkCalendar.module.scss';

import { useQuery } from 'react-query';
import instance from 'axiosInstance';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import SkeletonCustomField from 'components/LoadingSkeletons/SkeletonCustomField/SkeletonCustomField';
import SkeletonLoadingField from 'components/LoadingSkeletons/SkeletonLoadingField/SkeletonLoadingField';

const getWorkCallendar = async ({ queryKey }) => {
  const response = await instance.get(
    '/private/employee-hours-month?period=' +
      queryKey[2] +
      (queryKey[1] ? '&slug=' + queryKey[1] : '')
  );

  return response.data;
};

// Todo preloader for WorkDays
const WorkDays = () => {
  const { slug } = useParams();
  const {
    state: { calendarPeriod },
  } = useTabContext();

  const { data } = useQuery(
    ['callendar', slug, calendarPeriod.value],
    getWorkCallendar
  );

  return data.map((value, key) => (
    <span
      key={key}
      className={cx(
        styles['day-item'],
        styles['body-day-item'],
        'flex-row',
        'centered-horizontally',
        'centered-vertically',
        { [styles['danger']]: value < 7 && value !== null }
      )}
    >
      {value !== null ? `${value}h` : ''}
    </span>
  ));
};

const SkeletonWorkCalendarDays = () => {
  const daysNumber = 35;
  const boxes = [];

  for (let i = 0; i < daysNumber; i++) {
    boxes.push(
      <span
        key={i}
        className={cx(
          styles['day-item-skeleton'],
          styles['day-item'],
          styles['body-day-item'],
          'flex-row',
          'centered-horizontally',
          'centered-vertically'
        )}
      >
        <SkeletonCustomField
          width="40px"
          height="18px"
          backgroundColor="var(--field-skeleton-background-color)"
          backgroundShineColor="var(--field-skeleton-shine-background-color)"
        />
      </span>
    );
  }

  return boxes;
};

const WorkCalendar = () => {
  const {
    state: { calendarPeriod },
  } = useTabContext();

  const headerDays = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

  const weekHeaderDays = headerDays.map((day, index) => {
    return (
      <span
        className={cx(styles['day-item'], styles['header-day-item'])}
        key={index}
      >
        {day}
      </span>
    );
  });

  return (
    <div className={styles['work-calendar']}>
      <div className={styles['calendar-header']}>
        <h2 className={styles.title}>{calendarPeriod.label}</h2>
        <ErrorBoundary>
          <Suspense
            fallback={
              <SkeletonLoadingField
                additionalInlineStyles={{ maxWidth: '200px', height: '46px' }}
              />
            }
          >
            <CalendarMonthFilter />
          </Suspense>
        </ErrorBoundary>
      </div>
      <div className={styles['calendar-inner-wrapper']}>
        <div className={cx(styles['header-days'], 'flex-row')}>
          {weekHeaderDays}
        </div>
        <div className={cx(styles['body-days'])}>
          <ErrorBoundary>
            <Suspense fallback={<SkeletonWorkCalendarDays />}>
              <WorkDays />
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
      {/* <div className={styles['calendar-footer']}>
        <p className={cx(styles.excerpt, styles.description)}>
          Click on any day above to see it&apos;s full details. If a box is
          empty, it means it&apos;s a day in the future. All non-filled days are
          in red.
        </p>
        <div className={styles.separator}></div>
        <div
          className={cx(
            'flex-row',
            'centered-vertically',
            styles['excerpt-wrapper']
          )}
        >
          <ErrorTriangleSolid
            width="18px"
            height="16px"
            color="var(--theme-color-danger-dark)"
          />
          <p className={cx(styles.excerpt, styles['errors-description'])}>
            A day has zero hours reported! Click on it to take action.
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default WorkCalendar;
