import React from 'react';

const MenuExpandWrapper = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5V15C20 17.7614 17.7614 20 15 20H5C2.23858 20 0 17.7614 0 15V5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MenuExpandWrapper;

export const MenuExpandArrows = (props) => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 1L11 4L8 7" stroke="white" />
      <path d="M4 7L1 4L4 1" stroke="white" />
    </svg>
  );
};
