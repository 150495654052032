import React from 'react';
import { useParams, Outlet } from 'react-router-dom';

import AppTabs from 'components/AppTabs/AppTabs';

const SingleMember = () => {
  const { slug } = useParams();
  const pageTabs = [
    {
      to: `/member/${slug}`,
      text: 'Calendar and Table',
    },
    {
      to: `/member/${slug}/tables`,
      text: 'Table for each day in the month',
    },
  ];
  return (
    <>
      <AppTabs links={pageTabs} />
      <div className="app-body-content">
        <Outlet />
      </div>
    </>
  );
};

export default SingleMember;
