import React, { useEffect, memo, Suspense } from 'react';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';
import PropTypes from 'prop-types';

// Form
import { useForm } from 'react-hook-form';
import TaskUrlField from '../TaskUrlField';
import InputField from 'components/Form/InputField';
import DateField from 'components/Form/DateField';
import ProjectSelect from 'components/Form/Selects/ProjectsSelect';
import ComponentSelect from 'components/Form/Selects/ComponentSelect';
import SubmitButton from 'components/Form/SubmitButton';
import TextAreadField from 'components/Form/TextAreaField';
import SkeletonLoadingField from 'components/LoadingSkeletons/SkeletonLoadingField/SkeletonLoadingField';

// Styles
import formStyles from '../../Form.module.scss';
import cx from 'classnames';

// UX
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import Button from 'components/Button/Button';

// utils
import parseYearMonthDate, { getLowestEditDate } from 'utils/dateFunctions';

// Data
import { useMutation, useQueryClient } from 'react-query';
import instance from 'axiosInstance';

const mutationFunction = async (variables) => {
  const params = new URLSearchParams();
  params.append('project_id', variables.project.value);
  params.append('is_billable', true);
  params.append('component_id', variables.component.value);
  params.append(
    'task_title',
    variables.taskTitle === 'Open Link' ? '' : variables.taskTitle
  );
  params.append('task_link', variables.taskUrl);
  params.append('task_description', variables.description);
  params.append('hours_spent', variables.hoursSpent);
  params.append(
    'created_on',
    parseYearMonthDate(new Date(variables.createdOn))
  );

  let response;

  if (variables.type === 'create') {
    response = await instance.post('private/employees-reports', params);
  } else {
    response = await instance.put(
      'private/employees-reports/' + variables.id,
      params
    );
  }

  return response.data;
};

const EditReportForm = ({ row, type, activeDay }) => {
  const { globalDispatch } = useGlobalContext();

  const queryClient = useQueryClient();

  const {
    id,
    project: { name: projectName, id: projectId } = { name: '', id: '' },
    component: { name: componentName, id: componentId } = { name: '', id: '' },
    description,
    hoursSpent,
    task: { title, link } = { title: '', link: '' },
    createdOn = activeDay,
  } = row || '';

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      project: projectId ? { label: projectName, value: projectId } : null,
      component: componentId
        ? { label: componentName, value: componentId }
        : null,
      description,
      hoursSpent: Number(hoursSpent),
      taskUrl: link === null ? '' : link,
      taskTitle: title === null ? '' : title,
      createdOn,
    },
  });

  const project = watch('project');

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'project' && value) {
        setValue('component', null);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const { mutate } = useMutation(mutationFunction, {
    mutationKey: `${type}-report`,
    onSuccess: () => {
      queryClient.invalidateQueries('my-reports');
      queryClient.invalidateQueries('week-hours');

      globalDispatch({ type: 'setModalContent', payload: null });

      globalDispatch({
        type: 'pushNotification',
        payload: {
          type: 'success',
          text:
            type === 'create' ? 'Report entry added!' : 'Report entry updated',
        },
      });
    },
    onError: ({ response }) => {
      globalDispatch({
        type: 'pushNotification',
        payload: {
          type: 'error',
          text: 'Something went wrong!',
        },
      });
    },
  });

  const onSubmit = (newData) => {
    newData.type = type;
    newData.id = id;
    newData.activeDay = activeDay;

    mutate(newData);
  };

  const handleRejection = () => {
    globalDispatch({ type: 'setModalContent', payload: null });
  };

  return (
    <div className={formStyles['edit-report-form']}>
      <h2 className={formStyles['form-title']}>
        {type === 'create' ? 'Create' : 'Edit'} Report
      </h2>
      <form
        className={formStyles['form-wrapper']}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['width-medium']}`}
        >
          <label className={formStyles['form-input-label']}>
            Project
            <ErrorBoundary>
              <Suspense fallback={<SkeletonLoadingField />}>
                <ProjectSelect control={control} />
              </Suspense>
            </ErrorBoundary>
          </label>
        </div>
        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['width-medium']}`}
        >
          <label className={formStyles['form-input-label']}>
            Component
            <ErrorBoundary>
              <Suspense fallback={<SkeletonLoadingField />}>
                <ComponentSelect control={control} projectId={project?.value} />
              </Suspense>
            </ErrorBoundary>
          </label>
        </div>
        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['width-small']}`}
        >
          <label
            className={`${formStyles['form-input-label']}  ${formStyles['form-input-label-url']}`}
          >
            Task URL
            <TaskUrlField
              setValue={setValue}
              setError={setError}
              control={control}
              clearErrors={clearErrors}
              getValues={getValues}
            />
          </label>
        </div>
        <InputField
          wrapperClassName={`${formStyles['form-input-wrapper']} ${formStyles['width-small']}`}
          placeholder="Time"
          type="number"
          errors={errors.hoursSpent}
          label="Time to complete"
          {...register('hoursSpent', {
            required: '*Hours spent are required',
            min: { value: 0, message: 'Time cannot be negative' },
            validate: {
              timeFormat: (v) =>
                (v % 0.25 === 0 && v !== 0) ||
                'You must pick a valid time. Ex: 1, 1.25, 2.75',
            },
          })}
        />

        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['width-small']}`}
        >
          <label
            className={cx(formStyles['form-input-label'], {
              [formStyles['field-error-wrapper']]: errors.createdOn,
            })}
          >
            Date Reported
            <DateField
              control={control}
              inputName="createdOn"
              rules={{
                validate: (value) => {
                  const today = new Date();
                  today.setHours(0, 0, 0, 0);

                  const lowestDate = getLowestEditDate();
                  lowestDate.setHours(0, 0, 0, 0);

                  if (value < lowestDate) {
                    return 'This date is already closed';
                  }

                  if (value > new Date()) {
                    return 'This date is in the future';
                  }

                  return true;
                },
                valueAsDate: true,
              }}
            />
            {errors.createdOn && (
              <span className={formStyles['error-message']}>
                {errors.createdOn.message}
              </span>
            )}
          </label>
        </div>

        <TextAreadField
          wrapperClassName={`${formStyles['form-input-wrapper']} ${formStyles['form-input-description']} ${formStyles['width-large']}`}
          rows="3"
          cols="50"
          errors={errors.description}
          label="Description"
          {...register('description', {
            required: '*Required! The descriptions helps us form the reports',
          })}
        />

        <div className={formStyles['form-actions-wrapper']}>
          <Button
            clicked={handleRejection}
            type="button-primary-inline-adaptive"
            text="Cancel"
          />
          <SubmitButton
            type="button-success"
            additionalStyles={formStyles['edit-report-submit']}
            text="Save"
          />
        </div>
      </form>
    </div>
  );
};

EditReportForm.propTypes = {
  row: PropTypes.object,
  type: PropTypes.string,
  activeDay: PropTypes.object,
};

export default memo(EditReportForm);
