import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import cx from 'classnames';
import styles from './BoxBasic.module.scss';

const BoxBasic = ({ link, children, hasBottomSpace }) => {
  // Basically wrap the element as a link if it's provided.
  const boxElement = link ? (
    <Link
      to={link}
      className={cx(styles.box, hasBottomSpace ? styles['bottom-gap'] : '')}
    >
      {children}
    </Link>
  ) : (
    <div className={cx(styles.box, hasBottomSpace ? styles['bottom-gap'] : '')}>
      {children}
    </div>
  );

  return boxElement;
};

BoxBasic.propTypes = {
  link: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hasBottomSpace: PropTypes.bool,
};

export default BoxBasic;
