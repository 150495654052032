import React, { Suspense } from 'react';

import Card from 'components/Card/Card';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import SkeletonUserCard from 'components/LoadingSkeletons/SkeletonUserCard/SkeletonUserCard';
import UserInformationPublic from 'components/UserInformationOverview/UserInformationPublic';
import styles from '../Home/TabStatistics/TabStatistics.module.scss';

const SingleMemberPublic = () => {
  return (
    <Card additionalStyles={styles['user-information']}>
      <ErrorBoundary>
        <Suspense fallback={<SkeletonUserCard />}>
          <UserInformationPublic />
        </Suspense>
      </ErrorBoundary>
    </Card>
  );
};

export default SingleMemberPublic;
