import React, { memo, Suspense } from 'react';
import PropTypes from 'prop-types';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Form
import { useForm } from 'react-hook-form';
import DateField from 'components/Form/DateField';
import InputField from 'components/Form/InputField';
import Checkbox from 'components/Checkbox/Checkbox';
import Button from 'components/Button/Button';
import SubmitButton from 'components/Form/SubmitButton';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import PositionsCombobox from 'components/Form/Selects/PositionsSelect';
import SkeletonLoadingField from 'components/LoadingSkeletons/SkeletonLoadingField/SkeletonLoadingField';

// Styles
import formStyles from '../../Form.module.scss';

// Utils
import parseYearMonthDate from 'utils/dateFunctions';

// Data
import { useMutation, useQueryClient } from 'react-query';
import instance from 'axiosInstance';

const mutationFunction = async (variables) => {
  const params = new URLSearchParams();
  params.append('email', variables.email);
  params.append('full_name', variables.name);
  params.append('is_admin', variables.isAdmin);
  params.append('is_active', variables.isActive);
  params.append('position_id', variables.position.value);
  params.append(
    'date_of_start',
    parseYearMonthDate(new Date(variables.contractDay))
  );

  let response;
  if (variables.type === 'create') {
    response = await instance.post('/private/admin/employees', params);
  } else if (variables.type === 'edit') {
    response = await instance.put(
      '/private/admin/employees/' + variables.id,
      params
    );
  }
  return response.data;
};

const EmployeesForm = ({ heading, row, type }) => {
  const { globalDispatch } = useGlobalContext();

  const {
    id,
    email,
    employee: { name } = { name: '' },
    position = '',
    positionId,
    isActive = false,
    isAdmin = false,
    contractDay = new Date(),
  } = row || '';

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email,
      name,
      position: positionId ? { label: position, value: positionId } : null,
      contractDay,
      isActive,
      isAdmin,
    },
  });

  const queryClient = useQueryClient();

  const { mutate, error, isLoading } = useMutation(mutationFunction, {
    mutationKey: `${type}-employee`,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('employees');

      globalDispatch({ type: 'setModalContent', payload: null });

      globalDispatch({
        type: 'pushNotification',
        payload: {
          type: 'success',
          text: `Employee ${
            type === 'create' ? 'created' : 'edited'
          } successfully`,
        },
      });
    },
  });

  const onSubmit = (newData) => {
    newData.isActive = isActive;
    newData.type = type;
    newData.id = id;
    mutate(newData);
  };

  const handleRejection = () => {
    globalDispatch({ type: 'setModalContent', payload: null });
  };

  return (
    <div className={formStyles['edit-report-form']}>
      <h2 className={formStyles['form-title']}>{heading}</h2>
      <form
        className={formStyles['form-wrapper']}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputField
          placeholder="Email"
          type="email"
          wrapperClassName={`${formStyles['form-input-wrapper']} ${formStyles['width-medium']}`}
          errors={errors.email}
          serverErrors={error?.response}
          label="Email"
          {...register('email', {
            required: '*Email is required',
            validate: (value) =>
              value.endsWith('@devrix.com') || 'Use a DevriX email',
          })}
        />
        <InputField
          placeholder="Name"
          wrapperClassName={`${formStyles['form-input-wrapper']} ${formStyles['width-medium']}`}
          errors={errors.name}
          label="Name"
          {...register('name', {
            required: '*Name is required',
            pattern: {
              value: /^[a-zA-Z\s]+$/,
              message: 'Only latin letters and spaces are allowed',
            },
          })}
        />
        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['width-custom-large']}`}
        >
          <label className={formStyles['form-input-label']}>
            Position
            <ErrorBoundary>
              <Suspense fallback={<SkeletonLoadingField />}>
                <PositionsCombobox control={control} />
              </Suspense>
            </ErrorBoundary>
          </label>
        </div>

        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['width-small']}`}
        >
          <label className={formStyles['form-input-label']}>
            Start date
            <DateField control={control} inputName="contractDay" />
          </label>
        </div>

        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['width-medium']}`}
        >
          <label className={formStyles['form-input-label']}>
            <Checkbox
              label="Admin Priviliges"
              control={control}
              inputName="isAdmin"
            />
          </label>
        </div>

        <div className={formStyles['form-actions-wrapper']}>
          <Button
            clicked={handleRejection}
            type="button-primary-inline-adaptive"
            text="Cancel"
          />
          <SubmitButton
            type="button-success"
            additionalStyles={formStyles['edit-report-submit']}
            text="Save"
            disabled={isLoading}
          />
        </div>
      </form>
    </div>
  );
};

EmployeesForm.propTypes = {
  row: PropTypes.object,
  heading: PropTypes.string,
  setModalContent: PropTypes.func,
  type: PropTypes.string,
};

export default memo(EmployeesForm);
