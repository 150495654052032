import React from 'react';
import { createRoot } from 'react-dom/client';

import App from 'views/App/App';
import GlobalContext from 'GlobalState/Context/GlobalContext';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import './styles';

const container = document.getElementById('root');
const root = createRoot(container);

const client = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <GlobalContext>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
        <ReactQueryDevtools initialIsOpen={false} />
      </GlobalContext>
    </QueryClientProvider>
  </React.StrictMode>
);
