import React from 'react';

import PropTypes from 'prop-types';

import styles from './ProgressCircle.module.scss';

const ProgressCircle = ({ progress, text }) => {
  const background =
    'conic-gradient(var(--theme-color-primary-light) ' +
    progress +
    '%,var(--theme-separator-color) ' +
    progress +
    '%)';
  return (
    <>
      <div className={styles['middle-circle']}>{text}</div>
      <div style={{ background }} className={styles['progress']}></div>
    </>
  );
};

ProgressCircle.propTypes = {
  progress: PropTypes.number,
  text: PropTypes.string,
};

export default ProgressCircle;
