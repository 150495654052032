import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import styles from './ButtonDropdown.module.scss';
import stylesButton from '../Button.module.scss';
import ArrowDown from 'components/Icons/Arrow-Down';

const ButtonDropdown = ({
  text,
  type,
  additionalStyles,
  additionalInlineStyles,
  dropdownItems,
}) => {
  const links = dropdownItems.map((item, i) => (
    <span
      key={i}
      onClick={item.click}
      className={cx(styles['dropdown-item'], styles[type])}
    >
      {item.title}
    </span>
  ));

  return (
    <div className={cx(styles['button-dropdown'])}>
      <span
        className={cx(
          stylesButton.button,
          stylesButton[type],
          additionalStyles
        )}
        style={additionalInlineStyles}
      >
        {text}
        <ArrowDown width="0.625em" />
      </span>
      <div className={styles['dropdown-menu']}>{links}</div>
    </div>
  );
};

// additionalStyles is to add new class
// additionalInlineStyles is to add new css to override the initial styles of the button
// type is used to set the design type of the button

ButtonDropdown.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  additionalStyles: PropTypes.string,
  additionalInlineStyles: PropTypes.string,
  dropdownItems: PropTypes.array,
};

export default ButtonDropdown;
