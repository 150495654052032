import React, { memo } from 'react';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Form
import { useForm } from 'react-hook-form';
import SubmitButton from '../SubmitButton';
import Button from 'components/Button/Button';
import PasswordField from '../PasswordField';

// Styles
import formStyles from '../Form.module.scss';

import { useMutation } from 'react-query';
import instance from 'axiosInstance';

const mutationFunction = async (variables) => {
  const params = new URLSearchParams();
  params.append('old_password', variables.currentPassword);
  params.append('password', variables.newPassword);
  params.append('password_confirm', variables.repeatPassword);

  const response = await instance.post('/private/change-password', params);

  return response.data;
};

const ChangePasswordForm = () => {
  const { globalDispatch } = useGlobalContext();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const { error, mutate, isLoading } = useMutation(mutationFunction, {
    mutationKey: `change-password`,
    onSuccess: () => {
      globalDispatch({ type: 'setModalContent', payload: null });

      globalDispatch({
        type: 'pushNotification',
        payload: { type: 'success', text: 'Password changed successfully!' },
      });
    },
    onError: ({ response }) => {
      globalDispatch({
        type: 'pushNotification',
        payload: { type: 'error', text: 'Something went wrong' },
      });
    },
  });

  const onSubmit = (newData) => {
    mutate(newData);
  };

  const handleRejection = () => {
    globalDispatch({ type: 'setModalContent', payload: null });
  };

  return (
    <div className={formStyles['edit-report-form']}>
      <h2 className={formStyles['form-title']}>Change Password</h2>
      <form
        className={formStyles['form-wrapper']}
        onSubmit={handleSubmit(onSubmit)}
      >
        <PasswordField
          placeholder="Current Password"
          wrapperClassName={`${formStyles['form-input-wrapper']} ${formStyles['width-large']}`}
          errors={errors.currentPassword}
          serverError={error?.response}
          autofocus
          label="Current Password"
          {...register('currentPassword', {
            required: '*Current Password is required!',
          })}
        />

        <PasswordField
          placeholder="New Password"
          wrapperClassName={`${formStyles['form-input-wrapper']} ${formStyles['width-large']}`}
          errors={errors.newPassword}
          label="New Password"
          {...register('newPassword', {
            required: '*New Password is required',
          })}
        />

        <PasswordField
          placeholder="Repeat Password"
          wrapperClassName={`${formStyles['form-input-wrapper']} ${formStyles['width-large']}`}
          errors={errors.repeatPassword}
          serverErrors={error?.response}
          label="Repeat Password"
          {...register('repeatPassword', {
            required: '*Repeat Password is required!',
            validate: (value) =>
              value === getValues('newPassword') ||
              'Passwords are not matching',
          })}
        />

        <div className={formStyles['form-actions-wrapper']}>
          <Button
            clicked={handleRejection}
            type="button-primary-inline-adaptive"
            text="Cancel"
          />
          <SubmitButton
            type="button-success"
            additionalStyles={formStyles['edit-report-submit']}
            text="Save"
            disabled={isLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default memo(ChangePasswordForm);
