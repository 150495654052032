import React from 'react';

import PageHeader from 'components/PageHeader/PageHeader';

/**
 *
 * @return {JSX}
 */
export default function NoMatch() {
  return (
    <div className="app-body-content">
      <PageHeader title="Nothing found here..." />
      <p>A prettier 404 is baking :)</p>
    </div>
  );
}
