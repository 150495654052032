import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Icons
import CheckBoldSolid from 'components/Icons/CheckBoldSolid';
import CloseBoldSolid from 'components/Icons/CloseBoldSolid';
import InfoBoldSolid from 'components/Icons/InfoBoldSolid';
import WarningBoldSolid from 'components/Icons/WarningBoldSolid';

// Styles
import cx from 'classnames';
import styles from './NotificationPopup.module.scss';
import CloseThin from 'components/Icons/CloseThin';

const NotificationPopup = ({ type, text }) => {
  let typeMessage;
  let typeMessageIcon;

  const [isActive, setIsActive] = useState(true);

  const handleNotificationClose = () => {
    setIsActive(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsActive(false);
    }, 1000);
  }, []);

  switch (type) {
    case 'success':
      typeMessageIcon = (
        <CheckBoldSolid
          width="26px"
          height="26px"
          color="var(--app-general-success-dark-custom)"
        />
      );
      typeMessage = 'Success!';
      break;

    case 'error':
      typeMessageIcon = (
        <CloseBoldSolid
          width="26px"
          height="26px"
          color="var(--app-general-danger-dark-custom)"
        />
      );
      typeMessage = 'Error!';
      break;
    case 'warning':
      typeMessageIcon = (
        <WarningBoldSolid
          width="26px"
          height="26px"
          color="var(--app-general-warning-dark-custom)"
        />
      );
      typeMessage = 'Warning!';
      break;
    case 'info':
      typeMessageIcon = (
        <InfoBoldSolid
          width="26px"
          height="26px"
          color="var(--app-general-info-dark-custom)"
        />
      );
      typeMessage = 'Informational!';
      break;

    default:
      typeMessageIcon = (
        <CheckBoldSolid
          width="26px"
          height="26px"
          color="var(--app-general-success-dark-custom)"
        />
      );
      typeMessage = 'Success!';
      break;
  }

  return (
    <div
      className={cx(
        styles['notification-popup'],
        'flex-row',
        'centered-vertically',
        styles[type],
        isActive ? styles.active : styles.hide
      )}
    >
      <span className={styles['notification-type-icon']}>
        {typeMessageIcon}
      </span>
      <p className={styles.description}>
        <span className={styles['description-bold']}>{typeMessage}</span>
        {text}
      </p>
      <span className={styles.close} onClick={handleNotificationClose}>
        <CloseThin width="12px" height="12px" />
      </span>
    </div>
  );
};

NotificationPopup.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
};

export default NotificationPopup;
