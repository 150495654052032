import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import ErrorSolid from 'components/Icons/Error-solid';

import formStyles from './Form.module.scss';

const InputFieldNoLabel = forwardRef(
  (
    {
      placeholder,
      wrapperClassName,
      type = 'text',
      errors,
      autofocus,
      serverError,
      ...register
    },
    ref
  ) => {
    return (
      <div className={wrapperClassName}>
        <input
          type={type}
          autoFocus={autofocus}
          placeholder={placeholder}
          {...register}
          ref={ref}
          step="any"
          className={errors || serverError ? formStyles['field-error'] : ''}
        />
        {(errors || serverError) && (
          <span className={formStyles['error-icon']}>
            <ErrorSolid
              width="20"
              height="20"
              color={`var(--theme-color-danger-dark)`}
            />
          </span>
        )}
        {errors && (
          <span className={formStyles['error-message']}>{errors.message}</span>
        )}
        {serverError && (
          <span className={formStyles['error-message']}>{serverError}</span>
        )}
      </div>
    );
  }
);

InputFieldNoLabel.displayName = 'InputFieldNoLabel';

InputFieldNoLabel.propTypes = {
  placeholder: PropTypes.string,
  serverError: PropTypes.string,
  autofocus: PropTypes.bool,
  type: PropTypes.string,
  errors: PropTypes.object,
  wrapperClassName: PropTypes.string,
};

export default InputFieldNoLabel;
