import React from 'react';
import PropTypes from 'prop-types';

import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

import Button from 'components/Button/Button';

// Styling
import styles from './DeletePrompt.module.scss';

import { useMutation, useQueryClient } from 'react-query';
import instance from 'axiosInstance';

const DeletePrompt = ({
  endpoint,
  queriesToInvalidate,
  heading,
  additionalInformation,
}) => {
  const queryClient = useQueryClient();

  const { globalDispatch } = useGlobalContext();

  const deleteItem = async () => {
    const response = await instance.delete(endpoint);

    return response.data;
  };

  const { mutate } = useMutation(deleteItem, {
    mutationKey: `delete-entry`,
    onSuccess: () => {
      queriesToInvalidate.map((query) => {
        queryClient.invalidateQueries(query);
      });

      globalDispatch({
        type: 'pushNotification',
        payload: {
          type: 'success',
          text: 'Deleted Successfully!',
        },
      });
    },
  });
  const handleConfirmation = () => {
    mutate();
    globalDispatch({ type: 'setModalContent', payload: null });
  };

  const handleRejection = () => {
    globalDispatch({ type: 'setModalContent', payload: null });
  };

  return (
    <div className={styles['delete-report-form']}>
      <h2 className={styles['form-title']}>
        Are you sure you want to delete {heading}
      </h2>
      <p className={styles['form-description']}>{additionalInformation}</p>

      <div className={styles['form-actions-wrapper']}>
        <Button
          clicked={handleRejection}
          type="button-primary-inline-adaptive"
          text="Cancel"
        />

        <Button
          clicked={handleConfirmation}
          type="button-danger-dark"
          additionalStyles={styles['delete-report-submit']}
          text="Delete"
        />
      </div>
    </div>
  );
};

DeletePrompt.propTypes = {
  id: PropTypes.string,
  endpoint: PropTypes.string,
  heading: PropTypes.string,
  queriesToInvalidate: PropTypes.array,
  additionalInformation: PropTypes.string,
};
export default DeletePrompt;
