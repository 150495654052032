/**
 *
 * @param {number} timeInSeconds // Time in second
 * @return {number} Hours in format 1, 1.25 1.5, 1.75
 */
export const transformSecondsToHours = (timeInSeconds) => {
  let hours = Math.floor(timeInSeconds / 3600);
  let minutes = Math.floor(timeInSeconds / 60) % 60;
  if (minutes <= 7) {
    minutes = 0;
  } else if (minutes > 7 && minutes <= 22) {
    minutes = 0.25;
  } else if (minutes > 22 && minutes <= 37) {
    minutes = 0.5;
  } else if (minutes > 37 && minutes <= 52) {
    minutes = 0.75;
  } else if (minutes > 52) {
    minutes = 0;
    hours += 1;
  }
  return hours + minutes;
};
