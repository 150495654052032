import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import DataOverview from './DataOverview/DataOverview';

// Styling
import cx from 'classnames';
import styles from './UserOverview.module.scss';
import Thumbnail from 'components/Thumbnail/Thumbnail';

const UserOverview = ({ data }) => {
  return (
    <div className={styles['user-overview-wrapper']}>
      <div
        className={cx(
          styles['user-overview-header'],
          'flex-row',
          'separated-content'
        )}
      >
        <div className={styles['user-information-wrapper']}>
          <div className={styles['user-avatar-wrapper']}>
            <Thumbnail
              src={data.employee.imageUrl}
              customHeight="6.25em"
              customWidth="6.25em"
              borderRadius="var(--app-general-border-radius-larger)"
              additionalStyles={styles['user-avatar']}
            />
          </div>
          <div className={styles['user-info']}>
            <h1 className={styles['user-name']}>{data.employee.name}</h1>
            <p className={styles['user-position']}>{data.employee.position}</p>
          </div>
        </div>
      </div>
      <div className={styles['user-overview-body']}>
        <div className={styles['user-overview-data-wrapper']}>
          <DataOverview
            title={'Most active project'}
            accent={
              <Link to={`/project/${data.mostActiveProject.slug}`}>
                {data.mostActiveProject.name}
              </Link>
            }
          />
          <DataOverview
            title="Longest Task"
            accent={`${data.longestTask.hoursSpent}h`}
          />
          <DataOverview title="Weekly hours" accent={`${data.weekHours}h`} />
          <DataOverview title="Monthly hours" accent={`${data.monthHours}h`} />
        </div>
      </div>
    </div>
  );
};

UserOverview.propTypes = {
  data: PropTypes.object,
};

export default UserOverview;
