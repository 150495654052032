import React from 'react';

const Email = (props) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M15.75 0H2.25C0.984375 0 0 1.01953 0 2.25V11.25C0 12.5156 0.984375 13.5 2.25 13.5H15.75C16.9805 13.5 18 12.5156 18 11.25V2.25C18 1.01953 16.9805 0 15.75 0ZM2.25 1.6875H15.75C16.0312 1.6875 16.3125 1.96875 16.3125 2.25V3.05859L10.4414 7.94531C9.63281 8.61328 8.33203 8.61328 7.52344 7.94531L1.6875 3.05859V2.25C1.6875 1.96875 1.93359 1.6875 2.25 1.6875ZM15.75 11.8125H2.25C1.93359 11.8125 1.6875 11.5664 1.6875 11.25V5.23828L6.46875 9.24609C7.17188 9.80859 8.05078 10.1602 9 10.1602C9.91406 10.1602 10.793 9.80859 11.4961 9.24609L16.3125 5.23828V11.25C16.3125 11.5664 16.0312 11.8125 15.75 11.8125Z"
      />
    </svg>
  );
};

export default Email;
