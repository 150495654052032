import React from 'react';
import { Outlet } from 'react-router-dom';

import AppTabs from 'components/AppTabs/AppTabs';

const pageTabs = [
  {
    to: '/manage/employees-positions',
    text: 'Employees and positions',
  },
  {
    to: '/manage/projects',
    text: 'Projects',
  },
  {
    to: '/manage/clients',
    text: 'Clients',
  },
];

const Manage = () => {
  return (
    <>
      <AppTabs links={pageTabs} />
      <div className="app-body-content">
        <Outlet />
      </div>
    </>
  );
};

export default Manage;
