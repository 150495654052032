import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import cx from 'classnames';
import styles from './MenuGroup.module.scss';

const MenuGroup = ({ menuItems, state, isExpanded, setIsActiveMobileMenu }) => {
  const menuListItems = menuItems.map((item) => (
    <li key={item.text} onClick={() => setIsActiveMobileMenu(false)}>
      <Link to={item.to} className={cx('flex-row', 'centered-vertically')}>
        <span
          className={cx(styles['item-icon'], 'flex-row', 'centered-vertically')}
        >
          {item.icon}
        </span>
        {isExpanded && <span className={styles['item-text']}>{item.text}</span>}
      </Link>
    </li>
  ));

  return (
    <div className={cx(styles.menu, styles[state])}>
      <ul className={styles.list}>{menuListItems}</ul>
    </div>
  );
};

MenuGroup.propTypes = {
  state: PropTypes.string,
  menuItems: PropTypes.array,
  isExpanded: PropTypes.bool,
  setIsActiveMobileMenu: PropTypes.func,
};

export default MenuGroup;
