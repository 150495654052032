import React from 'react';

const MobileMenuIcon = (props) => {
  return (
    <svg
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.2656 1.95312H0.234375C0.078125 1.95312 0 1.875 0 1.71875V0.78125C0 0.625 0.078125 0.546875 0.234375 0.546875H17.2656C17.4219 0.546875 17.5 0.625 17.5 0.78125V1.71875C17.5 1.875 17.4219 1.95312 17.2656 1.95312ZM17.2656 8.20312H0.234375C0.078125 8.20312 0 8.125 0 7.96875V7.03125C0 6.875 0.078125 6.79688 0.234375 6.79688H17.2656C17.4219 6.79688 17.5 6.875 17.5 7.03125V7.96875C17.5 8.125 17.4219 8.20312 17.2656 8.20312ZM17.2656 14.4531H0.234375C0.078125 14.4531 0 14.375 0 14.2188V13.2812C0 13.125 0.078125 13.0469 0.234375 13.0469H17.2656C17.4219 13.0469 17.5 13.125 17.5 13.2812V14.2188C17.5 14.375 17.4219 14.4531 17.2656 14.4531Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export default MobileMenuIcon;
