import React from 'react';

const ErrorSolid = (props) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.5 0C4.23542 0 0 4.275 0 9.5C0 14.7646 4.23542 19 9.5 19C14.725 19 19 14.7646 19 9.5C19 4.275 14.725 0 9.5 0ZM8.55 5.38333C8.55 4.86875 8.94583 4.43333 9.5 4.43333C10.0146 4.43333 10.45 4.86875 10.45 5.38333V10.45C10.45 11.0042 10.0146 11.4 9.5 11.4C8.94583 11.4 8.55 11.0042 8.55 10.45V5.38333ZM9.5 15.2C8.7875 15.2 8.23333 14.6458 8.23333 13.9729C8.23333 13.3 8.7875 12.7458 9.5 12.7458C10.1729 12.7458 10.7271 13.3 10.7271 13.9729C10.7271 14.6458 10.1729 15.2 9.5 15.2Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ErrorSolid;
