import React, { Suspense } from 'react';
import { useTabContext } from 'GlobalState/Context/TabContext';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Table
import TableMyReports from 'components/Tables/MyReports/TableMyReports';
import SkeletonTable from 'components/LoadingSkeletons/SkeletonTable/SkeletonTable';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import WorkedHoursPerDay from 'components/WorkedHoursPerDay/WorkedHoursPerDay';

// UI Components
import Card from 'components/Card/Card';
import WeekFilter from 'components/Form/Selects/WeekFilter';
import FlexContainer from 'components/FlexContainer/FlexContainer';
import SkeletonCustomField from 'components/LoadingSkeletons/SkeletonCustomField/SkeletonCustomField';
import SkeletonWorkedHoursPerDay from 'components/LoadingSkeletons/SkeletonWorkedHoursPerDay/SkeletonWorkedHoursPerDay';
import MobileAddButton from 'components/Button/MobileAddButton';

import EditReportForm from 'components/Form/MyReports/EditReportForm/EditReportForm';

// Utils
import { prettifyActiveDay } from 'utils/dateFunctions';

const TabHome = () => {
  // We can change between success, warning and danger depending on the hours.

  const {
    state: { currentWeek, activeDay },
  } = useTabContext();

  const { globalDispatch } = useGlobalContext();

  const handleAddEntry = () => {
    globalDispatch({
      type: 'setModalContent',
      payload: (
        <ErrorBoundary>
          <EditReportForm type="create" activeDay={activeDay} />
        </ErrorBoundary>
      ),
    });
  };

  return (
    <>
      <MobileAddButton onClick={handleAddEntry} />
      <Card
        title="My weekly activity"
        titleAccent={currentWeek.label}
        headerAdditional={
          <FlexContainer
            align="align-center"
            spaceX="children-x-10px"
            className={'weekly-select-holder'}
          >
            <span>Select week:</span>
            <ErrorBoundary>
              <Suspense
                fallback={
                  <SkeletonCustomField
                    width="322px"
                    height="40px"
                    backgroundColor="var(--field-skeleton-background-color)"
                    backgroundShineColor="var(--field-skeleton-shine-background-color)"
                    borderRadius="5px"
                  />
                }
              >
                <WeekFilter />
              </Suspense>
            </ErrorBoundary>
          </FlexContainer>
        }
        hasPadding={false}
      >
        <ErrorBoundary>
          <Suspense fallback={<SkeletonWorkedHoursPerDay />}>
            <WorkedHoursPerDay />
          </Suspense>
        </ErrorBoundary>
      </Card>

      <Card
        title="My tasks report"
        titleAccent={prettifyActiveDay(activeDay)}
        hasPadding={false}
      >
        <ErrorBoundary>
          <Suspense fallback={<SkeletonTable countTableBodyRows={2} />}>
            <TableMyReports />
          </Suspense>
        </ErrorBoundary>
      </Card>
    </>
  );
};

export default TabHome;
