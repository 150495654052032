import React, { Suspense, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Form
import ComponentsProjectForm from 'components/Form/SingleProject/ComponentsProjectForm';

// Table
import TableComponentsProject from 'components/Tables/TableSingleProject/TableComponentsProject';
import SkeletonTable from 'components/LoadingSkeletons/SkeletonTable/SkeletonTable';

// UI
import Card from 'components/Card/Card';
import FlexContainer from 'components/FlexContainer/FlexContainer';
import SearchFilter from 'components/Tables/SearchField';
import Button from 'components/Button/Button';

import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import ExternalStateCheckbox from 'components/Checkbox/ExternalStateCheckbox';

const ComponentsTab = () => {
  const { globalDispatch } = useGlobalContext();
  const [shouldShowInactive, setShouldShowInactive] = useState(false);
  const { slug } = useParams();
  const handleAddPopup = () => {
    globalDispatch({
      type: 'setModalContent',
      payload: (
        <Suspense fallback={<div>Loading</div>}>
          <ComponentsProjectForm
            heading="Add Component"
            type="create"
            slug={slug}
          />
        </Suspense>
      ),
    });
  };

  return (
    <>
      <Card
        title="Manage Project Components"
        leftAdditional={
          <label>
            Show Inactive
            <ExternalStateCheckbox
              setIsChecked={setShouldShowInactive}
              isChecked={shouldShowInactive}
            />
          </label>
        }
        headerAdditional={
          <FlexContainer spaceX="children-x-10px">
            <SearchFilter />
            <Button
              clicked={handleAddPopup}
              text="Add"
              type="button-primary"
              additionalInlineStyles={{
                minWidth: '6.25em',
                paddingTop: '0.375em',
                paddingBottom: '0.375em',
              }}
            />
          </FlexContainer>
        }
      >
        <ErrorBoundary>
          <Suspense fallback={<SkeletonTable countTableBodyRows={20} />}>
            <TableComponentsProject shouldShowInactive={shouldShowInactive} />
          </Suspense>
        </ErrorBoundary>
      </Card>
    </>
  );
};

export default ComponentsTab;
