import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Form
import { useForm } from 'react-hook-form';
import SubmitButton from '../SubmitButton';
import PasswordField from '../PasswordField';

// Styles
import formStyles from '../Form.module.scss';

import { useMutation } from 'react-query';
import instance from 'axiosInstance';

const mutationFunction = async (variables) => {
  const params = new URLSearchParams();
  params.append('token', variables.token);
  params.append('password', variables.password);
  params.append('password_confirm', variables.repeatPassword);

  const response = await instance.post('/public/change-password', params);

  return response.data;
};

const CreatePasswordForm = () => {
  const { globalDispatch } = useGlobalContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const { error, isLoading, mutate } = useMutation(mutationFunction, {
    mutationKey: `create-password`,
    onSuccess: () => {
      globalDispatch({
        type: 'pushNotification',
        payload: { type: 'success', text: 'Password changed successfully!' },
      });

      navigate('/');
    },
    onError: ({ response }) => {
      globalDispatch({
        type: 'pushNotification',
        payload: { type: 'error', text: 'Something went wrong' },
      });
    },
  });

  const onSubmit = (newData) => {
    newData.token = searchParams.get('token');
    mutate(newData);
  };

  return (
    <div className={formStyles['edit-report-form']}>
      <form
        className={formStyles['form-wrapper']}
        onSubmit={handleSubmit(onSubmit)}
      >
        <PasswordField
          placeholder="Please enter your new password"
          wrapperClassName={`${formStyles['form-input-wrapper']} ${formStyles['width-large']}`}
          errors={errors.password}
          label="Password"
          {...register('password', {
            required: '*Password is required',
          })}
        />

        <PasswordField
          placeholder="Please repeat your new password"
          wrapperClassName={`${formStyles['form-input-wrapper']} ${formStyles['width-large']}`}
          errors={errors.repeatPassword}
          serverErrors={error?.response}
          label="Repeat Password"
          {...register('repeatPassword', {
            required: '*Repeat Password is required!',
            validate: (value) =>
              value === getValues('password') || 'Passwords are not matching',
          })}
        />

        <SubmitButton
          type="button-success"
          additionalStyles={formStyles['sign-in-button']}
          text="Save"
          disabled={isLoading}
        />
      </form>
    </div>
  );
};

export default CreatePasswordForm;
