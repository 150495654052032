import React, { useMemo, Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTabContext } from 'GlobalState/Context/TabContext';

// Forms
const ClientsForm = React.lazy(() =>
  import('components/Form/Manage/ManageClientsForm/ClientsForm')
);

// Table
import { useTable, useSortBy, useFlexLayout, useFilters } from 'react-table';
import Label from 'components/Label/Label';
import Edit from 'components/Icons/Edit';
import Button from 'components/Button/Button';
import IsActiveCheckbox from 'components/Checkbox/IsActiveCheckbox';

// styles
import cx from 'classnames';
import tableStyles from '../../Tables.module.scss';
import styles from '../Manage.module.scss';

import { useQuery } from 'react-query';
import instance from 'axiosInstance';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';
/**
 * The one, the only
 *
 * This is the home page table and it's the most used component of the whole app
 * @see https://react-table.js.org/ - 90% of the stuff is there.
 * @return {JSX} My reports table
 */

// Todo: - Modal on top. Data changes on edit click
const TableManageClientsUI = ({ data }) => {
  // accessor is the "key" in the data
  const {
    state: { searchFilter },
  } = useTabContext();

  const { globalDispatch } = useGlobalContext();
  const columns = useMemo(
    () => [
      {
        Header: 'Client Name',
        accessor: 'name',
        maxWidth: 320,
        minWidth: 260,
        width: 280,
        filter: (rows, columnId, filterValue) =>
          rows.filter((row) =>
            row.original.name.toLowerCase().includes(filterValue.toLowerCase())
          ),
      },
      {
        Header: 'Contract Day',
        accessor: 'contractDate',
        maxWidth: 300,
        minWidth: 240,
        width: 220,
      },
      {
        Header: 'Billing Day',
        accessor: 'billingDate',
        maxWidth: 300,
        minWidth: 240,
        width: 220,
      },
      {
        Header: 'Activity',
        accessor: 'isActive',
        maxWidth: 52,
        minWidth: 100,
        width: 100,
        sortType: (a, b) =>
          a.original.isActive > b.original.isActive ? -1 : 1,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 90,
        minWidth: 120,
        width: 120,
        disableSortBy: true,
      },
    ],
    []
  );

  const handleEditPopup = (row) => {
    globalDispatch({
      type: 'setModalContent',
      payload: (
        <Suspense fallback={<div>Loading</div>}>
          <ClientsForm heading="Edit Client" row={row} type="edit" />
        </Suspense>
      ),
    });
  };

  /**
   * The useTable hook is what gives us the instances to render the table.
   * @see https://react-table.js.org/api/useTable
   */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
    useFlexLayout
  );

  useEffect(() => {
    setFilter('name', searchFilter);
  }, [searchFilter]);

  /**
   * In the header, we are showing the label + can add sorting options
   */

  const tableHeader = useMemo(
    () =>
      headerGroups.map((headerGroup, key) => (
        <div
          key={key}
          className={cx(tableStyles.tr, tableStyles['sticky-row'])}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map((column) => (
            <div
              key={column.id}
              className={`${tableStyles.th} ${styles.th}`}
              {...column.getHeaderProps(column.getSortByToggleProps())}
            >
              <Label
                fontStyle="bold"
                arrow={column.canSort ? 'arrow-down' : ''}
              >
                {column.render('Header')}
              </Label>
            </div>
          ))}
        </div>
      )),
    []
  );

  /**
   * The tableBody is the date we get from the API. Currently static :)
   */
  const tableBody = useMemo(
    () =>
      rows.map((row) => {
        // (Required) Any row that you intend to render in your table needs to be passed to
        // this function before every render.
        prepareRow(row);

        return (
          <div key={row.id} className={tableStyles.tr} {...row.getRowProps()}>
            {row.cells.map((cell) => {
              let output = <Label>{cell.value}</Label>;
              // Standard output

              if (cell.column.id === 'client') {
                output = <Link to={cell.value.slug}>{cell.value.name}</Link>;
              }

              if (cell.column.id === 'isActive') {
                output = (
                  <IsActiveCheckbox
                    endpoint={
                      '/private/admin/clients/active/' + row.original.id
                    }
                    isChecked={row.original.isActive}
                    queriesToInvalidate={['clients']}
                  />
                );
              }

              if (cell.column.id === 'actions') {
                output = (
                  <div className={tableStyles['actions-wrapper']}>
                    <Button
                      type="button-primary-inline"
                      icon={<Edit width="20px" height="20px" />}
                      clicked={() => handleEditPopup(row.original)}
                    />
                  </div>
                );
              }

              return (
                <div
                  key={cell.column.id}
                  className={cx(tableStyles.td)}
                  {...cell.getCellProps()}
                >
                  {output}
                </div>
              );
            })}
          </div>
        );
      }),
    [rows]
  );

  return (
    <div className={tableStyles.table} {...getTableProps}>
      <div className={`${tableStyles['thead-sticky']} thead`}>
        {tableHeader}
      </div>
      <div className={tableStyles.tbody} {...getTableBodyProps()}>
        {tableBody}
      </div>
    </div>
  );
};

TableManageClientsUI.propTypes = {
  data: PropTypes.object,
};
const getClients = async () => {
  const response = await instance.get('/private/admin/clients');
  return response.data;
};

const TableClients = ({ shouldShowInactive }) => {
  const { data } = useQuery('clients', getClients);

  const activeClients = data.filter((client) => client.isActive);

  return (
    <TableManageClientsUI data={shouldShowInactive ? data : activeClients} />
  );
};

TableClients.propTypes = {
  shouldShowInactive: PropTypes.bool,
};

export default TableClients;
