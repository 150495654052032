import React from 'react';
import PropTypes from 'prop-types';

const LoadingIcon = (props) => {
  return (
    <svg {...props} viewBox={`0 0 100 100`}>
      <circle cx="91" cy="50" fill="currentColor" r="6.39718">
        <animate
          attributeName="r"
          values="5;5;9;5;5"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.875s"
        ></animate>
      </circle>
      <circle
        cx="78.66666666666666"
        cy="78.66666666666666"
        fill="currentColor"
        r="4.8"
      >
        <animate
          attributeName="r"
          values="5;5;9;5;4.8"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.75s"
        ></animate>
      </circle>
      <circle cx="50" cy="91" fill="currentColor" r="4.8">
        <animate
          attributeName="r"
          values="5;5;9;5;5"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.625s"
        ></animate>
      </circle>
      <circle
        cx="21.33333333333333"
        cy="78.66666666666666"
        fill="currentColor"
        r="4.8"
      >
        <animate
          attributeName="r"
          values="5;5;9;5;5"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.5s"
        ></animate>
      </circle>
      <circle cx="9" cy="50" fill="currentColor" r="4.8">
        <animate
          attributeName="r"
          values="5;5;9;5;5"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.375s"
        ></animate>
      </circle>
      <circle
        cx="21.33333333333333"
        cy="21.33333333333333"
        fill="currentColor"
        r="4.80282"
      >
        <animate
          attributeName="r"
          values="5;5;9;5;5"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.25s"
        ></animate>
      </circle>
      <circle cx="50" cy="9" fill="currentColor" r="6.40282">
        <animate
          attributeName="r"
          values="5;5;9;5;5"
          dur="1s"
          repeatCount="indefinite"
          begin="-0.125s"
        ></animate>
      </circle>
      <circle
        cx="78.66666666666666"
        cy="21.33333333333333"
        fill="currentColor"
        r="7.99718"
      >
        <animate
          attributeName="r"
          values="5;5;9;5;5"
          dur="1s"
          repeatCount="indefinite"
          begin="0s"
        ></animate>
      </circle>
    </svg>
  );
};

LoadingIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default LoadingIcon;
