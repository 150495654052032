const projectTableGlobalFilter = (rows, columnIds, globalFilterValue) => {
  return rows.filter((row) => {
    let shouldRemove = true;
    for (let i = 0; i < columnIds.length; i++) {
      if (
        columnIds[i] === 'client' ||
        columnIds[i] === 'project' ||
        columnIds[i] === 'po' ||
        columnIds[i] === 'pm' ||
        columnIds[i] === 'employee'
      ) {
        if (
          row.original[columnIds[i]].name
            .toLowerCase()
            .includes(globalFilterValue.toLowerCase())
        ) {
          shouldRemove = false;
          break;
        }
      } else {
        if (
          String(row.original[columnIds[i]])
            .toLowerCase()
            .includes(globalFilterValue.toLowerCase())
        ) {
          shouldRemove = false;
          break;
        }
      }
    }
    return !shouldRemove;
  });
};

export default projectTableGlobalFilter;
