import React from 'react';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

import { useMutation } from 'react-query';
import instance from 'axiosInstance';

// Form
import { useForm } from 'react-hook-form';
import EmailIcon from 'components/Icons/Email';
import SubmitButton from '../SubmitButton';
import InputFieldNoLabel from '../InputFieldNoLabel';

// Styles
import styles from './LoginForm.module.scss';
import formStyles from '../Form.module.scss';

const resetPassword = (variables) => {
  const params = new URLSearchParams();
  params.append('email', variables.email);
  return instance.post('/public/password-reset', params);
};

const ForgotPassword = () => {
  const { globalDispatch } = useGlobalContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { error, isLoading, mutate } = useMutation(resetPassword, {
    mutationKey: 'reset-password',
    onSuccess: ({ data }) => {
      globalDispatch({
        type: 'pushNotification',
        payload: {
          type: 'success',
          text: 'Password reset link was sent successful! Check your email :)',
        },
      });
    },
    onError: () => {
      globalDispatch({
        type: 'pushNotification',
        payload: { type: 'error', text: 'Something went wrong!' },
      });
    },
  });

  const onSubmit = (data) => {
    mutate(data);
  };

  return (
    <div className={styles['login-form']}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative">
          <EmailIcon
            className={formStyles['field-icon']}
            color={
              errors.email
                ? 'var(--theme-color-danger)'
                : 'var(--theme-color-primary)'
            }
            width="1.125em"
            height="0.875em"
          />
          <InputFieldNoLabel
            placeholder="Enter your email"
            wrapperClassName={formStyles['full-width-input']}
            errors={errors.email}
            serverError={error && error.response.data.error}
            {...register('email', {
              required: '*Email is required',
              validate: (value) =>
                value.endsWith('@devrix.com') || 'Use a DevriX email',
            })}
          />
        </div>
        <SubmitButton
          additionalStyles={styles['sign-in-button']}
          disabled={isLoading}
          text="Reset!"
        />
      </form>
    </div>
  );
};
export default ForgotPassword;
