import React from 'react';
import SkeletonCustomField from '../SkeletonCustomField/SkeletonCustomField';

// Styles
import cx from 'classnames';
import styles from '../../UserInformationOverview/UserInformationOverview.module.scss';
import skeletonUserCardStyles from './SkeletonUserCard.module.scss';

const SkeletonUserCard = () => {
  return (
    <div className={styles['information-wrapper']}>
      <div className={styles['information-wrapper-inner']}>
        <div className={styles['header-wrapper']}>
          <div className={styles['header-background-wrapper']}>
            <SkeletonCustomField
              width="100%"
              height="238px"
              backgroundColor="var(--theme-background-color-light)"
              backgroundShineColor="var(--theme-background-color-light)"
              borderRadius="var(--app-general-border-radius-larger) var(--app-general-border-radius-larger) 0 0"
            />
          </div>
          <div className={cx(styles['header-wrapper-inner'], 'flex-row')}>
            <div className={styles['primary-container']}>
              <div className={styles['user-thumbnail']}>
                <SkeletonCustomField
                  width="216px"
                  height="216px"
                  backgroundColor="var(--theme-color-primary-light)"
                  backgroundShineColor="var(--theme-color-custom-shine)"
                  borderRadius="50%"
                  additionalStyles={skeletonUserCardStyles['image-wrapper']}
                />
              </div>
            </div>
            <div
              className={cx(
                styles['secondary-container'],
                'flex-row',
                'separated-content',
                'centered-end'
              )}
            >
              <div
                className={`${styles['user-information']}
                  ${skeletonUserCardStyles['user-information']}`}
              >
                <h1 className={styles['user-name']}>
                  <SkeletonCustomField
                    width="260px"
                    height="37px"
                    backgroundColor="var(--field-skeleton-background-color)"
                    backgroundShineColor="var(--field-skeleton-shine-background-color)"
                    additionalStyles={skeletonUserCardStyles['name-field']}
                  />
                </h1>
                <p className={styles['user-position']}>
                  <SkeletonCustomField
                    width="150px"
                    height="18px"
                    backgroundColor="var(--field-skeleton-background-color)"
                    backgroundShineColor="var(--field-skeleton-shine-background-color)"
                  />
                </p>
              </div>

              <SkeletonCustomField
                width="140px"
                height="40px"
                backgroundColor="var(--theme-color-primary-light)"
                backgroundShineColor="var(--theme-color-custom-shine)"
                borderRadius="var(--app-general-border-radius-larger)"
                additionalStyles={skeletonUserCardStyles['edit-button']}
              />
            </div>
          </div>
        </div>
        <div className={cx(styles['body-content-wrapper'], 'flex-row')}>
          <div className={styles['primary-container']}>
            <div
              className={cx(
                styles['badge-list-wrapper'],
                'flex-row',
                'wrap',
                'centered-horizontally'
              )}
            >
              <SkeletonCustomField
                width="62px"
                height="62px"
                backgroundColor="var(--theme-color-primary-light)"
                backgroundShineColor="var(--theme-color-custom-shine)"
                borderRadius="50%"
              />
              <SkeletonCustomField
                width="62px"
                height="62px"
                backgroundColor="var(--theme-color-primary-light)"
                backgroundShineColor="var(--theme-color-custom-shine)"
                borderRadius="50%"
              />
            </div>
            <p className={styles['badges-subheader']}>
              <SkeletonCustomField
                width="104px"
                height="16px"
                backgroundColor="var(--field-skeleton-background-color)"
                backgroundShineColor="var(--field-skeleton-shine-background-color)"
              />
            </p>
          </div>
          <div className={styles['secondary-container']}>
            <div className={styles['user-description-wrapper']}>
              <p className={styles['user-description']}>
                <SkeletonCustomField
                  width="104px"
                  height="16px"
                  backgroundColor="var(--field-skeleton-background-color)"
                  backgroundShineColor="var(--field-skeleton-shine-background-color)"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonUserCard;
