import React from 'react';

import Card from 'components/Card/Card';
import RequestFeature from 'components/Form/Request/RequestFeature/RequestFeature';

const TabRequestFeature = () => {
  return (
    <Card hasPadding>
      <RequestFeature />
    </Card>
  );
};

export default TabRequestFeature;
