import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// Form
import { useController } from 'react-hook-form';
import Select from './Select';

import { useQuery } from 'react-query';
import instance from 'axiosInstance';

const getComponents = async ({ queryKey }) => {
  const response = await instance.get(
    '/private/project-components/names?project_id=' + queryKey[0]
  );
  return response.data;
};

const ComponentSelect = ({ control, projectId }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'component',
    control,
    rules: { required: '*Component is required' },
  });

  const { data } = useQuery([projectId, 'components-names'], getComponents, {
    enabled: !!projectId,
  });

  const options = useMemo(
    () =>
      data
        ?.filter((component) => component.isActive)
        ?.map((component) => ({
          label: component.name,
          value: component.id,
        })) || [],
    [data]
  );

  return (
    <Select
      placeholder="Component"
      error={error}
      {...field}
      options={options}
    />
  );
};

ComponentSelect.propTypes = {
  control: PropTypes.object,
  setValue: PropTypes.func,
  projectId: PropTypes.number,
};

export default ComponentSelect;
