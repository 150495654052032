import React from 'react';

const InfoBoldSolid = (props) => {
  return (
    <svg
      viewBox="0 0 44 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 0.105713C10.5703 0.105713 1.375 9.38697 1.375 20.7307C1.375 32.1604 10.5703 41.3557 22 41.3557C33.3437 41.3557 42.625 32.1604 42.625 20.7307C42.625 9.38697 33.3437 0.105713 22 0.105713ZM22 9.73071C23.461 9.73071 24.75 11.0198 24.75 12.4807C24.75 14.0276 23.461 15.2307 22 15.2307C20.4531 15.2307 19.25 14.0276 19.25 12.4807C19.25 11.0198 20.4531 9.73071 22 9.73071ZM25.4374 31.7307H18.5626C17.3594 31.7307 16.5 30.8714 16.5 29.6681C16.5 28.551 17.3594 27.6057 18.5626 27.6057H19.9374V22.1057H19.25C18.0469 22.1057 17.1874 21.2464 17.1874 20.0433C17.1874 18.926 18.0469 17.9807 19.25 17.9807H22C23.1173 17.9807 24.0626 18.926 24.0626 20.0433V27.6057H25.4374C26.5547 27.6057 27.5 28.551 27.5 29.6681C27.5 30.8714 26.5547 31.7307 25.4374 31.7307Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InfoBoldSolid;
