import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Select from './Select';
import { useController } from 'react-hook-form';

import { useQuery } from 'react-query';
import instance from 'axiosInstance';

const getClients = async () => {
  const response = await instance.get('/private/admin/clients');
  return response.data;
};

const ClientSelect = ({ control }) => {
  const { data } = useQuery('clients', getClients);

  const options = useMemo(() =>
    data
      .filter((client) => client.isActive)
      .map((client) => ({ label: client.name, value: client.id }))
  );

  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'client',
    control,
    rules: {
      required: '*Client is required!',
    },
  });

  return (
    <Select placeholder="Client" error={error} options={options} {...field} />
  );
};

ClientSelect.propTypes = {
  control: PropTypes.object,
};

export default ClientSelect;
