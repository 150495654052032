import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BE_API,
});

instance.defaults.headers.common['Content-Type'] =
  'application/x-www-form-urlencoded';

export default instance;
