const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const parseYearMonthDate = (d) => {
  const year = d.getFullYear();
  let month = d.getMonth() + 1;
  let date = d.getDate();

  if (month < 10) {
    month = '0' + month;
  }

  if (date < 10) {
    date = '0' + date;
  }

  return `${year}-${month}-${date}`;
};

export const parseMonthToNameFromString = (item) => {
  const [year, month] = item.split('-');

  return `${monthNames[Number(month) - 1]} ${year}`;
};

export const parseMonthsToNameFromDate = (d) => {
  const date = d.getDate();
  const month = d.getMonth();
  const year = d.getFullYear();
  const monthName = monthNames[month];

  return `${monthName} ${date} ${year}`;
};

export const parseDateToBeString = (d) => {
  const date = d.getDate();
  const month = d.getMonth();
  const year = d.getFullYear();

  return `${year}-${month + 1}-${date}`;
};

export const parseDateToBeStringMonthWithZero = (d) => {
  const date = d.getDate();
  let month = d.getMonth() + 1;
  const year = d.getFullYear();

  if (month < 10) {
    month = '0' + month;
  }

  return `${year}-${month}-${date}`;
};

export const prettifyActiveDay = (d) => {
  const dayOfWeek = d.getDay();
  let date = d.getDate();
  const month = d.getMonth();

  if (date < 10) {
    date = '0' + date;
  }

  return `${daysOfWeek[dayOfWeek]} ${date} ${monthNames[month]}`;
};

export const getFridayOfCurrentWeek = () => {
  const today = new Date();
  const first = today.getDate() - today.getDay() + 1;
  const fifth = first + 4;

  const friday = new Date(today.setDate(fifth));

  return friday;
};

export const getPreviousMonday = (d) => {
  const day = d.getDay();
  const diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

  return new Date(d.setDate(diff));
};

export const getNextDayOfWeek = (date, dayOfWeek) => {
  const resultDate = new Date(date.getTime());

  resultDate.setDate(date.getDate() + ((7 + dayOfWeek - date.getDay()) % 7));

  return resultDate;
};

export const monthDiff = (d1, d2) => {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

export const shouldShowMyReportForm = (d) => {
  const today = new Date();
  const day = today.getDay();
  const weekDates = getWeekDates(today);

  if (weekDates.includes(d.getDate())) {
    return true;
  } else if (day === 1) {
    const newToday = new Date();
    const previousSunday = new Date(newToday.setDate(today.getDate() - 1));
    const prevWeekDates = getWeekDates(previousSunday);

    if (prevWeekDates.includes(d.getDate())) {
      return true;
    }
  }

  return false;
};

export const getLowestEditDate = () => {
  const today = new Date();
  const day = today.getDay();

  if (day === 1) {
    const previous = new Date();
    previous.setDate(today.getDate() - 1);

    return getPreviousMonday(previous);
  } else {
    return getPreviousMonday(today);
  }
};

const getWeekDates = (d) => {
  const periodStart = getPreviousMonday(d);
  const dates = [];

  for (let i = 0; i < 7; i++) {
    const currentDate = periodStart.getDate();
    dates.push(periodStart.getDate());
    periodStart.setDate(currentDate + 1);
  }

  return dates;
};

export const getFirstDayOfMonth = (year, month) => {
  return new Date(year, month, 1);
};

export default parseYearMonthDate;
