import {
  getFirstDayOfMonth,
  getPreviousMonday,
  getNextDayOfWeek,
  parseMonthsToNameFromDate,
  parseDateToBeString,
} from 'utils/dateFunctions';

const generateWeeks = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  const firstDay = getFirstDayOfMonth(year, month);

  let periodStart = getPreviousMonday(firstDay);
  let periodEnd = getNextDayOfWeek(firstDay, 7);

  const weeks = [
    {
      label: `${parseMonthsToNameFromDate(
        periodStart
      )} - ${parseMonthsToNameFromDate(periodEnd)}`,
      value: `${parseDateToBeString(periodStart)} ${parseDateToBeString(
        periodEnd
      )}`,
    },
  ];

  while (periodEnd < today) {
    periodStart = getNextDayOfWeek(new Date(periodEnd), 1);
    periodEnd = getNextDayOfWeek(new Date(periodStart), 7);

    weeks.push({
      label: `${parseMonthsToNameFromDate(
        periodStart
      )} - ${parseMonthsToNameFromDate(periodEnd)}`,
      value: `${parseDateToBeString(periodStart)} ${parseDateToBeString(
        periodEnd
      )}`,
    });
  }

  return weeks;
};

export const getCurrentWeek = () => {
  const today = new Date();

  const periodStart = getPreviousMonday(today);
  const periodEnd = getNextDayOfWeek(today, 7);

  const currentWeek = {
    label: `${parseMonthsToNameFromDate(
      periodStart
    )} - ${parseMonthsToNameFromDate(periodEnd)}`,
    value: `${parseDateToBeString(periodStart)} ${parseDateToBeString(
      periodEnd
    )}`,
  };

  return currentWeek;
};

export default generateWeeks;
