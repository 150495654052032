import React, { useState } from 'react';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

// Forms
import EditProfileInformationForm from 'components/Form/EditProfileInformationForm';
import ChangePasswordForm from 'components/Form/Auth/ChangePasswordForm';

// UX Components
import Thumbnail from 'components/Thumbnail/Thumbnail';
import ButtonDropdown from 'components/Button/ButtonDropdown/ButtonDropdown';
import Button from 'components/Button/Button';
import LabelButton from 'components/Button/LabelButton';

// Styles
import cx from 'classnames';
import styles from './UserInformationOverview.module.scss';

import { useQuery, useMutation, useQueryClient } from 'react-query';
import instance from 'axiosInstance';

// utils
import { monthDiff } from 'utils/dateFunctions';

const getUserInformation = async () => {
  const response = await instance.get('/private/employee-information');
  return response.data;
};

const saveImage = async (image) => {
  const params = new URLSearchParams();
  params.append('image', image);

  const response = await instance.put(
    '/private/employee-information/image/upload',
    params
  );

  return response.data;
};

const UserInformationOverview = () => {
  const queryClient = useQueryClient();
  const { globalDispatch } = useGlobalContext();
  const badges = [];
  const [profileImage, setProfileImage] = useState(undefined);

  const { mutate } = useMutation(saveImage, {
    mutationKey: 'saveImage',
    onSuccess: () => {
      queryClient.invalidateQueries('user-information');
      setProfileImage(undefined);

      globalDispatch({
        type: 'pushNotification',
        payload: {
          type: 'success',
          text: 'Profile picture updated!',
        },
      });
    },
  });

  const handleProfileChange = (e) => {
    const input = e.target;

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = function (e) {
        setProfileImage(e.target.result);
      };

      reader.readAsDataURL(input.files[0]);
    }
  };

  const handleSaveImage = () => {
    mutate(profileImage);
  };

  const { data } = useQuery(['user-information', null], getUserInformation);

  const monthInCompany = monthDiff(new Date(data.dateOfStart), new Date());
  const yearsInCompany = monthInCompany / 12;

  for (let i = 1; i <= yearsInCompany; i++) {
    badges.push(
      <Thumbnail
        key={i}
        src={`/badges/${i}-years-badge.png`}
        customWidth="3.875em"
        customHeight="3.875em"
      />
    );
  }

  return (
    <>
      <div className={styles['header-wrapper']}>
        <div className={styles['header-background-wrapper']}>
          <Thumbnail
            src="https://i.ibb.co/fdRgq7k/user-background.png"
            customHeight="14.875em"
            customWidth="100%"
            borderRadius="var(--app-general-border-radius-larger) var(--app-general-border-radius-larger) 0 0"
          />
        </div>
        <div className={cx(styles['header-wrapper-inner'], 'flex-row')}>
          <div className={styles['primary-container']}>
            <div className={cx(styles['user-thumbnail'], styles['can-change'])}>
              <label htmlFor="upload-image">
                <Thumbnail
                  src={profileImage || data.imageUrl || '/dixy.png'}
                  alt="user-thumbnail"
                  customHeight="13.5em"
                  customWidth="13.5em"
                  borderRadius="50%"
                  additionalStyles={styles['image-wrapper']}
                />
              </label>
              <input
                type="file"
                style={{ display: 'none' }}
                onChange={handleProfileChange}
                id="upload-image"
                accept="image/png, image/gif, image/jpeg"
              />
              <div className={styles['buttons']}>
                <LabelButton htmlFor="upload-image" text="Change" />

                <Button
                  type="button-primary"
                  text="Save"
                  clicked={handleSaveImage}
                />
              </div>
            </div>
          </div>
          <div
            className={cx(
              styles['secondary-container'],
              'flex-row',
              'separated-content',
              'centered-end'
            )}
          >
            <div className={styles['user-information']}>
              <h1 className={styles['user-name']}>{data.fullName}</h1>
              <p className={styles['user-position']}>{data.title}</p>
            </div>

            <ButtonDropdown
              additionalStyles={styles['user-button-edit']}
              text="Edit profile"
              type="button-primary"
              dropdownItems={[
                {
                  title: 'Edit profile information',
                  click: () =>
                    globalDispatch({
                      type: 'setModalContent',
                      payload: (
                        <ErrorBoundary>
                          <EditProfileInformationForm data={data} />
                        </ErrorBoundary>
                      ),
                    }),
                },
                {
                  title: 'Change password',
                  click: () =>
                    globalDispatch({
                      type: 'setModalContent',
                      payload: <ChangePasswordForm />,
                    }),
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className={cx(styles['body-content-wrapper'], 'flex-row')}>
        <div className={styles['primary-container']}>
          <div
            className={cx(
              styles['badge-list-wrapper'],
              'flex-row',
              'wrap',
              'centered-horizontally'
            )}
          >
            {badges}
          </div>
          <p className={styles['badges-subheader']}>Collected badges</p>
        </div>

        {data.description && (
          <div className={styles['secondary-container']}>
            <div className={styles['user-description-wrapper']}>
              <p className={styles['user-description']}>{data.description}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserInformationOverview;
