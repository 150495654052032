import React, { Suspense, useState } from 'react';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Table
import TableManageProjects from 'components/Tables/Mangage/TableManageProjects/TableManageProjects';
import SearchFilter from 'components/Tables/SearchField';
import Button from 'components/Button/Button';
import SkeletonTable from 'components/LoadingSkeletons/SkeletonTable/SkeletonTable';
import ExternalStateCheckbox from 'components/Checkbox/ExternalStateCheckbox';

// Form
import ManageProjectsForm from 'components/Form/Manage/ManageProjectsForm/ManageProjectsForm';

// UI components
import Card from 'components/Card/Card';
import FlexContainer from 'components/FlexContainer/FlexContainer';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

const TabProjects = () => {
  const { globalDispatch } = useGlobalContext();
  const [shouldShowInactive, setShouldShowInactive] = useState(false);

  const handleAddPopup = () => {
    globalDispatch({
      type: 'setModalContent',
      payload: (
        <Suspense fallback={<div>Loading</div>}>
          <ManageProjectsForm heading="Add Project" type="create" />
        </Suspense>
      ),
    });
  };
  return (
    <>
      <Card
        title="Projects overview"
        leftAdditional={
          <label>
            Show Inactive
            <ExternalStateCheckbox
              setIsChecked={setShouldShowInactive}
              isChecked={shouldShowInactive}
            />
          </label>
        }
        headerAdditional={
          <FlexContainer spaceX="children-x-10px">
            <SearchFilter />
            <Button
              clicked={handleAddPopup}
              text="Add"
              type="button-primary"
              additionalInlineStyles={{
                minWidth: '6.25em',
                paddingTop: '0.375em',
                paddingBottom: '0.375em',
              }}
            />
          </FlexContainer>
        }
      >
        <ErrorBoundary>
          <Suspense fallback={<SkeletonTable countTableBodyRows={25} />}>
            <TableManageProjects shouldShowInactive={shouldShowInactive} />
          </Suspense>
        </ErrorBoundary>
      </Card>
    </>
  );
};

export default TabProjects;
