import React from 'react';

import PropTypes from 'prop-types';

import LoadingIcon from 'components/Icons/Loading';

import styles from './SkeletonLoadingField.module.scss';

const SkeletonLoadingField = ({ additionalInlineStyles }) => {
  return (
    <div
      className={styles['loading-field-wrapper']}
      style={additionalInlineStyles}
    >
      <input className={styles['loading-field']} />
      <LoadingIcon width="24" height="24" color={`var(--theme-text-color)`} />
    </div>
  );
};

SkeletonLoadingField.propTypes = {
  additionalInlineStyles: PropTypes.string,
};

export default SkeletonLoadingField;
