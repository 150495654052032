import { useEffect } from 'react';
import * as serviceWorkerRegistration from '../../serviceWorkerRegestartion';

const UpdateAvailableNotification = () => {
  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (reg) => {
        reg.waiting.postMessage({ type: 'SKIP_WAITING' });
      },
    });

    window.addEventListener('focus', function () {
      navigator.serviceWorker
        .getRegistrations()
        .then((regs) => regs.forEach((reg) => reg.update()));
    });

    let isRefreshing;
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (!isRefreshing) {
        isRefreshing = true;
        window.location.reload();
      }
    });
  }, []);

  return null;
};

export default UpdateAvailableNotification;
