import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

// Form
import { useForm } from 'react-hook-form';

import SubmitButton from 'components/Form/SubmitButton';
import Button from 'components/Button/Button';

// Styles
import formStyles from '../Form.module.scss';

// Requests
import { useMutation, useQueryClient } from 'react-query';
import instance from 'axiosInstance';

const mutationFunction = async (variables) => {
  const params = new URLSearchParams();
  params.append('name', variables.name);
  params.append('project_slug', variables.slug);

  let response;
  if (variables.type === 'create') {
    response = await instance.post('/private/admin/project-components', params);
  } else if (variables.type === 'edit') {
    response = await instance.put(
      '/private/admin/project-components/' + variables.id,
      params
    );
  }
  return response.data;
};

const ComponentsProjectForm = ({ heading, row, type, slug }) => {
  const { id, name = '' } = row || '';

  const { globalDispatch } = useGlobalContext();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(mutationFunction, {
    mutationKey: `${type}-component`,
    onSuccess: () => {
      queryClient.invalidateQueries('components-names');
      queryClient.invalidateQueries(['components', slug]);

      globalDispatch({ type: 'setModalContent', payload: null });

      globalDispatch({
        type: 'pushNotification',
        payload: {
          type: 'success',
          text: `Components ${
            type === 'create' ? 'created' : 'edited'
          } successfully`,
        },
      });
    },

    onError: () => {
      globalDispatch({
        type: 'pushNotification',
        payload: { type: 'error', text: 'Something went wrong! :(' },
      });
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name,
    },
  });

  const onSubmit = (data) => {
    data.id = id;
    data.type = type;
    data.slug = slug;
    mutate(data);
  };

  const handleRejection = () => {
    globalDispatch({ type: 'setModalContent', payload: null });
  };

  return (
    <div className={formStyles['edit-report-form']}>
      <h2 className={formStyles['form-title']}>{heading}</h2>
      <form
        className={formStyles['form-wrapper']}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          className={`${formStyles['form-input-wrapper']} ${formStyles['width-large']}`}
        >
          <label className={formStyles['form-input-label']}>
            Component
            <div className={formStyles['form-field-wrapper']}>
              <input
                type="text"
                placeholder="Component"
                {...register('name', {
                  required: '*Required! The name of the component is required',
                })}
              />
              {errors && errors.name && <span>{errors.name.message}</span>}
            </div>
          </label>
        </div>

        <div className={formStyles['form-actions-wrapper']}>
          <Button
            clicked={handleRejection}
            type="button-primary-inline-adaptive"
            text="Cancel"
          />
          <SubmitButton
            type="button-success"
            additionalStyles={formStyles['edit-report-submit']}
            text="Save"
          />
        </div>
      </form>
    </div>
  );
};

ComponentsProjectForm.propTypes = {
  type: PropTypes.string.isRequired,
  row: PropTypes.object,
  heading: PropTypes.string,
  slug: PropTypes.string,
};

export default memo(ComponentsProjectForm);
