import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import ErrorSolid from 'components/Icons/Error-solid';

import cx from 'classnames';
import formStyles from './Form.module.scss';

const InputField = forwardRef(
  (
    {
      placeholder,
      wrapperClassName,
      label,
      type = 'text',
      errors,
      autofocus,
      serverErrors,
      ...register
    },
    ref
  ) => {
    return (
      <div className={wrapperClassName}>
        <label className={formStyles['form-input-label']}>
          {label}
          <div
            className={cx(formStyles['form-field-wrapper'], {
              [formStyles['field-error-wrapper']]: errors || serverErrors,
            })}
          >
            <input
              type={type}
              autoFocus={autofocus}
              placeholder={placeholder}
              {...register}
              ref={ref}
              step="any"
              className={
                errors || serverErrors ? formStyles['field-error'] : ''
              }
            />
            {(errors || serverErrors) && (
              <span className={formStyles['error-icon']}>
                <ErrorSolid
                  width="20"
                  height="20"
                  color={`var(--theme-color-danger-dark)`}
                />
              </span>
            )}
          </div>
          {errors && (
            <span className={formStyles['error-message']}>
              {errors.message}
            </span>
          )}
          {serverErrors && (
            <span className={formStyles['error-message']}>
              {serverErrors.data.data}
            </span>
          )}
        </label>
      </div>
    );
  }
);

InputField.displayName = 'InputField';

InputField.propTypes = {
  placeholder: PropTypes.string,
  serverErrors: PropTypes.object,
  autofocus: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  errors: PropTypes.object,
  wrapperClassName: PropTypes.string,
};

export default InputField;
