import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Select from './Select';
import { useController } from 'react-hook-form';

import { useQuery } from 'react-query';
import instance from 'axiosInstance';

const getPositions = async () => {
  const response = await instance.get('/private/employees-positions');
  return response.data;
};

const PositionsCombobox = ({ control }) => {
  const { data } = useQuery('positions', getPositions);

  const options = useMemo(
    () =>
      data
        .filter((position) => position.is_active === '1')
        .map((position) => ({
          label: position.title,
          value: position.id,
        })),
    [data]
  );

  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'position',
    control,
    rules: {
      required: '*Postion is required!',
    },
  });

  return (
    <Select {...field} options={options} placeholder="Position" error={error} />
  );
};

PositionsCombobox.propTypes = {
  control: PropTypes.control,
};

export default PositionsCombobox;
