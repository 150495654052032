import React, { useState } from 'react';
import TabContext from 'GlobalState/Context/TabContext';

// UI components
import EmployeesSubtab from './EmployeesSubtab';
import Subtabs from 'components/Subtabs/Subtabs';
import PositionsSubtab from './PositionsSubtab';

import styles from './TabEmployeesPositions.module.scss';

const TabEmployeesPositions = () => {
  const [subTab, setSubTab] = useState({
    label: 'employees',
    element: (
      <TabContext>
        <EmployeesSubtab />
      </TabContext>
    ),
  });
  const isEmployees = true;

  const subtabs = [
    {
      text: 'Employees',
      isActive: subTab.label === 'employees',
      clicked: () =>
        setSubTab({
          label: 'employees',
          element: (
            <TabContext>
              <EmployeesSubtab />
            </TabContext>
          ),
        }),
    },
    {
      text: 'Positions',
      isActive: subTab.label === 'positions',
      clicked: () =>
        setSubTab({
          label: 'positions',
          element: (
            <TabContext>
              <PositionsSubtab />
            </TabContext>
          ),
        }),
    },
  ];

  return (
    <>
      <Subtabs tabs={subtabs} />
      {isEmployees && (
        <div className={styles['manage-table-tabs-wrapper']}></div>
      )}
      {subTab.element}
    </>
  );
};

export default TabEmployeesPositions;
