import React from 'react';
import PropTypes from 'prop-types';

import { useTabContext } from 'GlobalState/Context/TabContext';

// Styles
import cx from 'classnames';
import styles from './WorkedHoursPerDay.module.scss';

const calendarDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const WorkDayBox = ({ date, hours, index }) => {
  const {
    state: { activeDay },
    dispatch,
  } = useTabContext();

  const position = date.search('-');
  const substring = date.substring(position + 1);

  const d = new Date(date);

  const isActive =
    activeDay.getFullYear() === d.getFullYear() &&
    activeDay.getMonth() === d.getMonth() &&
    activeDay.getDate() === d.getDate();

  const handleClick = () => {
    dispatch({ type: 'setActiveDay', payload: new Date(date) });
  };
  return (
    <div
      className={cx(
        styles.box,
        hours >= 7 && styles.success,
        hours < 7 && index < 5 && styles.danger,
        hours === 'OFF' && styles.off,
        index >= 5 && index <= 6 && styles.holiday,
        isActive && styles.active
      )}
      onClick={handleClick}
    >
      <span
        className={styles['date']}
      >{`${calendarDays[index]}, ${substring}`}</span>
      <span className={styles['hours']}>{`${
        hours === 'OFF' ? hours : Number(hours).toFixed(2) + 'h'
      }`}</span>
    </div>
  );
};

WorkDayBox.propTypes = {
  date: PropTypes.string,
  hours: PropTypes.string,
  index: PropTypes.number,
};

export default WorkDayBox;
