import React from 'react';

const CloseThin = (props) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.06076 2.07842L4.98235 6.00008L1.06093 9.92158C0.779913 10.2026 0.779912 10.6582 1.06093 10.9392C1.34194 11.2203 1.79756 11.2203 2.07858 10.9392L6 7.01775L9.92142 10.9392C10.2024 11.2203 10.6581 11.2203 10.9391 10.9392C11.2201 10.6582 11.2201 10.2026 10.9391 9.92158L7.01764 6.00008L10.9392 2.07842C11.2203 1.79741 11.2203 1.34178 10.9392 1.06077C10.6582 0.779745 10.2026 0.779745 9.92159 1.06077L6 4.98242L2.07841 1.06077C1.79739 0.779745 1.34178 0.779745 1.06076 1.06077C0.779746 1.34178 0.779747 1.79741 1.06076 2.07842Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.3"
      />
    </svg>
  );
};

export default CloseThin;
