import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

import styles from './ModalPopup.module.scss';
import cx from 'classnames';

const ModalPopup = ({ children, hasOverflowVisible }) => {
  const { globalDispatch } = useGlobalContext();

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.code === 'Escape') {
        globalDispatch({ type: 'setModalContent', payload: null });
      }
    };

    if (children) {
      document.addEventListener('keydown', handleKeyPress);
      document.body.style.overflow = 'hidden';
    } else {
      document.removeEventListener('keydown', handleKeyPress);
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [children]);

  return (
    <>
      <div
        className={cx(
          [styles['modal']],
          {
            [styles['modal-visible']]: children !== null,
          },
          hasOverflowVisible ? styles.overflow : styles['vertical-scroll']
        )}
      >
        {children}
      </div>
      <div
        onClick={() =>
          globalDispatch({ type: 'setModalContent', payload: null })
        }
        className={cx({
          [styles['overlay']]: true,
          [styles['overlay-visible']]: children !== null,
        })}
      ></div>
    </>
  );
};

ModalPopup.propTypes = {
  children: PropTypes.object,
  hasOverflowVisible: PropTypes.bool,
};

export default ModalPopup;
