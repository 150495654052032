import React from 'react';

const ChecklistLight = (props) => {
  return (
    <svg
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.71875 3.4021H16.2188C16.6562 3.4021 16.9688 3.0896 16.9688 2.6521C16.9688 2.24585 16.6562 1.9021 16.2188 1.9021H7.71875C7.3125 1.9021 6.96875 2.24585 6.96875 2.6521C6.96875 3.0896 7.3125 3.4021 7.71875 3.4021ZM2.46875 11.6521C1.9375 11.6521 1.46875 12.1208 1.46875 12.6521C1.46875 13.2146 1.9375 13.6521 2.46875 13.6521C3.03125 13.6521 3.46875 13.2146 3.46875 12.6521C3.46875 12.1208 3.03125 11.6521 2.46875 11.6521ZM16.2188 6.9021H7.71875C7.3125 6.9021 6.96875 7.24585 6.96875 7.6521C6.96875 8.0896 7.3125 8.4021 7.71875 8.4021H16.2188C16.6562 8.4021 16.9688 8.0896 16.9688 7.6521C16.9688 7.24585 16.6562 6.9021 16.2188 6.9021ZM16.2188 11.9021H6.71875C6.3125 11.9021 5.96875 12.2458 5.96875 12.6521C5.96875 13.0896 6.3125 13.4021 6.71875 13.4021H16.2188C16.6562 13.4021 16.9688 13.0896 16.9688 12.6521C16.9688 12.2458 16.6562 11.9021 16.2188 11.9021ZM4.6875 0.9021L2.96875 2.8396L2.25 2.1521C1.96875 1.8396 1.5 1.8396 1.21875 2.1521C0.90625 2.43335 0.90625 2.9021 1.21875 3.18335L2.46875 4.43335C2.59375 4.5896 2.78125 4.6521 2.96875 4.6521C3 4.6521 3 4.6521 3 4.6521C3.21875 4.6521 3.40625 4.55835 3.53125 4.43335L5.78125 1.93335C6.0625 1.62085 6.03125 1.1521 5.75 0.87085C5.4375 0.5896 4.96875 0.62085 4.6875 0.9021ZM4.6875 5.9021L2.96875 7.8396L2.25 7.1521C1.96875 6.8396 1.5 6.8396 1.21875 7.1521C0.90625 7.43335 0.90625 7.9021 1.21875 8.18335L2.46875 9.43335C2.59375 9.5896 2.78125 9.6521 2.96875 9.6521C3 9.6521 3 9.6521 3 9.6521C3.21875 9.6521 3.40625 9.55835 3.53125 9.43335L5.78125 6.93335C6.0625 6.62085 6.03125 6.1521 5.75 5.87085C5.4375 5.5896 4.96875 5.62085 4.6875 5.9021Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChecklistLight;
