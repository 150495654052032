import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

import CheckSolid from 'components/Icons/Check-solid';
import styles from './Checkbox.module.scss';
import cx from 'classnames';

import { useMutation, useQueryClient } from 'react-query';
import instance from 'axiosInstance';

const IsActiveCheckbox = ({ endpoint, queriesToInvalidate, isChecked }) => {
  const queryClient = useQueryClient();
  const { globalDispatch } = useGlobalContext();

  const toggleActivity = useCallback(async () => {
    const params = new URLSearchParams();
    params.append('isActive', !isChecked);
    const response = await instance.put(endpoint, params);

    return response.data;
  }, [endpoint, isChecked]);

  const { mutate } = useMutation(toggleActivity, {
    mutationKey: ``,
    onSuccess: () => {
      queriesToInvalidate.map((query) => {
        queryClient.invalidateQueries(query);
      });

      globalDispatch({
        type: 'pushNotification',
        payload: {
          type: isChecked ? 'warning' : 'success',
          text: isChecked
            ? 'Successfully set inactive'
            : 'Successfully set active',
        },
      });
    },
  });

  const handleOnChange = () => {
    mutate();
  };

  return (
    <div
      className={cx(
        styles['checkbox-wrapper'],
        'flex-row',
        'centered-horizontally',
        'centered-vertically'
      )}
    >
      <label
        className={`${styles['checkbox-item-custom']} ${
          isChecked ? styles.checked : ''
        }`}
      >
        <input
          type="checkbox"
          onChange={handleOnChange}
          className={`${styles['checkbox-item']} ${
            isChecked ? styles.checked : ''
          }`}
        />
        <CheckSolid color="var(--theme-text-color-invert-adaptive)" />
      </label>
    </div>
  );
};

IsActiveCheckbox.propTypes = {
  endpoint: PropTypes.string,
  queriesToInvalidate: PropTypes.array,
  isChecked: PropTypes.bool,
};

export default IsActiveCheckbox;
