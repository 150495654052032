import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import styles from './Subtabs.module.scss';

const Subtabs = ({ tabs }) => {
  const listItemsElements = tabs.map((tab) => {
    return (
      <li
        key={tab.text}
        className={`${styles['list-item']} ${
          tab.isActive ? styles.active : ''
        }`}
        onClick={tab.clicked}
      >
        {tab.text}
      </li>
    );
  });

  return (
    <div className={styles['table-tabs']}>
      <ul className={cx(styles['tabs-list'], 'flex-row')}>
        {listItemsElements}
      </ul>
    </div>
  );
};

Subtabs.propTypes = {
  isActive: PropTypes.bool,
  tabs: PropTypes.array,
  clicked: PropTypes.func,
};

export default Subtabs;
