import React from 'react';
import PropTypes from 'prop-types';

// Styles
import style from './SkeletonTable.module.scss';
import tableStyles from '../../Tables/Tables.module.scss';
import SkeletonField from '../SkeletonField/SkeletonField';

const SkeletonTable = ({ countTableBodyRows }) => {
  const tableBody = [];

  for (let i = 1; i <= countTableBodyRows; i++) {
    tableBody.push(
      <div key={i} className={`${tableStyles.tr} ${style.tr}`}>
        <SkeletonField additionalStyles={style.field} />
      </div>
    );
  }

  return (
    <div className={tableStyles.table}>
      <div className={style.thead}>
        <div className={`${tableStyles.tr} ${style.tr}`}>
          <SkeletonField additionalStyles={style.field} />
        </div>
      </div>
      <div className={tableStyles.tbody}>{tableBody}</div>
    </div>
  );
};

SkeletonTable.propTypes = {
  countTableBodyRows: PropTypes.number,
};

export default SkeletonTable;
