import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import styles from './Thumbnail.module.scss';

const Thumbnail = ({
  src,
  alt,
  customHeight,
  customWidth,
  additionalStyles,
  borderRadius,
}) => {
  return (
    <div
      className={cx(styles['thumbnail-wrapper'], additionalStyles)}
      style={{
        width: customWidth,
        paddingBottom: customHeight,
        borderRadius: borderRadius,
      }}
    >
      <img
        className={cx(styles.thumbnail, src ? '' : styles.thumbnailPlaceholder)}
        src={src}
        alt={alt}
        style={{
          borderRadius: borderRadius,
        }}
      />
    </div>
  );
};

Thumbnail.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  customHeight: PropTypes.string,
  customWidth: PropTypes.string,
  additionalStyles: PropTypes.string,
  borderRadius: PropTypes.string,
};

export default Thumbnail;
