import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import styles from './Boxes.module.scss';

/**
 * Wrapper used to define how group of boxes should behave.
 * @param {node} children
 * @return { JSX } Boxes
 */
const Boxes = ({ children, additionalStyles }) => {
  return <div className={cx(styles.boxes, additionalStyles)}>{children}</div>;
};

Boxes.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  additionalStyles: PropTypes.string,
};

export default Boxes;
