import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './AppTabs.module.scss';

const AppTabs = ({ links }) => {
  const location = useLocation();

  // We will build the links here and highlight the current one based on the route.
  const linksElements = links.map((link) => {
    return (
      <li
        key={link.text}
        className={link.to === location.pathname ? styles['is-current'] : ''}
      >
        <Link to={link.to}>{link.text}</Link>
      </li>
    );
  });

  return (
    <div className={styles['tabs']}>
      <ul className={styles['list']}>{linksElements}</ul>
    </div>
  );
};

AppTabs.propTypes = {
  links: PropTypes.array.isRequired,
};

export default AppTabs;
