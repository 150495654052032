import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'react-datepicker';

import { useController } from 'react-hook-form';

import 'react-datepicker/dist/react-datepicker.css';

const DateField = ({ control, inputName, rules, ...props }) => {
  const {
    field: { onChange, value },
  } = useController({
    name: inputName,
    control,
    rules,
  });

  return (
    <DatePicker
      selected={new Date(value)}
      onChange={(date) => onChange(date)}
      {...props}
    />
  );
};

DateField.propTypes = {
  control: PropTypes.object,
  inputName: PropTypes.string,
  rules: PropTypes.object,
};
export default DateField;
