import React from 'react';

import packageJSON from '../../../package.json';

const AppFooter = () => {
  return (
    <div className="app-body-footer">
      <span> v{packageJSON.version}</span>
    </div>
  );
};

export default AppFooter;
