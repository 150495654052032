import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import styles from './FlexContainer.module.scss';

const FlexContainer = ({
  align,
  direction,
  justify,
  width,
  height,
  maxWidth,
  children,
  spaceY,
  spaceX,
  spaceXChildren,
  wrap,
  grow,
  className,
}) => {
  return (
    <div
      className={cx(
        styles.flex,
        styles[align],
        styles[direction],
        styles[justify],
        styles[width],
        styles[maxWidth],
        styles[height],
        styles[spaceY],
        styles[spaceX],
        styles[spaceXChildren],
        styles[wrap],
        styles[grow],
        styles[className]
      )}
    >
      {children}
    </div>
  );
};

FlexContainer.propTypes = {
  align: PropTypes.string,
  direction: PropTypes.string,
  justify: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  maxWidth: PropTypes.string,
  spaceY: PropTypes.string,
  spaceX: PropTypes.string,
  spaceXChildren: PropTypes.string,
  children: PropTypes.element,
  wrap: PropTypes.string,
  grow: PropTypes.string,
  className: PropTypes.string,
};

export default FlexContainer;
