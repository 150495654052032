import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import TabContext from 'GlobalState/Context/TabContext';
import { useGlobalContext } from 'GlobalState/Context/GlobalContext';

import AppHeader from 'components/AppHeader/AppHeader';
import AppSidebar from 'components/AppSidebar/AppSidebar';
import AppFooter from 'components/AppFooter/AppFooter';

// Popups
import ModalPopup from 'components/ModalPopup/ModalPopup';
import NotificationPopup from 'components/NotificationPopup/NotificationPopup';

// views
import Home from 'views/Home/Home';
import Projects from 'views/Projects/Projects';
import Project from 'views/Project/Project';
import Manage from 'views/Manage/Manage';
import NoMatch from 'views/NoMatch/NoMatch';
import LoginView from 'views/LoginView/LoginView';
import HowToUse from 'views/Misk/HowToUse/HowToUse';
import RequestFeature from 'views/Misk/RequestFeature/RequestFeature';
import RequestProblem from 'views/Misk/ReportProblem/ReportProblem';
import MembersOverview from 'views/Members/MembersOverview/MembersOverview';
import SingleMember from 'views/SingleMember/SingleMember';
import SingleMemberPublic from 'views/SingleMember/SingleMemberPublic';

// home tabs
import TabStatistics from 'views/Home/TabStatistics/TabStatistics';
import TabHome from 'views/Home/TabHome/TabHome';

// manage tabs
import TabEmployeesPositions from 'views/Manage/TabEmployeesPositions/TabEmployeesPostions';
import TabProjects from 'views/Manage/TabProjects/TabProjects';
import TabClients from 'views/Manage/TabClients/TabClients';

// project tabs
import ProjectTab from 'views/Project/ProjectTab';
import ComponentsTab from 'views/Project/ComponentsTab';
import InvolvedTab from 'views/Project/InvolvedTab';
import SingleComponentTab from 'views/Project/SingleCopmponentTab';

// admin members tabs
import TabNewSingleMember from 'views/SingleMember/TabNewSingleMember';
import TabSingleMemberTables from 'views/SingleMember/TabSingleMemberTables';

// // We have the main menu structure in a separate json file to keep components clean.
import navigationMenuItems from './navigation';

// Toggle the dark mode function
import updateCssVariables from 'components/Toggle/ToggleDarkMode/updateCssVariblesDarkMode';
import updateCssThemeColorVariables from 'components/ThemeOptions/updateCssVariblesThemeColors';

import UpdateAvailableNotification from 'components/UpdateAvailableNotification/UpdateAvailableNotification.js';

// Data fetching
import { useQuery } from 'react-query';
import instance from 'axiosInstance';
import { authorizationQueryFunc } from 'utils/sharedFetchers';
/**
 * The one component that holds the whole app.
 *
 * From here we branch out, add the routers and so on. This file is only loaded
 * from the index.js in root. Up for a change ofc.
 *
 * Developer notes:
 *
 * When it comes to the routing part, look into react-router-tabs. Will most
 * likely need to have additional switch statements inside the page as we are
 * nesting a few pages into each other.
 * @see https://www.npmjs.com/package/react-router-tabs
 *
 * Each folder here represents a page on the site. For each page, we can have
 * multiple tabs, which are located in the page's folder.
 * @return {JSX}
 */

const App = () => {
  const [cookies, , removeCookie] = useCookies(['auth_token']);

  const {
    globalState: { modalContent, notifications },
    globalDispatch,
  } = useGlobalContext();

  const isLogged = !!cookies.auth_token;

  const [isActiveMobileMenu, setIsActiveMobileMenu] = useState(false);

  const isActiveMenu = () => {
    setIsActiveMobileMenu(!isActiveMobileMenu);
  };

  const notificationToRender = notifications.map((notification, index) => (
    <NotificationPopup
      key={index}
      type={notification.type}
      text={notification.text}
    />
  ));

  // Toggle dark mode persistency
  useEffect(() => {
    const isDark = JSON.parse(localStorage.getItem('is-dark'));
    let theme = localStorage.getItem('theme');

    if (!theme) {
      theme = 'default';
      localStorage.setItem('theme', 'default');
    }

    updateCssVariables(isDark);
    updateCssThemeColorVariables(theme);
  }, []);

  if (isLogged) {
    instance.defaults.headers['Authtoken'] = cookies.auth_token;
  }

  const { data, isLoading } = useQuery(
    'authorization',
    authorizationQueryFunc,
    {
      suspense: false,
      enabled: isLogged,
      onSuccess: (data) => {
        globalDispatch({ type: 'setIsAdmin', payload: data.is_admin });
      },
      onError: () => {
        removeCookie('auth_token');
      },
    }
  );

  if (!isLogged) {
    return (
      <div className="timetracker">
        {notificationToRender}
        <Router>
          <Routes>
            <Route path="/*" element={<LoginView />} />
          </Routes>
        </Router>
      </div>
    );
  }

  return (
    <div className="timetracker">
      {notificationToRender}
      <ModalPopup>{modalContent}</ModalPopup>
      <Router>
        <UpdateAvailableNotification />

        <AppHeader isActiveMenu={isActiveMenu} />
        <div className="app-body">
          <AppSidebar
            navigationMenuItems={navigationMenuItems}
            isActiveMobileMenu={isActiveMobileMenu}
            setIsActiveMobileMenu={setIsActiveMobileMenu}
          />

          <div className="app-body-main">
            <Routes>
              <Route
                path="/member/:slug/"
                element={
                  isLoading ? null : data.is_admin ? (
                    <SingleMember />
                  ) : (
                    <SingleMemberPublic />
                  )
                }
              >
                <Route
                  path=""
                  element={
                    <TabContext>
                      <TabNewSingleMember />
                    </TabContext>
                  }
                />
                <Route
                  path="tables"
                  element={
                    <TabContext>
                      <TabSingleMemberTables />
                    </TabContext>
                  }
                />
              </Route>
              <Route
                path="members-overview"
                element={
                  isLoading ? null : data.is_admin ? (
                    <TabContext>
                      <MembersOverview />
                    </TabContext>
                  ) : (
                    <NoMatch />
                  )
                }
              />
              <Route path="/projects/" element={<Projects />} />
              <Route path="/project/:slug/" element={<Project />}>
                <Route
                  path=""
                  element={
                    <TabContext>
                      <ProjectTab />
                    </TabContext>
                  }
                />
                <Route
                  path="components-table"
                  element={
                    <TabContext>
                      <ComponentsTab />
                    </TabContext>
                  }
                />
                <Route
                  path="involved-table"
                  element={
                    <TabContext>
                      <InvolvedTab />
                    </TabContext>
                  }
                />
                <Route
                  path="component/:id"
                  element={
                    <TabContext>
                      <SingleComponentTab />
                    </TabContext>
                  }
                />
              </Route>

              <Route
                path="/manage/"
                element={
                  isLoading ? null : data.is_admin ? <Manage /> : <NoMatch />
                }
              >
                <Route
                  path="employees-positions"
                  element={<TabEmployeesPositions />}
                />
                <Route
                  path="projects"
                  element={
                    <TabContext>
                      <TabProjects />
                    </TabContext>
                  }
                />
                <Route
                  path="clients"
                  element={
                    <TabContext>
                      <TabClients />
                    </TabContext>
                  }
                />
              </Route>
              <Route path="/" element={<Home />}>
                <Route
                  path="my-statistics"
                  element={
                    <TabContext>
                      <TabStatistics />
                    </TabContext>
                  }
                />
                <Route
                  path=""
                  element={
                    <TabContext>
                      <TabHome />
                    </TabContext>
                  }
                />
              </Route>

              <Route path="/request-feature" element={<RequestFeature />} />
              <Route path="/report-problem" element={<RequestProblem />} />
              <Route path="/how-to" element={<HowToUse />} />

              <Route element={NoMatch} />
            </Routes>

            <AppFooter />
          </div>
        </div>
      </Router>
    </div>
  );
};

export default App;
