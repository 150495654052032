import React from 'react';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';

import Button from 'components/Button/Button';

import { useMutation } from 'react-query';
import instance from 'axiosInstance';

const logout = async () => {
  const response = await instance.delete('/private/logout');

  return response.data;
};

const Logout = ({ additionalStyles }) => {
  const [, , removeCookie] = useCookies(['auth_token']);

  const { mutate } = useMutation(logout, {
    mutationKey: 'logout',
    onSettled: () => {
      removeCookie('auth_token');
    },
  });

  const handleLogout = () => {
    mutate();
  };
  return (
    <Button
      text="Logout"
      clicked={handleLogout}
      type="button-primary-transparent"
      additionalStyles={additionalStyles}
    />
  );
};

Logout.propTypes = {
  additionalStyles: PropTypes.string,
};

export default Logout;
