import React from 'react';
import PropTypes from 'prop-types';

// Form
import { useController } from 'react-hook-form';
import CheckSolid from 'components/Icons/Check-solid';

// Styles
import cx from 'classnames';
import styles from './Checkbox.module.scss';

const Checkbox = ({ label, control, inputName }) => {
  const {
    field: { onChange, name, value },
  } = useController({
    name: inputName,
    control,
  });
  return (
    <div
      className={cx(
        'flex-row',
        'centered-vertically',
        styles['checkbox-input']
      )}
    >
      <div
        className={cx(
          styles['checkbox-wrapper'],
          'flex-row',
          'centered-horizontally',
          'centered-vertically'
        )}
      >
        <input
          type="checkbox"
          checked={value}
          name={name}
          onChange={onChange}
          className={`${styles['checkbox-item']} ${
            value ? styles.checked : ''
          }`}
        />
        <span
          className={`${styles['checkbox-item-custom']} ${
            value ? styles.checked : ''
          }`}
        >
          <CheckSolid color="var(--theme-text-color-invert-adaptive)" />
        </span>
      </div>
      <span>{label}</span>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  control: PropTypes.object,
  inputName: PropTypes.string,
};

export default Checkbox;
