import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import styles from './Button.module.scss';

const LabelButton = ({
  text,
  type,
  additionalStyles,
  additionalInlineStyles,
  icon,
  ...field
}) => {
  return (
    <label
      className={cx(styles.button, styles[type], additionalStyles)}
      style={additionalInlineStyles}
      {...field}
    >
      {text}
      {icon}
    </label>
  );
};

// additionalStyles is to add new class
// additionalInlineStyles is to add new css to override the initial styles of the button
// type is used to set the design type of the button

LabelButton.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  type: PropTypes.string,
  additionalStyles: PropTypes.string,
  additionalInlineStyles: PropTypes.string,
};

export default LabelButton;
